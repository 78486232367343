import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  id?: string;
  text: string;
  level: number;
};

export const HeadingBlock: BFC<Props> = ({
  id,
  text,
  level,
  className,
}) => {
  const classes = classNames("font-bold", className, {
    "text-2xl my-1": level === 1,
    "text-xl border-b pb-3 my-1": level === 2,
    "text-lg": level === 3,
    "text-base": level === 4,
    "text-sm": level === 5,
    "text-xs": level === 6,
  });

  return (
    <div
      id={`header-${id}`}
      className={classes}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
