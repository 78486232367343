import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { Checkout, PaymentMethod, TopicType } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { CalculateTopicRequest, PostTopicRequest } from "shared/services/api/web";
import { isAPIError } from "shared/types";

import { useAuthContext } from "~/features/auth";
import { useStripeContext } from "~/features/checkouts";
import { usePaymentMethods } from "~/features/users";

enum ReadyState {
  INITIALIZING,
  LOADING,
  READY,
};

export const useTopicCheckoutService = () => {
  const { accessToken } = useAuthContext();
  const { defaultPaymentMethod, isLoading: isPaymentMethodsLoading } = usePaymentMethods();
  const { stripe } = useStripeContext();
  const api = useWebAPI({ accessToken });

  const readyState = useRef(ReadyState.INITIALIZING);
  const [checkout, setCheckout] = useState<Checkout>(new Checkout());
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [isExecuteLoading, setExecuteLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Map<string, string>>(new Map);

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["topic/create/withCheckout"],
    (data: PostTopicRequest) => api.postTopic(data),
    {
      onSuccess: ({ data: { checkout } }) => {
        if (checkout) {
          setCheckout(new Checkout(checkout));
        }
      },
    },
  );

  const { mutateAsync: callback, isLoading: isCallbackLoading } = useMutation(
    ["topics/checkout/callback"],
    (id: string) => api.callbackTopicCheckout(id),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
    },
  );

  const { mutateAsync: calculateAsync, isLoading: isCalculateLoading } = useMutation(
    ["topics/checkout/calculate"],
    (data: CalculateTopicRequest) => api.calculateTopic(data),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
        readyState.current = ReadyState.READY;
      },
      onError: (e) => {
        readyState.current = ReadyState.INITIALIZING;
        if (isAPIError(e)) {
          e.response.clone().json().then((data) => {
            if (data.error.code === "invalid_record") {
              const tmpErrors = new Map;
              data.error.validations.forEach((v) => {
                tmpErrors.set(`${v.model}.${v.key}`, v.message);
              });
              setErrorMessages(tmpErrors);
            }
          });
        }
      },
    },
  );

  const execute = useCallback(async (data: PostTopicRequest, onSucceeded?: (topic: TopicType) => Promise<void>) => {
    if (stripe && paymentMethod) {
      setExecuteLoading(true);
      try {
        const { data: { payment, topic } } = await create(data);

        if (payment) {
          try {
            await stripe.confirmCardPayment(payment.stripeClientSecret, {
              payment_method: paymentMethod.stripePaymentMethodId,
            });
          } finally {
            await callback(topic.id);
          }
        }

        if (onSucceeded) {
          await onSucceeded(topic);
        }

        reset();
      } catch (error) {
        setIsFailed(true);
        setExecuteLoading(false);
      }
    }
  }, [create, callback, checkout, paymentMethod, stripe]);

  const calculate = useCallback(async (data: CalculateTopicRequest) => {
    await calculateAsync(data);
  }, [calculateAsync]);

  const reset = useCallback(() => {
    readyState.current = ReadyState.INITIALIZING;
    setCheckout(new Checkout());
    setExecuteLoading(false);
    setIsFailed(false);
    setErrorMessages(new Map);
  }, []);

  useEffect(() => {
    if (defaultPaymentMethod && !paymentMethod) {
      setPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod, paymentMethod]);

  const isLoading = isPaymentMethodsLoading;
  const isMutating = isCreateLoading || isCallbackLoading;
  const isProcessing = isExecuteLoading;

  return {
    checkout,
    paymentMethod,
    calculate,
    execute,
    setPaymentMethod,
    errorMessages,
    isReady: paymentMethod && !isLoading && !isMutating && readyState.current === ReadyState.READY,
    isFailed,
    isLoading,
    isMutating,
    isProcessing,
    isCalculateLoading,
  };
};
