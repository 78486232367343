import classNames from "classnames";
import { useCallback, useMemo, useRef } from "react";

import { formatDate } from "shared/helpers";
import { MagazineArticle as MagazineArticleClassType } from "shared/models";
import { BFC } from "shared/types";

import { EditorjsRenderer } from "~/components";
import { GoogleAdsenseUnit } from "~/features/ads";
import { toStrapiImageURL } from "~/features/magazines/helpers";
import { EditorJsData } from "~/lib/editorjs";

import { TableOfContents } from "../TableOfContents";

type Props = {
  article: MagazineArticleClassType;
};

export const MagazineArticle: BFC<Props> = ({
  article,
  className,
}) => {
  const { cover, title } = article;
  const ref = useRef<HTMLDivElement>(null);

  const json = useMemo(() => article.getBodyJson<EditorJsData>(), [article]);
  const blocks = useMemo(() => {
    if (!json) return [];

    return json.blocks.map((block) => {
      switch (block.type) {
        case "image":
          block.data.file.url = toStrapiImageURL(block.data.file.url);
          return block;
        default:
          return block;
      }
    });
  }, [json]);

  const onTableOfContentsClick = useCallback((id?: string) => {
    ref.current?.querySelector(`#header-${id}`)?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} className={classNames("bg-white", className)}>
      {cover && (
        <div className="relative h-60 w-full text-white">
          <img src={cover.url} alt={title} className="absolute inset-0 z-10 h-full w-full object-cover" />
          <div className="absolute bottom-0 left-0 z-20 flex gap-2 p-4">
            {article.getCategories().map((category) => (
              <span
                key={category.id}
                className="rounded bg-neutral-100 px-2 py-1 text-sm text-gray-500"
              >
                {category.name}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-3 p-4">
        <div className="text-sm text-gray-500">
          {formatDate(article.getPublishDate())}
        </div>
        <h1 className="text-2xl font-bold">{title}</h1>
        {article.tags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {article.getTags().map((tag) => (
              <span
                key={tag.id}
                className="rounded border bg-white px-2 py-1 text-sm text-gray-600"
              >
                #{tag.name}
              </span>
            ))}
          </div>
        )}
        <div className="flex grid-cols-12 flex-col items-start gap-4 md:grid">
          <div className="hidden md:sticky md:top-16 md:order-last md:col-span-4 md:block">
            <TableOfContents blocks={blocks} onClick={onTableOfContentsClick} />
            <GoogleAdsenseUnit
              slot="8492432568"
            />
          </div>
          <div className="col-span-8 flex flex-col gap-4">
            <div className="flex gap-3 rounded border p-4">
              <img
                src={article.editor?.thumbnail?.url}
                className="h-12 w-12 shrink-0 rounded-full"
              />
              <div className="flex flex-col gap-1">
                <div>
                  <div className="font-bold">
                    {article.editor?.name}
                  </div>
                </div>
                <div className="text-black-600 text-sm">
                  {article.editor?.introduction}
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <TableOfContents blocks={blocks} onClick={onTableOfContentsClick} />
            </div>
            <EditorjsRenderer data={json} />
          </div>
        </div>
      </div>
    </div>
  );
};
