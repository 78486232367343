import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

import { Checkout, PaymentMethod, Support } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { CalculateProposalSupportRequest, PostProposalSupportRequest } from "shared/services/api/web";
import { isAPIError } from "shared/types";

import { useAuthContext } from "~/features/auth";
import { useStripeContext } from "~/features/checkouts";
import { usePaymentMethods } from "~/features/users";

enum ReadyState {
  INITIALIZING,
  LOADING,
  READY,
};

export const useSupportCheckoutService = (id: string) => {
  const { accessToken } = useAuthContext();
  const { defaultPaymentMethod, isLoading: isPaymentMethodsLoading } = usePaymentMethods();
  const { stripe } = useStripeContext();
  const api = useWebAPI({ accessToken });

  const readyState = useRef(ReadyState.INITIALIZING);
  const [checkout, setCheckout] = useState<Checkout>(new Checkout());
  const [support, setSupport] = useState<Support | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [isExecuteLoading, setExecuteLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Map<string, string>>(new Map);

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["proposals/supports/create", id],
    (data: PostProposalSupportRequest) => api.postProposalSupport(id, data),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
    },
  );

  const { mutateAsync: callback, isLoading: isCallbackLoading } = useMutation(
    ["proposals/supports/complete"],
    (id: string) => api.callbackSupportCheckout(id),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
    },
  );

  const { mutateAsync: calculateAsync, isLoading: isCalculateLoading } = useMutation(
    ["proposals/supports/calculate", id],
    (data: CalculateProposalSupportRequest) => api.calculateProposalSupport(id, data),
    {
      onSuccess: ({ data: { checkout, support } }) => {
        setCheckout(new Checkout(checkout));
        setSupport(new Support(support));
        readyState.current = ReadyState.READY;
      },
      onError: (e) => {
        readyState.current = ReadyState.INITIALIZING;
        if (isAPIError(e)) {
          e.response.clone().json().then((data) => {
            if (data.error.code === "invalid_record") {
              const tmpErrors = new Map;
              data.error.validations.forEach((v) => {
                tmpErrors.set(`${v.model}.${v.key}`, v.message);
              });
              setErrorMessages(tmpErrors);
            }
          });
        }
      },
    },
  );

  const execute = useCallback(async (data: PostProposalSupportRequest, onSucceeded?: () => void) => {
    if (paymentMethod && stripe) {
      setExecuteLoading(true);
      try {
        const { data: { payment, support } } = await create(data);

        try {
          await stripe.confirmCardPayment(payment.stripeClientSecret, {
            payment_method: paymentMethod.stripePaymentMethodId,
          });
        } catch (e) {
          await callback(support.id);
          throw e;
        }

        await callback(support.id);

        if (onSucceeded) {
          onSucceeded();
        }

        reset();
      } catch (error) {
        console.error(error);
        setIsFailed(true);
        setExecuteLoading(false);
      }
    }
  }, [create, callback, checkout, paymentMethod, stripe]);

  const calculate = useCallback(async (data: PostProposalSupportRequest) => {
    await calculateAsync(data);
  }, [calculateAsync]);

  const reset = useCallback(() => {
    readyState.current = ReadyState.INITIALIZING;
    setCheckout(new Checkout());
    setSupport(null);
    setExecuteLoading(false);
    setIsFailed(false);
    setErrorMessages(new Map);
  }, []);

  useEffect(() => {
    if (defaultPaymentMethod && !paymentMethod) {
      setPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod, paymentMethod]);

  const isLoading = isPaymentMethodsLoading;
  const isMutating = isCreateLoading || isCallbackLoading;
  const isProcessing = isExecuteLoading;

  return {
    checkout,
    support,
    paymentMethod,
    calculate,
    execute,
    setPaymentMethod,
    errorMessages,
    isReady: paymentMethod && !isLoading && !isMutating && readyState.current === ReadyState.READY,
    isFailed,
    isLoading,
    isMutating,
    isProcessing,
    isCalculateLoading,
  };
};
