import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";
import { CreateTopicNoteRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useNotes = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: create, isLoading: isCreating } = useMutation(
    ["topics/notes/create", id],
    (params: CreateTopicNoteRequest) => api.createTopicNote(id, params),
  );

  const { mutateAsync: destroy, isLoading: isDestroying } = useMutation(
    ["topics/notes/destroy", id],
    (noteId: string) => api.deleteTopicNote(id, noteId),
  );

  return {
    create,
    destroy,
    isCreating,
    isDestroying,
    isMutating: isCreating || isDestroying,
  };
};
