import { useState } from "react";

import { MagazineCategory } from "shared/models";

import { useStrapiQuery } from "./useStrapiQuery";
import { StrapiResponse, StrapiCategory } from "../types";

export const useMagazineCategory = (slug: string) => {
  const [category, setCategory] = useState(new MagazineCategory());

  const { isLoading } = useStrapiQuery<StrapiResponse<StrapiCategory[]>>(
    ["magazineCategory", slug],
    "/api/categories",
    {
      filters: {
        slug: {
          $eq: slug,
        },
      },
    },
    {
      enabled: !!slug,
      onSuccess: ({ data }) => {
        if (data.length) {
          const { id, attributes } = data[0];
          setCategory(new MagazineCategory({
            id,
            name: attributes.name,
            slug: attributes.slug,
          }));
        }
      },
    },
  );

  return {
    category,
    isLoading,
  };
};
