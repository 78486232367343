import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Vote } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { VoteTopicRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useVotes = (id: string) => {
  const { accessToken, getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const guestableApi = useWebAPI({ accessToken: getAccessToken(true) });
  const [votes, setVotes] = useState<Vote[]>([]);
  const [voted, setVoted] = useState<Vote>();

  const { fetchNextPage, refetch, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["topics/votes", id],
    ({ pageParam }) => api.getTopicVotes(id, pageParam),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setVotes(pages.flatMap(({ data: { votes } }) => votes.map((d) => new Vote(d))));
        if (pages[pages.length - 1].data.voted) {
          setVoted(new Vote(pages[pages.length - 1].data.voted));
        }
      },
    },
  );

  const { mutateAsync: vote, isLoading: isVoting } = useMutation(
    ["topics/vote", id],
    async (params: VoteTopicRequest) => {
      return await guestableApi.voteTopic(id, params);
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    votes,
    voted,
    vote,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    isVoting,
    isMutating: isVoting,
  };
};
