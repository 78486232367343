import classNames from "classnames";
import { useState } from "react";
import { useCallback } from "react";
import { MouseEventHandler } from "react";
import { useEffect } from "react";
import { HiOutlinePaperClip } from "react-icons/hi";

import { BFC } from "shared/types";

type Clippable = {
  clipsCount: number;
  clipped: boolean;
};

type Props = {
  clippable: Clippable;
  clip: () => Promise<unknown>;
  unclip: () => Promise<unknown>;
  disabled?: boolean;
  isMutating: boolean;
};

export const ClipButton: BFC<Props> = ({
  clippable,
  clip,
  unclip,
  disabled,
  isMutating,
}) => {
  const [clipped, setClipped] = useState(clippable.clipped);
  const [clipsCount, setClipsCount] = useState(clippable.clipsCount);

  const toggleClip = useCallback(async () => {
    if (disabled) return;

    if (clipped) {
      await unclip();
      setClipped(false);
      setClipsCount(clipsCount - 1);
    } else {
      await clip();
      setClipped(true);
      setClipsCount(clipsCount + 1);
    }
  }, [clipped, clipsCount]);

  const onClipClick: MouseEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleClip();
  }, [toggleClip]);

  useEffect(() => {
    setClipped(clippable.clipped);
    setClipsCount(clippable.clipsCount);
  }, [clippable]);

  return (
    <button className="flex items-center gap-1 ring-0" onClick={onClipClick} disabled={isMutating}>
      {clipped || isMutating ? (
        <HiOutlinePaperClip
          size={20}
          className={classNames("transition-all", { "text-green-500": !isMutating, "text-black-300 animate-pulse": isMutating })}
        />
      ) : (
        <HiOutlinePaperClip size={20} className="text-black-400" />
      )}
      <div className="text-black-400">{clipsCount}</div>
    </button>
  );
};
