"use client";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useTransition } from "react";
import { UseFormSetError } from "react-hook-form";
import { FaSquareXTwitter } from "react-icons/fa6";
import { HiOutlineLogin } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { BFC, isAPIError } from "shared/types";

import { routes } from "~/constants";
import { SignInForm, SignInData, useSignIn } from "~/features/users";

type Props = {
  origin?: string;
};

export const SignInPage: BFC<Props> = ({
  origin,
}) => {
  const router = useRouter();
  const { signIn, isSignedInLoading } = useSignIn();
  const [isPending, startTransition] = useTransition();

  const onSubmit = useCallback(async (data: SignInData) => {
    await signIn(data);
    startTransition(() => {
      router.push(origin || routes.TOP);
    });
  }, [origin]);

  const onError = useCallback(
    (e: unknown, setError: UseFormSetError<SignInData>) => {
      if (isAPIError(e)) {
        e.response
          .clone()
          .json()
          .then((res) => {
            console.error(res);
            if (res.error.code === "not_found") {
              setError("email", {
                type: "server",
                message: "メールアドレスまたはパスワードが間違っています",
              });
              setError("password", {
                type: "server",
                message: "メールアドレスまたはパスワードが間違っています",
              });
            }
          });
      }
    },
    []
  );

  const onTwitterSignIn = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_TWITTER({ return_to: origin }));
  }, [origin]);

  const signUpUrl = useMemo(() => {
    return routes.USERS_SIGN_UP(origin);
  }, [origin]);

  if (isSignedInLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between border-b bg-white px-4 py-6">
        <h1 className="flex gap-2 text-lg font-bold">
          <HiOutlineLogin size={24} className="text-black-500" />
          ログイン
        </h1>
        <div>
          会員登録は
          <Link href={signUpUrl} decoration className="font-bold">こちら</Link>
        </div>
      </div>
      <div className="border-t bg-white p-4 shadow">
        <div className="mx-auto flex w-full max-w-2xl flex-col gap-4">
          <SignInForm
            onSubmit={onSubmit}
            onError={onError}
            isPending={isPending}
          />
          <div className="text-center">
            <Link href={routes.USERS_PASSWORD_NEW} className="p-3 text-center text-base text-gray-500">
              パスワードを忘れた方はこちら
            </Link>
          </div>
          <div className="border-t py-3">
            <Button
              block
              large
              className="flex items-center gap-2"
              onClick={onTwitterSignIn}
            >
              <FaSquareXTwitter size={20} />
              Xアカウントでログイン
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
