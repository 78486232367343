import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import { Proposal, ProposalType } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useProposal = (id?: string, defaultData?: ProposalType) => {
  const [proposal, setProposal] = useState<Proposal>(new Proposal(defaultData));
  const [isOwner, setIsOwner] = useState(false);
  const { getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken: getAccessToken(true) });

  const threadProposals = useMemo(() => proposal.getThreadProposals(), [proposal]);
  const threadProposalsWithoutSelf = useMemo(() => threadProposals.filter((p) => p.id !== proposal.id), [threadProposals]);

  const { refetch, isLoading } = useQuery(
    ["topics/proposals", id],
    () => api.getProposal(id || ""),
    {
      enabled: !!id,
      onSuccess: ({ data }) => {
        setProposal(new Proposal(data.proposal));
        setIsOwner(data.isOwner);
      },
    },
  );

  return {
    proposal,
    isOwner,
    threadProposals,
    threadProposalsWithoutSelf,
    refetch,
    isLoading,
  };
};
