import classNames from "classnames";
import { useSearchParams, useRouter } from "next/navigation";
import { FormEvent, useCallback, useRef } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { HiOutlineBars3, HiOutlineBell, HiOutlinePlusCircle, HiOutlineXMark } from "react-icons/hi2";

import { Link } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { BFC } from "shared/types";

import { useSearchLayout } from "~/components/layouts/SearchLayout";
import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { useNotificationsContext, WithNotificationBadge } from "~/features/notifications";
import { UserThumbnailImage } from "~/features/users";

type Props = {
  onMenuClick: () => void;
};

export const Header: BFC<Props> = ({
  onMenuClick,
  className,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const keyword = searchParams?.get("keyword") ?? "";
  const { setKeyword, inSearchPage } = useSearchLayout();
  const { user, isSignedIn, isIdle } = useAuthContext();
  const { notificationsCount } = useNotificationsContext();
  const [searchShown, showSearch, hideSearch] = useBooleanState(!!keyword);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchIconClick = useCallback(() => {
    showSearch();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  const onSearchCancelClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  }, []);

  const onSearchSubmit = useCallback((e: FormEvent) => {
    e.preventDefault();
    if (inSearchPage) {
      setKeyword(inputRef.current?.value ?? "");
    } else {
      router.push(routes.SEARCH_TOPICS(inputRef.current?.value ?? ""));
    }
  }, [inSearchPage]);

  const onBlur = useCallback(() => {
    if (!inputRef.current?.value) {
      hideSearch();
    }
  }, []);

  return (
    <div className={classNames("bg-white flex items-center h-12", className)}>
      <div className="flex flex-1 items-center">
        <div className="p-3">
          <Link href={routes.TOP}>
            <img
              src="/images/logo/logo_primary.png"
              className="h-6 w-auto"
            />
          </Link>
        </div>
        <div className="flex flex-1 items-center pl-3">
          {searchShown && (
            <div className="w-full">
              <form onSubmit={onSearchSubmit} className="flex w-full justify-center">
                <div className="relative w-full max-w-xs">
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="キーワードで検索"
                    enterKeyHint="search"
                    defaultValue={keyword}
                    onBlur={onBlur}
                    className="w-full rounded-full border-gray-300 px-3 py-0.5 focus:border-gray-300 focus:ring-0"
                  />
                  <HiOutlineXMark size={20} className="absolute right-2 top-1.5" onClick={onSearchCancelClick} />
                </div>
              </form>
            </div>
          )}
        </div>
        <div className="flex items-center gap-2 p-3">
          {!searchShown && (
            <HiOutlineSearch size={24} className="text-gray-500" onClick={onSearchIconClick} />
          )}
          {!isSignedIn && !isIdle && !searchShown && (
            <Link href={routes.USERS_SIGN_UP()} className="text-text text-sm font-bold">
              新規登録
            </Link>
          )}
          {isSignedIn && !searchShown && (
            <>
              <Link href={routes.TOPICS_NEW}>
                <HiOutlinePlusCircle size={24} className="text-gray-500" />
              </Link>
              <Link href={routes.NOTIFICATIONS}>
                <WithNotificationBadge count={notificationsCount}>
                  <HiOutlineBell size={24} className="text-gray-500" />
                </WithNotificationBadge>
              </Link>
              <Link href={routes.USERS_SHOW(user.handle)}>
                <UserThumbnailImage user={user} size={24} />
              </Link>
            </>
          )}
          <button
            onClick={onMenuClick}
            className="text-gray-600 md:hidden">
            <HiOutlineBars3 size={24} />
          </button>
        </div>
      </div>
    </div>
  );
};
