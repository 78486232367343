import classNames from "classnames";
import { ReactNode, useMemo } from "react";

import { Comment } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage } from "~/features/users";
import { ClipButton, LikeButton } from "~/features/utils";

import { useLikeComment, useClipComment } from "../../hooks";
import { PrioritizedProgressBar } from "../PrioritizedProgressBar";
import { SupportIcon } from "../SupportIcon";
import { getSupportStarColor } from "../SupportStarIcon";

type Props = {
  comment: Comment;
  commentable?: ReactNode;
};

export const CommentListItem: BFC<Props> = ({
  comment,
  commentable,
  className,
}) => {
  const { like, unlike, isMutating: isLikeMutating } = useLikeComment(comment.id);
  const { clip, unclip, isMutating: isClipMutating } = useClipComment(comment.id);

  const user = useMemo(() => comment.getUser(), [comment]);
  const support = useMemo(() => comment.getSupport(), [comment]);
  const color = useMemo(() => support ? getSupportStarColor(support.amount.amountCents) : undefined, [support]);

  return (
    <div className={classNames("flex flex-col bg-white", className)}>
      <div className="flex flex-col gap-4 p-4">
        <div>
          {comment.body}
        </div>
        <div className="flex justify-between">
          {user.isNormal() ? (
            <div className="flex flex-1 gap-1 overflow-hidden">
              <UserThumbnailImage user={user} size={20} />
              <div className="truncate">{user.nickname}</div>
            </div>
          ) : (
            <div className="flex flex-1 gap-1 overflow-hidden">
              <UserThumbnailImage user={user} size={20} />
              <div className="text-gray-500">ゲストユーザー</div>
            </div>
          )}
          <div className="flex gap-2">
            <ClipButton clippable={comment} clip={clip} unclip={unclip} isMutating={isClipMutating} />
            {support && <SupportIcon support={support} />}
            <LikeButton like={like} unlike={unlike} likeable={comment} isMutating={isLikeMutating} />
          </div>
        </div>
        {commentable}
      </div>
      {support && (
        <PrioritizedProgressBar
          startAt={support.getPrioritizedStartAt()}
          endAt={support.getPrioritizedEndAt()}
          color={color}
          hideIfFinished
        />
      )}
    </div>
  );
};
