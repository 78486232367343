import classNames from "classnames";

import { BFC } from "shared/types";

export const DelimiterBlock: BFC = ({
  className,
}) => {
  return (
    <hr className={classNames(className)} />
  );
};
