import { HiBell, HiChatBubbleOvalLeft, HiHeart, HiStar } from "react-icons/hi2";

import { twClassNames } from "shared/lib/tailwind";
import { NotificationKindType } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  kind: NotificationKindType;
  size?: number;
};

export const NotificationKindIcon: BFC<Props> = ({
  kind,
  size = 24,
  className,
}) => {
  switch (kind) {
    case NotificationKindType.Like:
      return <HiHeart size={size} className={twClassNames("text-red-400", className)} />;
    case NotificationKindType.Comment:
      return <HiChatBubbleOvalLeft size={size} className={twClassNames("text-gray-400", className)} />;
    case NotificationKindType.Support:
      return <HiStar size={size} className={twClassNames("text-yellow-500", className)} />;
    case NotificationKindType.Proposal:
      return <HiChatBubbleOvalLeft size={size} className={twClassNames("text-green-400", className)} />;
    default:
      return <HiBell size={size} className={twClassNames("text-blue-400", className)} />;
  }
};
