import { HiOutlineChatBubbleOvalLeft, HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";

import { RoundButton } from "shared/components";
import { twClassNames } from "shared/lib/tailwind";
import { User } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { TrustPointsMeter } from "../TrustPointsMeter";
import { UserThumbnailImage } from "../UserThumbnailImage";

type Props = {
  user: User;
  showEditButton?: boolean;
};

const gradientColors = {
  backgroundColor: "#F4D03F",
  backgroundImage: "linear-gradient(132deg, #F4D03F 0%, #16A085 100%)",
};

export const UserProfileDetail: BFC<Props> = ({
  user,
  showEditButton,
  className,
}) => {
  return (
    <div className={twClassNames("bg-white", className)}>
      <div className="relative h-28 w-full" style={gradientColors}>
        <UserThumbnailImage user={user} size={80} className="absolute -bottom-6 left-4 border-2 border-white" />
        <TrustPointsMeter
          points={user.trustPoints}
          className="absolute right-4 top-4 h-8 w-8"
        />
        <div className="absolute bottom-4 right-4 flex items-center gap-2">
          <span className="rounded-full bg-neutral-600 px-2 py-0.5 text-sm text-white">TP</span>
          <span className="font-bold text-white">{user.actionPoints}</span>
        </div>
        {showEditButton && (
          <div className="absolute -bottom-12 right-4">
            <RoundButton href={routes.MYPAGE_PROFILE_EDIT}>編集する</RoundButton>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-col gap-2 p-4">
        <div className="flex flex-col gap-1">
          <div className="font-bold">{user.nickname}</div>
          <div className="text-gray-400">
            @{user.handle}
            {user.jobTitle && ` / ${user.jobTitle}`}
          </div>
        </div>
        <div>{user.introduction}</div>
        <div className="flex gap-3">
          <div className="flex items-center gap-1 text-sm text-gray-500">
            <HiOutlineChatBubbleOvalLeftEllipsis size={20} />
            <span className="font-bold">{user.proposalsCount}</span>
            <span>発言</span>
          </div>
          <div className="flex items-center gap-1 text-sm text-gray-500">
            <HiOutlineChatBubbleOvalLeft size={20} />
            <span className="font-bold">{user.commentsCount}</span>
            <span>コメント</span>
          </div>
        </div>
      </div>
    </div>
  );
};
