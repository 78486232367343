import classNames from "classnames";
import { useEffect } from "react";

import { BFC } from "shared/types";

const DEFAULT_CLIENT = "ca-pub-6979357145762566";

declare global {
  interface Window {
    adsbygoogle: { [key: string]: unknown }[]
  }
}

type Props = {
  slot: string;
  client?: string;
  layoutKey?: string;
  format?: string;
  fullWidthResponsive?: boolean;
};

export const GoogleAdsenseUnit: BFC<Props> = ({
  slot,
  client = DEFAULT_CLIENT,
  layoutKey,
  format = "auto",
  fullWidthResponsive,
  className,
}) => {
  useEffect(() => {
    try {
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <ins
      className={classNames("adsbygoogle block", className)}
      data-ad-layout-key={layoutKey}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format={format}
      data-full-width-responsive={fullWidthResponsive}
    />
  );
};
