import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  text: string;
};

export const ParagraphBlock: BFC<Props> = ({
  text,
  className,
}) => {
  return (
    <div
      className={classNames("leading-5", className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
