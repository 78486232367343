"use client";
import { useCallback } from "react";
import { HiPaperClip } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { TopicCardList } from "../../components";
import { useClippedTopics } from "../../hooks";

export const ClippedTopicsIndexPage: BFC = () => {
  const { fetchNextPage, topics, hasNextPage, isLoading, isFetchingNextPage } = useClippedTopics();

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <HiPaperClip size={24} />
          クリップ
        </h1>
        <div className="text-black-400 text-sm">
          クリップしたトピックに発言があると通知が届きます
        </div>
      </div>

      <div className="border-t bg-white md:border-b">
        <div className="flex divide-x md:border-b">
          <div className="border-b-primary border-b-2 px-6 py-4 font-bold">トピック</div>
          <Link className="px-6 py-4" href={routes.CLIPPED_PROPOSALS}>発言</Link>
          <Link className="!border-r px-6 py-4" href={routes.CLIPPED_COMMENTS}>コメント</Link>
        </div>
        <div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <TopicCardList topics={topics} className="md:p-3" />
          </InfiniteScroll>
          {topics.length === 0 && !isFetchingNextPage && !isLoading && (
            <div className="text-black-500 flex flex-col items-center justify-center py-8">
              クリップしたトピックがありません
            </div>
          )}
          {(isLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
