import { useMemo } from "react";

import { Comment, Proposal } from "shared/models";
import { BFC } from "shared/types";

import { CommentListItem } from "../CommentListItem";
import { ProposalDetailAccordion } from "../ProposalDetailAccordion";

type Props = {
  comment: Comment;
  proposal: Proposal;
};

export const CommentListItemWithProposal: BFC<Props> = ({
  comment,
  proposal,
  ...props
}) => {
  const commentable = useMemo(() => (
    <ProposalDetailAccordion
      topic={proposal.getTopic()}
      proposal={proposal}
      className="rounded border"
    />
  ), [proposal]);

  return (
    <CommentListItem
      comment={comment}
      commentable={commentable}
      {...props}
    />
  );
};
