import { useCallback, useEffect, useState } from "react";

import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { SimpleModal } from "~/components";
import { PaymentMethodData, usePaymentMethods } from "~/features/users";

import { ListPage } from "./ListPage";
import { NewPage } from "./NewPage";

export enum PaymentMethodsModalPage {
  LIST,
  NEW,
}

type Props = {
  open: boolean;
  page?: PaymentMethodsModalPage;
  selectedPaymentMethod?: PaymentMethod | null;
  onClose: () => void;
  onSelected?: (paymentMethod: PaymentMethod) => void;
};

export const PaymentMethodsModal: BFC<Props> = ({
  open,
  page = PaymentMethodsModalPage.LIST,
  selectedPaymentMethod,
  onClose,
  onSelected,
}) => {
  const { paymentMethods, defaultPaymentMethod, create, isLoading } = usePaymentMethods();
  const [currentPage, setCurrentPage] = useState(page);

  const onSelectedHandler = useCallback((paymentMethod: PaymentMethod) => {
    onSelected?.(paymentMethod);
    onClose();
  }, []);

  const onNewClick = useCallback(() => {
    setCurrentPage(PaymentMethodsModalPage.NEW);
  }, []);

  const onNewBackClick = useCallback(() => {
    setCurrentPage(PaymentMethodsModalPage.LIST);
  }, []);

  const onNewSubmit = useCallback(async (data: PaymentMethodData) => {
    await create(data, {
      onSuccess: ({ data: { paymentMethod } }) => {
        onSelectedHandler(new PaymentMethod(paymentMethod));
      },
    });
  }, [onSelectedHandler]);

  useEffect(() => {
    if (open) {
      setCurrentPage(paymentMethods.length > 0 ? PaymentMethodsModalPage.LIST : PaymentMethodsModalPage.NEW);
    }
  }, [open]);

  return (
    <SimpleModal open={open} onClose={onClose}>
      {currentPage === PaymentMethodsModalPage.LIST && (
        <ListPage
          paymentsMethods={paymentMethods}
          defaultPaymentMethod={defaultPaymentMethod}
          selectedPaymentMethod={selectedPaymentMethod}
          isLoading={isLoading}
          onSelected={onSelectedHandler}
          onNewClick={onNewClick}
        />
      )}
      {currentPage === PaymentMethodsModalPage.NEW && (
        <NewPage
          paymentsMethods={paymentMethods}
          onBackClick={onNewBackClick}
          onSubmit={onNewSubmit}
        />
      )}
    </SimpleModal>
  );
};
