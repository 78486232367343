import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  title: string;
};

export const JobTitleBadge: BFC<Props> = ({
  title,
  className,
}) => {
  return (
    <div className={classNames("border bg-white text-black-500 rounded-full text-xs inline-block py-0.5 px-2", className)}>
      {title}
    </div>
  );
};
