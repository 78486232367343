import classNames from "classnames";

import { Category } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  category?: Category;
  label?: string;
};

export const CategoryBadge: BFC<Props> = ({
  category,
  label,
  className,
}) => {
  return (
    <div className={classNames("border bg-white text-black-500 rounded-full text-sm inline-block py-0.5 px-2", className)}>
      {category?.name || label}
    </div>
  );
};
