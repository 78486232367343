import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  content: string[][];
  withHeadings: boolean;
};

export const TableBlock: BFC<Props> = ({
  content,
  withHeadings,
  className,
}) => {
  const classes = classNames("border rounded", className);

  const headings = (withHeadings ? content[0] : []) || [];
  const rows = withHeadings ? content.slice(1) : content;

  return (
    <div className={classes}>
      <table className="w-full">
        {headings.length > 0 && (
          <thead>
            <tr>
              {headings.map((heading, i) => (
                <th
                  key={i}
                  className="bg-gray-100 p-3"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className="p-3"
                  dangerouslySetInnerHTML={{ __html: col }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
