import { useCallback } from "react";
import { FieldValues, FieldPath, useFormContext, FieldPathValue, useController } from "react-hook-form";

import { EditorJsData } from "~/lib/editorjs";

import { EditorJsInputGroup, EditorJsInputGroupProps } from "../EditorjsInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & EditorJsInputGroupProps;

export const EditorJsControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  onChange,
  ...props
}: Props<Data>) => {
  const { control, setValue } = useFormContext<Data>();
  const { field, fieldState } = useController({ control, name });

  const onChangeHandler = useCallback((data: EditorJsData) => {
    setValue(name, data as FieldPathValue<Data, FieldPath<Data>>, { shouldDirty: true });
    onChange?.(data);
  }, [name]);

  return (
    <EditorJsInputGroup
      {...props}
      value={field.value}
      onChange={onChangeHandler}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
