import { Blob as RailsBlob } from "@rails/activestorage";
import { useCallback, useMemo, useState } from "react";

import { useAuthContext } from "~/features/auth";

const API_ORIGIN = process.env.API_ORIGIN || process.env.NEXT_PUBLIC_API_ORIGIN;

const DIRECT_UPLOAD_URL = `${API_ORIGIN}/api/web/direct_uploads`;

type Blob = RailsBlob & {
  key?: string;
  url?: string;
};

export type UploadedFile = {
  file: File;
  blob: Blob;
};

export const useDirectUpload = () => {
  const { accessToken } = useAuthContext();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const upload = useCallback(async (file: File): Promise<Blob> => {
    setIsUploading(true);
    const DirectUpload = (await import("@rails/activestorage")).DirectUpload;
    const directUpload = new DirectUpload(file, DIRECT_UPLOAD_URL, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      },
    });

    return new Promise((resolve, reject) => {
      directUpload.create((error, blob: Blob) => {
        if (error) {
          setIsUploading(false);
          reject(error);
        } else {
          setUploadedFiles((prev) => [...prev, { file, blob }]);
          setIsUploading(false);
          resolve(blob);
        }
      });
    });
  }, []);

  const uploadedFile: UploadedFile | undefined = useMemo(() => uploadedFiles[uploadedFiles.length - 1], [uploadedFiles]);

  return {
    upload,
    uploadedFile,
    uploadedFiles,
    isUploading,
  };
};
