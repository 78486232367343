import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { MagazineCategory, MagazineCategoryType } from "shared/models";

import { useStrapiQuery } from "./useStrapiQuery";
import { StrapiCategory, StrapiResponse } from "../types";

const { persistAtom } = recoilPersist({ key: "toiny.web.magazine" });

const categoriesAtom = atom<MagazineCategoryType[]>({
  key: "magazine/categories",
  default: [],
  effects: [persistAtom],
});

export const useMagazineCategiories = () => {
  const [categoriesState, setCategoriesState] = useRecoilState(categoriesAtom);
  const [categories, setCategories] = useState<MagazineCategory[]>([]);

  const { isLoading } = useStrapiQuery<StrapiResponse<StrapiCategory[]>>(
    ["magazineCategories"],
    "/api/categories",
    {
      populate: "*",
    },
    {
      onSuccess: ({ data }) => {
        setCategoriesState(data.map(({ id, attributes }) => ({
          id,
          name: attributes.name,
          slug: attributes.slug,
        })));
      },
    },
  );

  useEffect(() => {
    setCategories(categoriesState.map((category) => new MagazineCategory(category)));
  }, [categoriesState]);

  return {
    categories,
    isLoading,
  };
};
