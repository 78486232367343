"use client";
import { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { BFC } from "shared/types";

import { useSearchLayout } from "~/components";

import { ProposalListItem } from "../../components";
import { useSearchProposals } from "../../hooks";

export const ProposalsSearchPage: BFC = () => {
  const { selectTab, keyword } = useSearchLayout();
  const { proposals, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useSearchProposals({ keyword });

  useEffect(() => {
    selectTab("proposals");
  }, [selectTab]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="pt-3">
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <div className="flex flex-col gap-3">
          {proposals.map((proposal) => (
            <ProposalListItem key={proposal.id} proposal={proposal} className="border-y" />
          ))}
        </div>
      </InfiniteScroll>
      {proposals.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          該当する発言はありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center">
          <ColorRing />
        </div>
      )}
    </div>
  );
};
