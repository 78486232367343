import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useLikeComment = (id: string) => {
  const { getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken: getAccessToken(true) });

  const { mutateAsync: like, isLoading: isLikeLoading } = useMutation(
    ["topics/proposals/comments/like", id],
    () => api.likeComment(id),
  );

  const { mutateAsync: unlike, isLoading: isUnlikeLoading } = useMutation(
    ["topics/proposals/comments/unlike", id],
    () => api.unlikeComment(id),
  );

  return {
    like,
    unlike,
    isLikeLoading,
    isUnlikeLoading,
    isLoading: isLikeLoading || isUnlikeLoading,
    isMutating: isLikeLoading || isUnlikeLoading,
  };
};
