"use client";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { BFC } from "shared/types";

import { ConfirmModal } from "~/components";
import { routes } from "~/constants";

import { useDeactivate } from "../../hooks";

export const DeactivatePage: BFC = () => {
  const router = useRouter();
  const { deactivate, isLoading } = useDeactivate();
  const [shownModal, showModal, hideModal] = useBooleanState(false);

  const onClick = useCallback(() => {
    showModal();
  }, []);

  const onConfirm = useCallback(async () => {
    await deactivate();
    toast.success("退会しました");
    router.push(routes.TOP);
  }, [deactivate]);

  const onCancelClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onCancelClick} />
          退会
        </h1>
      </div>
      <div className="flex flex-col gap-3 bg-white p-3">
        <div className="rounded border bg-gray-100 p-3 text-gray-500">
          退会すると、あなたのアカウントは削除され復元することはできません。
        </div>
        <Button primary large block onClick={onClick} loading={isLoading}>退会する</Button>
      </div>

      <ConfirmModal
        open={shownModal}
        title="本当に退会しますか？"
        description="退会すると、あなたのアカウントは削除され復元することはできません。"
        confirmText="退会する"
        onConfirm={onConfirm}
        onCancel={hideModal}
      />
    </div>
  );
};
