import classNames from "classnames";

import { BFC } from "shared/types";

type Item = {
  content: string;
  items: Item[];
} | string;

type Props = {
  items: Item[];
  style: "ordered" | "unordered";
};

export const ListBlock: BFC<Props> = ({
  items,
  style,
  className,
}) => {
  const classes = classNames("ml-4 leading-6", className, {
    "list-disc": style === "unordered",
    "list-decimal": style === "ordered",
  });

  const renderItem = (item: Item, depth = 0) => {
    const content = typeof item === "string" ? item : item.content;
    const childItems = typeof item === "string" ? [] : item.items;
    return (
      <li>
        <div className="inline" dangerouslySetInnerHTML={{ __html: content }} />
        {childItems.length > 0 && (
          <ul className={classes}>
            {childItems.map((item) => renderItem(item, depth + 1))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <ul className={classes}>
      {items.map((item) => renderItem(item))}
    </ul>
  );
};
