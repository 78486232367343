import classNames from "classnames";
import { useEffect } from "react";

import { BFC } from "shared/types";

type Props = {
  service: string;
  source: string;
  embed?: string;
  width?: number;
  height?: number;
  caption?: string;
};

export const EmbedBlock: BFC<Props> = ({
  service,
  className,
  ...props
}) => {
  const classes = classNames(className);

  return (
    <div className={classes}>
      {service === "youtube" && (
        <YoutubeService {...props} />
      )}
      {service === "x" && (
        <XService {...props} />
      )}
    </div>
  );
};

type YoutubeServiceProps = {
  embed?: string;
  width?: number;
  height?: number;
  caption?: string;
}

const YoutubeService: BFC<YoutubeServiceProps> = ({
  embed,
  width,
  height,
  caption,
}) => {
  return (
    <iframe
      src={embed}
      width={width}
      height={height}
      title={caption}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      className="mx-auto max-w-full rounded-lg border"
    />
  );
};

type XServiceProps = {
  source: string;
  caption?: string;
}

const XService: BFC<XServiceProps> = ({
  source,
  caption,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <blockquote className="twitter-tweet" data-lang="ja">
      <p lang="ja" dir="ltr" />
      <a href={source.replace("x.com", "twitter.com")}>{caption}</a>
    </blockquote>
  );
};
