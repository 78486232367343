import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Proposal } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export enum UseProposalsOrder {
  LikesCountDesc = "likes_count:desc",
};

export type UseProposalsParams = {
  page?: number;
  positionId?: string;
  liked?: boolean;
  order?: UseProposalsOrder;
};

export const useProposals = (id: string, params: UseProposalsParams = {}) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const { getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken: getAccessToken(true) });

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["topics/proposals", id, params],
    ({ pageParam }) => api.getTopicProposals(id, pageParam || params.page || 1, params.positionId, params.liked, params.order),
    {
      enabled: !!id,
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setProposals(pages.flatMap(({ data: { proposals } }) => proposals.map((d) => new Proposal(d))));
      },
    },
  );

  return {
    proposals,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
