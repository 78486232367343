import { TopicBodyType } from "shared/models";
import { BFC } from "shared/types";

import { EditorjsRenderer, Markdown } from "~/components";
import { EditorJsData } from "~/lib/editorjs";

type Props = {
  body: string;
  bodyData?: EditorJsData;
  bodyType?: TopicBodyType;
};

export const Body: BFC<Props> = ({
  body,
  bodyData,
  bodyType = TopicBodyType.Markdown,
  className,
}) => {
  switch (bodyType) {
    case TopicBodyType.Markdown:
      return (
        <Markdown className={className}>
          {body}
        </Markdown>
      );
    case TopicBodyType.Editorjs:
      return (
        <EditorjsRenderer className={className} data={bodyData} />
      );
    default:
      return (
        <div className={className}>
          {body}
        </div>
      );
  }
};
