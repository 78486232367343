import { useCallback } from "react";
import { z } from "zod";

import { Button, TextControlGroup, HiddenControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    resetPasswordToken: z.string().min(1),
    password: z.string().min(1).min(8, "最低8文字で入力してください"),
    passwordConfirmation: z.string().min(1),
  }).refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致しません",
      path: ["passwordConfirmation"],
    },
  ),
});

export type PasswordResetData = z.infer<typeof schema>;

type Props = {
  resetPasswordToken: string;
  isPending?: boolean;
}

export const PasswordResetForm = createHookForm<PasswordResetData, Props>(({
  resetPasswordToken,
  isPending,
  formState: { isSubmitting },
}) => {
  const overrideErrorMessage = useCallback(() => {
    return "トークンが無効です。もう一度メール送信からやり直してください";
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup name="user.password" type="password" label="パスワード" required />
      <TextControlGroup name="user.passwordConfirmation" type="password" label="パスワード(確認)" required />
      <HiddenControlGroup name="user.resetPasswordToken" value={resetPasswordToken} overrideErrorMessage={overrideErrorMessage} />
      <Button type="submit" block primary large loading={isSubmitting || isPending}>
        リセットする
      </Button>
    </div>
  );
}, {
  schema,
});
