"use client";
import Link from "next/link";
import { useCallback, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { User, UserType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { CommentListItemWithProposal } from "~/features/topics";

import { UsersLayout } from "../../components";
import { useUserComments } from "../../hooks";

type Props = {
  userData: UserType;
};

export const UsersCommentsPage: BFC<Props> = ({
  userData,
}) => {
  const user = useMemo(() => new User(userData), [userData]);

  const {
    comments,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useUserComments(user.id);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <UsersLayout user={user} selectedTab="comments">
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <div className="flex flex-col gap-3">
          {comments.map((comment) => comment.isProposal() && (
            <Link href={routes.TOPICS_PROPOSALS_SHOW(comment.proposal.topicId, comment.proposal.id)} key={comment.id}>
              <CommentListItemWithProposal
                comment={comment}
                proposal={comment.getForceProposal()}
                className="border-y"
              />
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      {comments.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          投稿したコメントはありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center border-y bg-white p-4">
          <ColorRing />
        </div>
      )}
    </UsersLayout>
  );
};
