import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Proposal } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export type UseSearchProposalsParams = {
  keyword?: string;
};

export const useSearchProposals = (params: UseSearchProposalsParams = {}) => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const { getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken: getAccessToken(true) });

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["proposals/search", params],
    ({ pageParam }) => api.searchProposals(pageParam || 1, params.keyword),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setProposals(pages.flatMap(({ data: { proposals } }) => proposals.map((d) => new Proposal(d))));
      },
    },
  );

  return {
    proposals,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
