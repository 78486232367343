import classNames from "classnames";
import { differenceInSeconds } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { BFC } from "shared/types";

import { colors } from "~/constants";

type Props = {
  startAt: Date;
  endAt: Date;
  color?: string;
  hideIfFinished?: boolean;
};

export const PrioritizedProgressBar: BFC<Props> = ({
  startAt,
  endAt,
  color = colors.primary,
  hideIfFinished = false,
}) => {
  const totalSeconds = differenceInSeconds(endAt, startAt);
  const [currentSeconds, setCurrentSeconds] = useState(differenceInSeconds(endAt, new Date()));
  const progress = useMemo(() => currentSeconds > 0 ? (currentSeconds / totalSeconds) * 100 : 0, [currentSeconds, totalSeconds]);

  useEffect(() => {
    setTimeout(function timer() {
      const newSeconds = differenceInSeconds(endAt, new Date());
      setCurrentSeconds(newSeconds);
      if (newSeconds > 0) {
        setTimeout(timer, 1000);
      }
    }, 1000);
  }, []);

  return (
    <div
      className={classNames("relative h-1 w-full bg-gray-100", {
        "hidden": hideIfFinished && progress <= 0,
      })}
    >
      <div className="absolute inset-y-0" style={{ width: `${progress}%`, backgroundColor: color, opacity: 0.6 }} />
    </div>
  );
};
