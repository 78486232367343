import { z } from "zod";

import { Button, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    email: z.string().min(1).email(),
  }),
});

export type EmailEditData = z.infer<typeof schema>;

type Props = {
  onCancelClick: () => void;
};

export const EmailEditForm = createHookForm<EmailEditData, Props>(({
  formState: { isSubmitting },
  onCancelClick,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup name="user.email" label="メールアドレス" placeholder="taro@toiny.net" required />
      <Button type="submit" block primary large loading={isSubmitting}>
        変更する
      </Button>
      <Button block onClick={onCancelClick}>
        キャンセル
      </Button>
    </div>
  );
}, {
  schema,
});
