import { BFC } from "shared/types";

type Props = {
  size?: number;
};

export const Spinner: BFC<Props> = ({
  size = 24,
}) => {
  return (
    <div className="border-t-primary animate-spin rounded-full border-4 border-gray-200" style={{ width: size, height: size }} />
  );
};
