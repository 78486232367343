import { useCallback } from "react";
import toast from "react-hot-toast";
import { HiOutlineLink } from "react-icons/hi2";
import {
  XIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  HatenaShareButton,
  HatenaIcon,
} from "react-share";

import { Button } from "shared/components";
import { BFC } from "shared/types";

import { BottomModal, BottomModalProps } from "~/components";

type Props = BottomModalProps & {
  url: string;
  title?: string;
  hashtags?: string[];
};

export const ShareMenuModal: BFC<Props> = ({
  url,
  title,
  hashtags = [],
  open,
  onClose,
}) => {
  const onClickCopy = useCallback(() => {
    navigator.clipboard.writeText(url);
    toast.success("URLをコピーしました");
    onClose();
  }, [url, onClose]);

  return (
    <BottomModal open={open} onClose={onClose} className="p-4">
      <div className="text-center font-bold">
        シェアする
      </div>
      <div className="mt-4 flex justify-center gap-3">
        <HatenaShareButton
          url={url}
          title={title}
          className="flex w-16 flex-col items-center justify-center gap-1"
        >
          <HatenaIcon size={32} round />
          <div className="text-sm text-gray-500">
            はてな
          </div>
        </HatenaShareButton>
        <LineShareButton
          url={url}
          title={title}
          className="flex w-16 flex-col items-center justify-center gap-1"
        >
          <LineIcon size={32} round />
          <div className="text-sm text-gray-500">
            LINE
          </div>
        </LineShareButton>
        <FacebookShareButton
          url={url}
          className="flex w-16 flex-col items-center justify-center gap-1"
        >
          <FacebookIcon size={32} round />
          <div className="text-sm text-gray-500">
            Facebook
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={title}
          hashtags={["Toiny", "言論ファウンディング", ...hashtags]}
          className="flex w-16 flex-col items-center justify-center gap-1"
        >
          <XIcon size={32} round />
          <div className="text-sm text-gray-500">
            X(Twitter)
          </div>
        </TwitterShareButton>
        <div
          onClick={onClickCopy}
          className="flex w-16 flex-col items-center justify-center gap-1"
        >
          <div className="flex h-9 w-9 items-center justify-center rounded-full border">
            <HiOutlineLink size={20} className="text-gray-500" />
          </div>
          <div className="text-sm text-gray-500">
            コピー
          </div>
        </div>
      </div>
      <Button onClick={onClose} block large className="mt-4">キャンセル</Button>
    </BottomModal>
  );
};
