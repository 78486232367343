import { HiChevronLeft } from "react-icons/hi2";

import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { PaymentMethodData, PaymentMethodForm } from "../../forms";

type Props = {
  paymentsMethods: PaymentMethod[];
  onBackClick?: () => void;
  onSubmit: (data: PaymentMethodData) => void;
};

export const NewPage: BFC<Props> = ({
  onBackClick,
  onSubmit,
  paymentsMethods,
}) => {
  return (
    <div>
      <div className="flex gap-2 border-b p-4 font-bold">
        <HiChevronLeft size={24} onClick={onBackClick} />
        新しいカードを追加する
      </div>
      <div className="p-4">
        <PaymentMethodForm
          showDefault={paymentsMethods.length > 0}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
