import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Comment } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useClippedComments = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [comments, setComments] = useState<Comment[]>([]);

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["clips/comments"],
    ({ pageParam }) => api.getClippedComments(pageParam ?? 1),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setComments(pages.flatMap(({ data: { comments } }) => comments.map((c) => new Comment(c))));
      },
    }
  );

  return {
    comments,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
