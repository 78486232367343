import classNames from "classnames";
import { HiOutlineUserCircle } from "react-icons/hi2";

import { User, UserMe } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  user: User | UserMe;
  size?: number;
};

export const UserThumbnailImage: BFC<Props> = ({
  user,
  size = 40,
  className,
}) => {
  const url = user.thumbnailImage?.webp?.url;
  const classes = classNames("rounded-full overflow-hidden", className);
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <div className={classNames("bg-white", classes)} style={styles}>
      {url ? (
        <img src={url} className="h-full w-full object-cover" />
      ) : (
        <HiOutlineUserCircle className="text-black-500 h-full w-full" />
      )}
    </div>
  );
};
