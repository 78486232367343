import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { differenceInSeconds } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { Support } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage } from "~/features/users";

import { CommentListItem } from "../CommentListItem";
import { SupportIcon } from "../SupportIcon";
import { getSupportStarColor } from "../SupportStarIcon";

type Props = {
  support: Support;
};

export const SupportBadge: BFC<Props> = ({
  support,
  className,
}) => {
  const user = support.getUser();
  const totalSeconds = differenceInSeconds(support.getPrioritizedEndAt(), support.getPrioritizedStartAt());
  const [currentSeconds, setCurrentSeconds] = useState(differenceInSeconds(support.getPrioritizedEndAt(), new Date()));
  const progress = useMemo(() => (currentSeconds / totalSeconds) * 100, [currentSeconds, totalSeconds]);
  const color = useMemo(() => getSupportStarColor(support.amount.amountCents), [support]);

  useEffect(() => {
    setTimeout(function timer() {
      const newSeconds = differenceInSeconds(support.getPrioritizedEndAt(), new Date());
      setCurrentSeconds(newSeconds);
      if (newSeconds > 0) {
        setTimeout(timer, 1000);
      }
    }, 1000);
  }, []);

  return (
    <Popover>
      <Popover.Button className="focus:ring-0">
        <div
          className={classNames("border-2 rounded-full overflow-hidden relative w-36 bg-white", className, {
            "hidden": progress <= 0,
          })}
          style={{ borderColor: color }}
        >
          <div className="relative z-10 flex items-center gap-1 pr-4">
            <div className="p-0.5">
              <UserThumbnailImage user={user} size={32} className="bg-white" />
            </div>
            <div className="flex flex-col overflow-hidden py-1">
              <div className="text-2xs truncate">
                {support.comment?.body || `${user.nickname}さんが応援中`}
              </div>
              <SupportIcon support={support} size={12} className="text-2xs" />
            </div>
          </div>
          <div className="absolute inset-y-0 z-0" style={{ width: `${progress}%`, backgroundColor: color, opacity: 0.2 }} />
        </div>
      </Popover.Button>
      <Popover.Panel className="absolute left-12 z-10 w-72 rounded border shadow-md">
        <CommentListItem comment={support.getComment()} />
      </Popover.Panel>
    </Popover>
  );
};
