import { useMagazineArticles } from "./useMagazineArticles";
import { useMagazineCategory } from "./useMagazineCategory";

export const useMagazineCategoryArticles = (slug: string) => {
  const { category } = useMagazineCategory(slug);

  const { articles, ...rest } = useMagazineArticles({
    pagination: {
      withCount: true,
    },
    filters: {
      categories: {
        slug: {
          $eq: slug,
        },
      },
    },
  });

  return {
    category,
    articles,
    ...rest,
  };
};
