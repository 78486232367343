import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useClipProposal = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: clip, isLoading: isClipping } = useMutation(
    ["proposals/clip", id],
    () => api.clipProposal(id),
  );

  const { mutateAsync: unclip, isLoading: isUnclipping } = useMutation(
    ["proposals/unclip", id],
    () => api.unclipProposal(id),
  );

  return {
    clip,
    unclip,
    isClipping,
    isUnclipping,
    isMutating: isClipping || isUnclipping,
  };
};
