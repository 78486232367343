import { useCallback } from "react";

import { twClassNames } from "shared/lib/tailwind";
import { Category } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  mainCategory: Category;
  categories: Category[];
  selectedCategory?: Category | null;
  onSelect?: (category: Category) => void;
};

export const SubCategorySelector: BFC<Props> = ({
  mainCategory,
  categories,
  selectedCategory,
  onSelect,
  className,
}) => {
  const onSelectFactory = useCallback((category: Category) => () => {
    if (onSelect) onSelect(category);
  }, [onSelect]);

  return (
    <div className={twClassNames(className)}>
      <div className="flex w-max gap-3">
        <CategoryItem
          label="すべて"
          onClick={onSelectFactory(mainCategory)}
          selected={!selectedCategory?.id}
        />
        {categories.map((category) => (
          <CategoryItem
            key={category.id}
            label={category.name}
            onClick={onSelectFactory(category)}
            selected={category.id === selectedCategory?.id}
          />
        ))}
      </div>
    </div>
  );
};

type CategoryItemProp = {
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

const CategoryItem: BFC<CategoryItemProp> = ({
  label,
  selected,
  onClick,
  className,
}) => {
  const classes = twClassNames("px-3 py-2 rounded-full border", {
    "bg-neutral-200 text-black-500": !selected,
    "bg-neutral-500 text-white": selected,
  }, className);

  return (
    <div className={classes} onClick={onClick}>
      {label}
    </div>
  );
};
