import classNames from "classnames";
import { useMemo } from "react";

import { formatNumber } from "shared/helpers";
import { Position, Vote } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  positions: Position[];
  voted?: Vote | null;
};

export const VoteResult: BFC<Props> = ({
  positions,
  voted,
  className,
}) => {
  const totalAmount = useMemo(() => {
    return positions.reduce((acc, position) => acc + position.votesAmount, 0);
  }, [positions]);

  const topAmount = useMemo(() => {
    return [...positions].sort((a, b) => b.votesAmount - a.votesAmount)[0]?.votesAmount || 0;
  }, [positions]);

  const calcPercentage = (amount: number, maxRate = 1) => {
    return totalAmount > 0 ? (amount / totalAmount) * 100 * maxRate : 0;
  };

  return (
    <div className={classNames("flex flex-col gap-3", className)}>
      <div className="flex flex-col gap-1">
        {positions.map((position) => (
          <div key={position.id} className="relative">
            <div
              className={classNames("absolute inset-y-0 z-0 rounded", {
                "bg-gray-200": position.votesAmount !== topAmount,
                "bg-primary": position.votesAmount === topAmount,
              })}
              style={{ width: `${calcPercentage(position.votesAmount, 0.8)}%` }}
            />
            <div className="relative z-10 flex items-center justify-between py-1 pl-3">
              <div
                className={classNames({
                  "text-white font-bold": position.votesAmount === topAmount,
                })}
              >
                {position.name}
              </div>
              <div
                className={classNames(
                  "text-black-300 rounded-full bg-gray-100 py-0.5 px-3",
                  { "border border-red-500 font-bold": voted?.position.id == position.id },
                )}
              >
                  {calcPercentage(position.votesAmount).toFixed()}%
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-right text-sm text-gray-400">{formatNumber(totalAmount)}票</div>
    </div>
  );
};
