import { z } from "zod";

import { Button, NumberControlGroup, SelectControlGroup } from "shared/components";
import { formatCurrency, formatMoney } from "shared/helpers";
import { createHookForm } from "shared/lib/hook-form";
import { Balance, BankAccount, MoneyCurrency } from "shared/models";

const schema = z.object({
  payout: z.object({
    amount: z.number().min(100).max(100000),
    currency: z.nativeEnum(MoneyCurrency),
    stripeBankAccountId: z.string(),
  }),
});

export type PayoutData = z.infer<typeof schema>;

type Props = {
  balance: Balance;
  bankAccounts: BankAccount[];
};

export const PayoutForm = createHookForm<PayoutData, Props>(({
  formState: { isSubmitting },
  balance,
  bankAccounts,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-3">
        <NumberControlGroup
          name="payout.amount"
          label="金額"
          unit={formatCurrency(balance.amount.currency)}
          min={1000}
          max={balance.amount.amount}
          required
          className="flex-1"
          note="最低出金額は1000円です"
        />
        <div className="font-bold">
          / {formatMoney(balance.amount)}
        </div>
      </div>
      <SelectControlGroup
        name="payout.stripeBankAccountId"
        label="出金先口座"
        items={bankAccounts.map((account) => ({
          value: account.id,
          label: `${account.bankName} | ${account.accountHolderName} | ${account.last4}`,
        }))}
        placeholder="出金先口座を選択"
        required
      />
      <div className="rounded border bg-gray-100 p-3 text-sm">
        振込手数料、および出金手数料として600円を差し引いた金額を振込いたします
      </div>
      <Button
        type="submit"
        block primary large
        disabled={balance.amount.amountCents < 1000}
        loading={isSubmitting}
      >
        出金を申請する
      </Button>
    </div>
  );
}, {
  schema,
});
