import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

type Props = {
  onSelect?: () => void;
  selected?: boolean;
};

export const Tab: CFC<Props> = ({
  onSelect,
  selected,
  className,
  children,
}) => {
  const classes = twClassNames({
    "font-bold border-primary border-b-2": selected,
    className,
  });

  return (
    <div
      className={twClassNames("cursor-pointer py-3 px-5", classes)}
      onClick={onSelect}
    >
      {children}
    </div>
  );
};
