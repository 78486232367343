import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { PositionType, TopicType } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { PostTopicRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

const { persistAtom } = recoilPersist({ key: "toiny.web.createTopic" });

type DraftTopicType = Pick<TopicType, "title" | "body" | "bodyText"> & {
  expiresAt?: Date;
};

type StoredDraftTopicType = Pick<TopicType, "title" | "body" | "bodyText"> & {
  expiresAt?: string;
};

type DraftType<T = DraftTopicType> = {
  topic: Partial<T>;
  positions: Array<Partial<PositionType>>;
};

const draftState = atom<DraftType<StoredDraftTopicType> | null>({
  key: "topics/createTopic/draft",
  default: null,
  effects: [persistAtom],
});

export const useCreateTopic = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [draftObject, setDraft] = useRecoilState(draftState);

  const { mutateAsync: createTopic, isLoading: isMutating } = useMutation(
    ["topics/create"],
    (params: PostTopicRequest) => api.postTopic(params),
  );

  const encodeDraft = useCallback((data: DraftType): DraftType<StoredDraftTopicType> => {
    return {
      ...data,
      topic: {
        ...data.topic,
        expiresAt: data.topic.expiresAt?.toISOString()?.substring(0, 10),
      },
    };
  }, []);

  const decodeDraft = useCallback((data: DraftType<StoredDraftTopicType>): DraftType => {
    return {
      ...data,
      topic: {
        ...data.topic,
        expiresAt: data.topic.expiresAt ? new Date(data.topic.expiresAt) : undefined,
      },
    };
  }, []);

  const saveDraft = useCallback((data: DraftType) => {
    setDraft(encodeDraft(data));
  }, []);

  const clearDraft = useCallback(() => {
    setDraft(null);
  }, []);

  const draft = useMemo(() => draftObject ? decodeDraft(draftObject) : null, [draftObject]);

  return {
    draft,
    createTopic,
    saveDraft,
    clearDraft,
    isMutating,
  };
};
