import { HiOutlineCurrencyYen, HiOutlineCurrencyDollar } from "react-icons/hi";

import { twClassNames } from "shared/lib/tailwind";
import { MoneyCurrency } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  currency?: MoneyCurrency;
  size?: number;
};

export const CurrencyIcon: BFC<Props> = ({
  currency = MoneyCurrency.Jpy,
  size = 20,
  className,
}) => {
  switch (currency) {
    case MoneyCurrency.Usd:
      return (
        <HiOutlineCurrencyDollar className={twClassNames("inline-block text-black-500", className)} size={size} />
      );
    default:
      return (
        <HiOutlineCurrencyYen className={twClassNames("inline-block text-black-500", className)} size={size} />
      );
  }
};
