"use client";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { HiOutlineCalendarDays, HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { formatDateTime } from "shared/helpers";
import { Position, PositionType, TopicType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { GoogleAdsenseUnit } from "~/features/ads";
import { useAuthContext } from "~/features/auth";

import { PositionSelector, ProposalListItem, TopicDetail } from "../../components";
import { useTopic, useProposals } from "../../hooks";

type Props = {
  topic: TopicType;
  position?: PositionType;
};

export const TopicsShowPage: BFC<Props> = ({
  topic: topicData,
  position: positionData,
}) => {
  const { user } = useAuthContext();
  const { topic, positions, notes, checkout, refetch } = useTopic(topicData.id, topicData);
  const { fetchNextPage, proposals, hasNextPage, isLoading, isFetchingNextPage } = useProposals(
    topic.id,
    {
      positionId: positionData?.id,
    },
  );
  const router = useRouter();

  const selectedPosition = useMemo(() => {
    if (positionData) {
      return new Position(positionData);
    }
  }, [positionData]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const onPositionSelect = useCallback((position?: Position) => {
    if (position) {
      router.push(routes.TOPICS_POSITIONS_SHOW(topic.id, position.id));
    } else {
      router.push(routes.TOPICS_SHOW(topic.id));
    }
  }, [topic]);

  const onChartClick = useCallback(() => {
    router.push(routes.TOPICS_VOTES(topic.id));
  }, [topic]);

  const onNewProposalClick = useCallback(() => {
    router.push(routes.TOPICS_PROPOSALS_NEW(topic.id, selectedPosition?.id));
  }, [topic, selectedPosition]);

  const onChangeVisibility = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="flex flex-col gap-4">
      <TopicDetail
        topic={topic}
        notes={notes}
        checkout={checkout}
        onChangeVisibility={onChangeVisibility}
      />
      <GoogleAdsenseUnit
        slot="1831763243"
        fullWidthResponsive
      />
      <div className="border-y bg-white">
        <h2 className="flex items-center justify-between border-b p-3">
          <div className="flex items-center gap-1 font-bold">
            <HiOutlineChatBubbleOvalLeftEllipsis size={20} />
            みんなの発言
          </div>
        </h2>
        {positions.length > 0 && (
          <PositionSelector
            positions={positions}
            totalProposalsCount={topic.proposalsCount}
            selectedPosition={selectedPosition}
            onSelect={onPositionSelect}
            onChartClick={onChartClick}
            className="border-b"
          />
        )}
        <div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <div className="grid grid-cols-1 divide-y">
              {proposals.map((proposal) => (
                <Link href={routes.TOPICS_PROPOSALS_SHOW(topic.id, proposal.id)} key={proposal.id}>
                  <ProposalListItem proposal={proposal} />
                </Link>
              ))}
            </div>
          </InfiniteScroll>
          {proposals.length === 0 && !isFetchingNextPage && !isLoading && (
            <div className="text-black-500 flex flex-col items-center justify-center py-8">
              発言がまだありません
            </div>
          )}
          {(isLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
      </div>
      <div
        className="sticky inset-x-0 bottom-0 z-20 flex flex-col gap-4 border-t bg-white p-4"
        style={{ boxShadow: "0px -6px 8px 0px rgba(0, 0, 0, 0.1)" }}
      >
        <h3 className="flex flex-nowrap items-center justify-center">
          <div>「</div>
          <div className="truncate font-bold">{topic.title}</div>
          <div>」</div>
          <div className="shrink-0">について</div>
        </h3>
        <Button
          primary
          large
          block
          onClick={onNewProposalClick}
          disabled={topic.isClosed() || topic.isFixed() || topic.isExpired()}
          className="font-bold"
        >
          {selectedPosition && `「${selectedPosition.name}」として`}
          発言する
        </Button>
        {positions.length > 0 && (
          <Button
            block
            href={routes.TOPICS_VOTES(topic.id)}
          >
            投票する
          </Button>
        )}
        {topic.expiresAt && (
          <div className="flex justify-between">
            <div className="flex items-center gap-1 font-bold">
              <HiOutlineCalendarDays size={20} className="text-gray-500" />受付期間
            </div>
            <div>
              {topic.isFixed() ? (
                <span className="text-gray-500">受付を終了しました</span>
              ) : (
                <span>{formatDateTime(topic.expiresAt)}まで</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
