import classNames from "classnames";
import { useMemo } from "react";
import { PiCrownFill } from "react-icons/pi";
import { twClassNames } from "~/../../packages/shared/lib/tailwind";

import { Supporter } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage } from "~/features/users";

import { SupportIcon } from "../SupportIcon";

type Props = {
  rank?: number;
  supporter: Supporter;
};

export const SupporterListItem: BFC<Props> = ({
  rank,
  supporter,
  className,
}) => {
  const user = useMemo(() => supporter.getUser(), [supporter]);

  const rankClassName = useMemo(() => twClassNames({
    "text-yellow-500": rank === 1,
    "text-gray-500": rank === 2,
    "text-yellow-300": rank === 3,
  }), [rank]);

  return (
    <div className={classNames("flex flex-col bg-white", className)}>
      <div className="flex flex-col gap-2 p-4">
        {rank && rank < 4 && (
          <div className="flex items-center gap-1 font-bold">
            <PiCrownFill className={rankClassName} />{rank}位
          </div>
        )}
        <div className="flex justify-between">
          {user.isNormal() ? (
            <div className="flex flex-1 gap-1 overflow-hidden">
              <UserThumbnailImage user={user} size={20} />
              <div className="truncate">{user.nickname}</div>
            </div>
          ) : (
            <div className="flex flex-1 gap-1 overflow-hidden">
              <UserThumbnailImage user={user} size={20} />
              <div className="text-gray-500">ゲストユーザー</div>
            </div>
          )}
          <div className="flex gap-2">
            {supporter.getAmountsSupports().map((support) => (
              <SupportIcon key={support.amount.currency} support={support} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
