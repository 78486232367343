import { twClassNames } from "shared/lib/tailwind";
import { User } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage } from "../UserThumbnailImage";

type Props = {
  user: User;
};

export const UserListItem: BFC<Props> = ({
  user,
  className,
}) => {
  return (
    <div className={twClassNames("bg-white flex gap-3 p-3", className)}>
      <UserThumbnailImage user={user} size={40} className="shrink-0" />
      <div className="flex flex-col gap-1">
        <div className="font-bold">{user.nickname}</div>
        <div className="text-gray-400">
          @{user.handle}
          {user.jobTitle && ` / ${user.jobTitle}`}
        </div>
        <div className="line-clamp-3 text-gray-500">{user.introduction}</div>
      </div>
    </div>
  );
};
