import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Supporter } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useProposalSupporters = (id: string) => {
  const [supporters, setSupporters] = useState<Supporter[]>([]);
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { fetchNextPage, refetch, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["topics/proposals/supporters", id],
    ({ pageParam }) => api.getProposalSupporters(id, pageParam),
    {
      enabled: !!id,
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setSupporters(pages.flatMap(({ data: { supporters } }) => supporters.map((c) => new Supporter(c))));
      },
    },
  );

  return {
    supporters,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
