import { useCallback } from "react";
import { z } from "zod";

import { Button } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";
import { TopicNoteBodyType, Topic } from "shared/models";

import { EditorJsChangeEvent, EditorJsControlGroup } from "~/components";
import { editorjsDataToText } from "~/lib/editorjs";

const schema = z.object({
  note: z.object({
    body: z.string().min(1).max(100000),
    bodyText: z.string(),
    bodyType: z.nativeEnum(TopicNoteBodyType),
    bodyData: z.any(),
  }),
});

export type CreateNoteData = z.infer<typeof schema>;

type Props = {
  topic: Topic;
  isPending?: boolean;
};

export const CreateNoteForm = createHookForm<CreateNoteData, Props>(({
  formState: { isSubmitting },
  setValue,
  isPending,
}) => {
  const onBodyChange: EditorJsChangeEvent = useCallback((data) => {
    const text = editorjsDataToText(data);
    setValue("note.body", JSON.stringify(data));
    setValue("note.bodyText", text);
  }, [setValue]);

  return (
    <div className="flex flex-col gap-4">
      <EditorJsControlGroup
        name="note.bodyData"
        label="本文"
        placeholder="補足を記載してください"
        inputClassName="min-h-64"
        onChange={onBodyChange}
        required
      />
      <Button
        type="submit"
        block
        primary
        large
        loading={isSubmitting || isPending}
      >
        補足を追加する
      </Button>
    </div>
  );
}, {
  schema,
  defaultValues: {
    note: {
      body: "",
      bodyText: "",
      bodyType: TopicNoteBodyType.Editorjs,
      bodyData: {},
    },
  },
});
