import { Components } from "react-markdown";

const components: Components = {
  ul: ({ children }) => (
    <ul className="mt-3 list-inside list-disc">{children}</ul>
  ),
  li: ({ children }) => (
    <li className="mt-1">{children}</li>
  ),
};

export default components;
