import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Notification } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuth } from "~/features/auth";

const { persistAtom } = recoilPersist({ key: "toiny.web.notifications" });

const notificationsCountState = atom<number>({
  key: "count",
  default: 0,
  effects: [persistAtom],
});

export const useNotifications = () => {
  const [notificationsCount, setNotificationsCount] = useRecoilState(notificationsCountState);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { accessToken, isSignedIn } = useAuth();
  const api = useWebAPI({ accessToken });

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["notifications"],
    ({ pageParam }) => api.getNotifications(pageParam),
    {
      enabled: isSignedIn,
      getNextPageParam: (last) => last.meta.paging.next,
      onSuccess: ({ pages }) => {
        setNotifications(pages.flatMap(({ data: { notifications } }) => notifications.map((n) => new Notification(n))));
      },
    }
  );

  const { mutateAsync: read, isLoading: isReadMutating } = useMutation(
    ["notifications/read"],
    (lastNotificationId: string) => api.readNotifications({ lastNotificationId }),
    {
      onSuccess: () => {
        setNotificationsCount(0);
      },
    }
  );

  useEffect(() => {
    let count = 0;
    for (const notification of notifications) {
      if (notification.isRead()) break;
      count = count + notification.getCount();
    }
    setNotificationsCount(count);
  }, [notifications]);

  return {
    notifications,
    notificationsCount,
    fetchNextPage,
    hasNextPage,
    read,
    isLoading,
    isFetching,
    isFetchingNextPage,
    isReadMutating,
    isMutating: isReadMutating,
  };
};
