import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  link: string;
  meta: {
    title?: string;
    description?: string;
    image?: {
      url?: string;
    };
  };
};

export const LinkBlock: BFC<Props> = ({
  link,
  meta,
  className,
}) => {
  return (
    <a href={link} className={classNames("flex flex-col md:flex-row rounded-lg border overflow-hidden items-stretch", className)}>
      {meta.image && meta.image.url && (
        <img src={meta.image.url} className="h-32 w-full shrink-0 object-cover md:h-auto md:w-1/3" alt={meta.title} />
      )}
      <div className="flex flex-col gap-1 p-3">
        <h4 className="line-clamp-2 font-bold">{meta.title}</h4>
        <p className="line-clamp-3 text-sm text-gray-500 md:line-clamp-6">{meta.description}</p>
      </div>
    </a>
  );
};
