import classNames from "classnames";
import { useRouter } from "next/navigation";
import { HTMLAttributes, useCallback, useMemo } from "react";
import { toast } from "react-hot-toast";
import { HiOutlineClock, HiOutlineDocumentText, HiOutlineLockClosed, HiOutlineLockOpen, HiOutlineXCircle } from "react-icons/hi2";
import { PiCrown } from "react-icons/pi";

import { PopoverMenu } from "shared/features/utils";
import { formatDate } from "shared/helpers";
import { Checkout, Topic, TopicNote } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { CategoryBadge } from "~/features/categories";
import { Note } from "~/features/topics/components/Note";
import { UserThumbnailImage } from "~/features/users";
import { CurrencyIcon } from "~/features/utils";
import { EditorJsData } from "~/lib/editorjs";

import { useCancelTopicCheckout, useTopicVisibility } from "../../hooks";
import { Body } from "../Body";
import { TopicActions } from "../TopicActions";
import { TopicRewardDetail } from "../TopicRewardDetail";

type Props = {
  topic: Topic;
  notes?: TopicNote[];
  checkout?: Checkout | null;
  onChangeVisibility?: () => void;
};

export const TopicDetail: BFC<Props> = ({
  topic,
  notes = [],
  checkout,
  onChangeVisibility,
  className,
}) => {
  const router = useRouter();
  const { user } = useAuthContext();
  const { close, open } = useTopicVisibility(topic.id);
  const { cancel } = useCancelTopicCheckout(topic.id);

  const categories = topic.getCategories();
  const owner = topic.getUser();

  const closeHandler = useCallback(async () => {
    await close();
    onChangeVisibility?.();
    toast.success("トピックを非公開にしました");
  }, [close]);

  const openHandler = useCallback(async () => {
    await open();
    onChangeVisibility?.();
    toast.success("トピックを公開しました");
  }, [open]);

  const cancelHandler = useCallback(async () => {
    await cancel();
    onChangeVisibility?.();
    toast.success("トピックの支払いをキャンセルしました");
  }, [cancel]);

  const onNoteDestroy = useCallback(() => {
    toast.success("補足を削除しました");
    onChangeVisibility?.();
  }, [onChangeVisibility]);

  const newNoteHandler = useCallback(() => {
    router.push(routes.TOPICS_NOTES_NEW(topic.id));
  }, [topic]);

  const selectBestProposalHandler = useCallback(() => {
    router.push(routes.TOPICS_SELECT_BEST_PROPOSAL(topic.id));
  }, [topic]);

  const coverImage = useMemo(() => topic.coverImage?.webp ?? topic.coverImage, [topic.coverImage]);
  const coverAttributesFactory = useCallback((className: string): HTMLAttributes<HTMLDivElement> => {
    return {
      style: coverImage ? { backgroundImage: `url("${coverImage.url}")` } : {},
      className: classNames("px-4 pt-4", className, {
        "h-48 bg-cover bg-center text-white": coverImage?.url,
      }),
    };
  }, [topic, coverImage]);

  return (
    <div className={classNames("border-b bg-white", className)}>
      <div {...coverAttributesFactory("flex flex-col justify-between")}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {topic.isClosed() && (
              <div className="flex items-center gap-1 rounded-full bg-neutral-600 px-2 py-1 text-sm text-white">
                <HiOutlineLockClosed />非公開
              </div>
            )}
            {categories.length > 0 && (
              <div className="flex items-center gap-1">
                {categories.map((category) => (
                  <CategoryBadge key={category.id} category={category} />
                ))}
              </div>
            )}
            {categories.length === 0 && (
              <CategoryBadge label="未分類" />
            )}
          </div>
          <PopoverMenu>
            <PopoverMenu.Item
              onClick={newNoteHandler}
              hidden={!topic.isOwner}
              className="flex items-center gap-1"
            >
              <HiOutlineDocumentText size={18} />
              補足を追加
            </PopoverMenu.Item>
            <PopoverMenu.Item
              onClick={closeHandler}
              hidden={topic.isClosed() || !topic.isOwner}
              disabled={!topic.isOwner}
              className="flex items-center gap-1"
            >
              <HiOutlineLockClosed size={18} />
              非公開にする
            </PopoverMenu.Item>
            <PopoverMenu.Item
              onClick={openHandler}
              hidden={topic.isOpen() || !topic.isOwner}
              disabled={!user.isSignedIn() || !topic.isOwner || (!!checkout && !checkout.isCancellable())}
              className="flex items-center gap-1"
            >
              <HiOutlineLockOpen size={18} />
              公開する
            </PopoverMenu.Item>
            <PopoverMenu.Item
              onClick={cancelHandler}
              hidden={topic.isOpen() || !checkout || !topic.isOwner}
              disabled={!user.isSignedIn() || !topic.isOwner || !checkout?.isCancellable()}
              className="flex items-center gap-1"
            >
              <HiOutlineXCircle size={18} />
              支払いをキャンセル
            </PopoverMenu.Item>
            <PopoverMenu.Item
              onClick={selectBestProposalHandler}
              hidden={!topic.isOwner}
              disabled={checkout?.isCanceled()}
              className="flex items-center gap-1"
            >
              {topic.hasReward() ? (
                <>
                  <CurrencyIcon currency={topic.rewardAmount?.currency} size={18} />
                  報酬を配布する
                </>
              ) : (
                <>
                  <PiCrown size={18} />
                  ベストアンサーを選ぶ
                </>
              )}
            </PopoverMenu.Item>
          </PopoverMenu>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <h1 className="break-all text-2xl font-bold">
          {topic.title}
        </h1>

        <Body
          body={topic.body}
          bodyData={topic.bodyData as EditorJsData}
          bodyType={topic.bodyType}
        />

        {notes.map((note, index) => (
          <Note
            key={note.id}
            topicId={topic.id}
            note={note}
            index={index}
            showMenu={topic.isOwner}
            onDestroy={onNoteDestroy}
            className="rounded border p-4"
          />
        ))}

        {topic.hasReward() && (
          <TopicRewardDetail topic={topic} checkout={checkout} />
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1 text-sm">
            <UserThumbnailImage user={owner} size={20} />
            <div>{owner.getNickname()}</div>
          </div>
          {topic.publishedAt && (
            <div className="text-black-500 flex items-center gap-1 text-sm">
              <HiOutlineClock size={18} />
              {formatDate(topic.publishedAt)}
            </div>
          )}
        </div>
        <TopicActions
          topic={topic}
        />
      </div>
    </div>
  );
};
