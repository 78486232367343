import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";

import { twClassNames } from "shared/lib/tailwind";
import { BFC } from "shared/types";

type Props = {
  points: number;
};

export const TrustPointsMeter: BFC<Props> = ({
  points,
  className,
}) => {
  return (
    <div className={twClassNames("w-10 h-10", className)}>
      <CircularProgressbarWithChildren
        value={points || 80}
        strokeWidth={12}
        styles={buildStyles({
          trailColor: "#AAAAAA",
          pathColor: "#FFFFFF",
        })}
      >
        <div className="text-sm font-bold text-white">{points || 80}</div>
      </CircularProgressbarWithChildren>
    </div>
  );
};
