import classNames from "classnames";
import { useRouter } from "next/navigation";
import { HTMLAttributes, MouseEvent, useCallback, useMemo } from "react";
import { HiOutlineDotsVertical, HiOutlineHeart, HiOutlineLockClosed } from "react-icons/hi";

import { formatDate } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { Topic } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { CategoryBadge } from "~/features/categories";
import { UserThumbnailImage } from "~/features/users";

import { PositionBadge } from "../PositionBadge";
import { ProposalFooter } from "../ProposalFooter";
import { TopicActions } from "../TopicActions";
import { TopicRewardBadge } from "../TopicRewardBadge";

type Props = {
  topic: Topic;
};

export const TopicCard: BFC<Props> = ({
  topic,
  className,
}) => {
  const categories = topic.getCategories();
  const user = topic.getUser();
  const proposal = topic.getProposal();
  const proposalUser = proposal?.getUser();
  const proposalPosition = proposal?.getPosition();
  const router = useRouter();

  const coverImage = useMemo(() => topic.coverImage?.webp ?? topic.coverImage, [topic.coverImage]);

  const coverAttributesFactory = useCallback((className: string): HTMLAttributes<HTMLDivElement> => {
    return {
      style: coverImage ? { backgroundImage: `url("${coverImage.url}")` } : {},
      className: classNames("px-4 pt-4", className, {
        "h-40 bg-cover text-white border-b": coverImage?.url,
      }),
    };
  }, [topic, coverImage]);

  const onProposalClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (proposal) {
      router.push(routes.TOPICS_PROPOSALS_SHOW(topic.id, proposal.id));
    }
  }, [proposal, topic]);

  return (
    <div className={twClassNames("bg-white overflow-hidden rounded-md border", className)}>
      <div {...coverAttributesFactory("flex flex-col justify-between")}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {topic.isClosed() && (
              <div className="flex items-center gap-1 rounded-full bg-neutral-600 px-2 py-1 text-sm text-white">
                <HiOutlineLockClosed />非公開
              </div>
            )}
            {categories.length > 0 && (
              <div className="flex items-center gap-1">
                {categories.map((category) => (
                  <CategoryBadge key={category.id} category={category} />
                ))}
              </div>
            )}
            {categories.length === 0 && (
              <CategoryBadge label="未分類" />
            )}
            {topic.hasReward() && (
              <TopicRewardBadge type={topic.rewardType} amount={topic.getRewardAmount()} />
            )}
          </div>
          <HiOutlineDotsVertical className="text-black-500" size={20} />
        </div>
      </div>
      <div className={classNames("flex flex-col gap-4 p-4")}>
        <div className="text-lg font-bold">
          {topic.title}
        </div>
        <div className="line-clamp-3 break-all">
          {topic.bodyText}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1 text-sm">
            <UserThumbnailImage user={user} size={20} />
            <div>{user.getNickname()}</div>
          </div>
          {topic.expiresAt && (
            <div className="text-black-500 text-sm">
              {formatDate(topic.expiresAt)}まで
            </div>
          )}
        </div>
        {(proposal && proposalUser) && (
          <button onClick={onProposalClick} className="text-left">
            <div className="overflow-hidden rounded border">
              <div>
                <div className="flex flex-col gap-3 p-3">
                  <div className="line-clamp-3">
                    {proposal.bodyText}
                  </div>
                  <div className="flex justify-between">
                    {proposalPosition && (
                      <div>
                        <PositionBadge position={proposalPosition} />
                      </div>
                    )}
                    <div className="flex gap-1">
                      <HiOutlineHeart size={20} className="text-black-500" />
                      <div className="text-black-400">{proposal.likesCount}</div>
                    </div>
                  </div>
                </div>
                <ProposalFooter proposal={proposal} introductionClamp />
              </div>
            </div>
          </button>
        )}
        <TopicActions
          topic={topic}
        />
      </div>
    </div>
  );
};
