import { Popover } from "@headlessui/react";
import { useCallback } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";

import { twClassNames } from "shared/lib/tailwind";
import { CFC } from "shared/types";

type ItemProps = {
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

export const PopoverMenuItem: CFC<ItemProps> = ({
  onClick,
  disabled,
  hidden,
  children,
  className,
}) => {
  const onClickHandler = useCallback(() => {
    if (disabled) return;
    onClick?.();
  }, [onClick]);

  return (
    <Popover.Button
      as="li"
      onClick={onClickHandler}
      className={twClassNames(className, {
        "text-gray-300": disabled,
        "hidden": hidden,
      })}
  >
    {children}
  </Popover.Button>
  );
};

type Props = {
  panelClassName?: string;
};

export const PopoverMenu: CFC<Props> & { Item: typeof PopoverMenuItem } = ({
  className,
  panelClassName,
  children,
}) => {
  return (
    <Popover
      className={twClassNames("relative", className)}
    >
      <Popover.Button>
        <HiOutlineDotsVertical className="text-gray-500" size={20} />
      </Popover.Button>
      <Popover.Panel className={twClassNames("absolute bg-white text-black-800 rounded shadow-md right-0 top-4 border p-3", panelClassName)}>
        <ul className="flex min-w-40 flex-col gap-3 text-sm">
          {children}
        </ul>
      </Popover.Panel>
    </Popover>
  );
};

PopoverMenu.Item = PopoverMenuItem;
