import Link from "next/link";
import { Fragment } from "react";

import { twClassNames } from "shared/lib/tailwind";
import { Topic } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
// import { GoogleAdsenseUnit } from "~/features/ads";

import { TopicCard } from "../TopicCard";

type Props = {
  topics: Topic[];
};

export const TopicCardList: BFC<Props> = ({
  topics,
  className,
}) => {
  const topicClassNameFactory = (topic: Topic) => twClassNames({
    "md:row-span-1": !topic.hasCoverImage() && !topic.hasProposal(),
    "md:row-span-2": (topic.hasCoverImage() && !topic.hasProposal()) || (!topic.hasCoverImage() && topic.hasProposal()),
    "md:row-span-3": topic.hasCoverImage() && topic.hasProposal(),
  });

  return (
    <div className={twClassNames("grid grid-cols-1 md:grid-cols-2 gap-3 md:grid-flow-row-dense", className)}>
      {topics.map((topic) => (
        <Fragment key={topic.id}>
          <Link href={routes.TOPICS_SHOW(topic.id)} className={topicClassNameFactory(topic)}>
            <TopicCard
              topic={topic}
              className="rounded-none border-x-0 border-y md:rounded-md md:border"
            />
          </Link>
          {/*i % 5 === 4 && (
            <GoogleAdsenseUnit
              layoutKey="-6p+dg+58-2b-89"
              slot="3548347209"
              format="fluid"
            />
          )*/}
        </Fragment>
      ))}
    </div>
  );
};
