"use client";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

import { BFC } from "shared/types";

const GA_ID = process.env.NEXT_PUBLIC_GA_ID;

export const GoogleAnalytics: BFC = () => {
  const pathname = usePathname();
  const params = useSearchParams();

  useEffect(() => {
    if (!GA_ID) return;

    const url = pathname + (params ? "?" + params.toString() : "");
    window.gtag("config", GA_ID, {
      page_path: url,
    });
  }, [pathname, params]);

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_ID}`}
      />
      <Script id="gtag-init" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_ID}');
        `}
      </Script>
    </>
  );
};
