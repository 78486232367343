"use client";
import Link from "next/link";
import { useCallback, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { User, UserType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ProposalListItem } from "~/features/topics";

import { UsersLayout } from "../../components";
import { useUserProposals } from "../../hooks";

type Props = {
  userData: UserType;
};

export const UsersShowPage: BFC<Props> = ({
  userData,
}) => {
  const user = useMemo(() => new User(userData), [userData]);

  const {
    proposals,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useUserProposals(user.id);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <UsersLayout user={user}>
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <div className="flex flex-col gap-3">
          {proposals.map((proposal) => (
            <Link href={routes.TOPICS_PROPOSALS_SHOW(proposal.topicId, proposal.id)} key={proposal.id}>
              <ProposalListItem
                proposal={proposal}
                showPosition
                showTopicTitle
                className="border-y"
              />
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      {proposals.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          投稿した発言はありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center border-y bg-white p-4">
          <ColorRing />
        </div>
      )}
    </UsersLayout>
  );
};
