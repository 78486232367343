import { createContext, useContext } from "react";

import { CFC } from "shared/types";

import { useNotifications } from "../../hooks";

type NotificationsContextType = ReturnType<typeof useNotifications>;

const NotificationsContext = createContext<NotificationsContextType>({
  notifications: [],
  notificationsCount: 0,
  fetchNextPage: () => Promise.resolve({} as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  hasNextPage: false,
  read: () => Promise.resolve({} as any), // eslint-disable-line @typescript-eslint/no-explicit-any
  isLoading: false,
  isFetching: false,
  isFetchingNextPage: false,
  isReadMutating: false,
  isMutating: false,
});

export const useNotificationsContext = () => useContext(NotificationsContext);

export const NotificationsProvider: CFC = ({ children }) => {
  const value = useNotifications();

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  );
};
