import { MouseEvent, useCallback, useMemo } from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";

import { Link } from "shared/components";
import { useBooleanToggleState } from "shared/hooks";
import { twClassNames } from "shared/lib/tailwind";
import { Proposal, Topic } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { UserThumbnailImage } from "~/features/users";
import { EditorJsData } from "~/lib/editorjs";

import { Body } from "../Body";

type Props = {
  topic: Topic;
  proposal: Proposal;
};

export const ProposalDetailAccordion: BFC<Props> = ({
  topic,
  proposal,
  className,
}) => {
  const user = useMemo(() => proposal.getUser(), [proposal]);
  const [opened, toggle] = useBooleanToggleState(false);

  const onToggleHandler = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  }, [toggle]);

  return (
    <div className={twClassNames("border-b bg-white", className)}>
      <div className="flex flex-col gap-4 p-4">
        <Link href={routes.TOPICS_SHOW(topic.id)}>
          <h2 className="font-bold">{topic.title}</h2>
        </Link>
        <div className="flex items-center gap-1 text-sm">
          <UserThumbnailImage user={user} size={20} />
          <div className="truncate">{user.getNickname()}さんの発言</div>
        </div>
        {opened ? (
          <>
            <Body
              body={proposal.body}
              bodyData={proposal.bodyData as EditorJsData}
              bodyType={proposal.bodyType}
            />
          </>
        ) : (
          <div className="line-clamp-3">
            {proposal.bodyText}
          </div>
        )}
      </div>
      <div className="flex justify-center p-4 pt-0" onClick={onToggleHandler}>
        {opened ? (
          <HiOutlineChevronUp />
        ) : (
          <HiOutlineChevronDown />
        )}
      </div>
    </div>
  );
};
