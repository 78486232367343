import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Proposal } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useClippedProposals = () => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [proposals, setProposals] = useState<Proposal[]>([]);

  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["clips/proposals"],
    ({ pageParam }) => api.getClippedProposals(pageParam ?? 1),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setProposals(pages.flatMap(({ data: { proposals } }) => proposals.map((p) => new Proposal(p))));
      },
    }
  );

  return {
    proposals,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
