import classNames from "classnames";
import { useCallback } from "react";
import { z } from "zod";

import {
  Button,
  ErrorMessage,
  InputLabel,
  TextAreaControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";
import { Position } from "shared/models";

const schema = z.object({
  vote: z.object({
    body: z.string().max(1000).optional(),
    positionId: z.string(),
  }),
});

export type VoteData = z.infer<typeof schema>;

type Props = {
  positions: Position[];
};

export const VoteForm = createHookForm<VoteData, Props>(({
  formState: { isSubmitting, errors },
  watch,
  setValue,
  positions,
}) => {
  const positionId = watch("vote.positionId");

  const onPositionSelect = useCallback((position: Position) => () => {
    setValue("vote.positionId", position.id);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div>
        <InputLabel label="選択肢・立ち位置" />
        <div className="mt-2 flex flex-col gap-2">
          {positions.map((position) => (
            <div
              key={position.id}
              onClick={onPositionSelect(position)}
              className={classNames("rounded border p-3 text-lg", { "font-bold border-primary": positionId === position.id })}
            >
              {position.name}
            </div>
          ))}
        </div>
        {errors.vote?.positionId?.message && (
          <ErrorMessage message={errors.vote.positionId.message} />
        )}
      </div>
      <TextAreaControlGroup
        name="vote.body"
        label="コメント"
        placeholder="なにか一言あればどうぞ！"
        inputClassName="h-16"
      />
      <Button type="submit" block primary large loading={isSubmitting}>
        投票する
      </Button>
    </div>
  );
}, {
  schema,
});
