import { useCallback, useMemo } from "react";
import { PiCrownFill } from "react-icons/pi";

import { formatPriceWithCurrency } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { Proposal, TopicBestProposal } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage } from "~/features/users";

import { PositionBadge } from "../PositionBadge";

type Props = {
  bestProposal: TopicBestProposal;
  onClick?: (proposal: Proposal) => void;
};

export const BestProposalListItem: BFC<Props> = ({
  bestProposal,
  onClick,
  className,
}) => {
  const proposal = useMemo(() => bestProposal.getProposal(), [bestProposal]);
  const user = useMemo(() => proposal.getUser(), [proposal]);
  const position = useMemo(() => proposal.getPosition(), [proposal]);

  const onClickHandler = useCallback(() => {
    onClick?.(proposal);
  }, [onClick, proposal]);

  return (
    <div className={twClassNames("flex gap-3", className)}>
      <div className="flex w-10 flex-col items-center gap-1">
        {bestProposal.best ? (
          <>
            <PiCrownFill className="text-yellow-500" size={20} />
            <div className="text-xs font-bold">ベスト</div>
          </>
        ) : (
          <PiCrownFill className="text-gray-300" size={20} />
        )}
      </div>
      <div className="flex w-full flex-col gap-3">
        <div className="flex cursor-pointer justify-between gap-2" onClick={onClickHandler}>
          <div className="flex items-center gap-1 text-sm">
            <UserThumbnailImage user={user} size={20} />
            <div className="truncate">{user.getNickname()}さんの発言</div>
          </div>
          {position && (
            <PositionBadge position={position} />
          )}
        </div>
        <div className="line-clamp-3 w-full cursor-pointer" onClick={onClickHandler}>
          {proposal.bodyText}
        </div>
        {bestProposal.thanksComment && (
          <div className="rounded border p-3 text-sm text-gray-500">
            {bestProposal.thanksComment}
          </div>
        )}
        {bestProposal.rewardAmount && (
          <div className="text-right font-bold">
            {formatPriceWithCurrency(bestProposal.rewardAmount.amount, bestProposal.rewardAmount.currency)}
          </div>
        )}
      </div>
    </div>
  );
};
