import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { addDays, endOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { useFieldArray } from "react-hook-form";
import toast from "react-hot-toast";
import { HiOutlineCalendar, HiOutlineCamera, HiXCircle } from "react-icons/hi";
import { z } from "zod";

import {
  Button,
  ErrorMessage,
  InputLabel,
  LoadingOverlay,
  NumberControlGroup,
  SelectControlGroup,
  TextAreaControlGroup,
  ToggleControlGroup,
} from "shared/components";
import { formatCurrency, formatDate } from "shared/helpers";
import { useBooleanState } from "shared/hooks";
import { createHookForm } from "shared/lib/hook-form";
import { Checkout, MoneyCurrency, TopicBodyType, TopicRewardType } from "shared/models";

import { EditorJsChangeEvent, EditorJsControlGroup } from "~/components";
import { PaymentMethodListItem, PaymentMethodsModal } from "~/features/checkouts";
import { PaymentDetail } from "~/features/checkouts";
import { useTopicCheckoutServiceContext } from "~/features/topics/hooks";
import { AmountSlider, useDirectUpload } from "~/features/utils";
import { editorjsDataToText } from "~/lib/editorjs";

import { PositionControlGroup } from "./PositionControlGroup";

const schema = z.object({
  withReward: z.boolean(),
  topic: z.object({
    title: z.string().min(1).max(140),
    body: z.string().max(100000).optional(),
    bodyText: z.string().optional(),
    bodyType: z.nativeEnum(TopicBodyType),
    bodyData: z.any(),
    rewardType: z.nativeEnum(TopicRewardType),
    rewardAmount: z.number().min(100).max(1000000).optional(),
    rewardCurrency: z.nativeEnum(MoneyCurrency).optional(),
    rewardAnswersAmount: z.number().min(1).max(10).optional(),
    expiresAt: z.date().optional().refine((expiresAt) => {
      if (!expiresAt) return true;
      return endOfDay(expiresAt) > addDays(new Date(), 7);
    }, { message: "7日以上先の日付を選択してください" }),
    coverImage: z.string().optional(),
    asAnonymous: z.boolean().optional(),
  }),
  positions: z.array(z.object({
    name: z.string().max(10),
    displayOrder: z.number(),
  })).superRefine((values, ctx) => {
    // 重複チェック
    const names = values.map((v) => v.name);
    values.forEach((v, i) => {
      if (names.indexOf(v.name) !== i) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [i, "name"],
          message: "重複しています",
        });
      }
    });
  }).transform((value) => {
    return value.filter((v) => !!v.name).map((v, i) => ({ ...v, displayOrder: i }));
  }),
});

export type CreateTopicData = z.infer<typeof schema>;

const rewardSelectOptions = [
  { value: TopicRewardType.AllForBest, label: "全額をベストアンサーに支払う" },
  { value: TopicRewardType.DistributeByOwner, label: "複数の回答者に分配する" },
  { value: TopicRewardType.DistributeByAuto, label: "自動で分配する" },
];

type Props = {
  checkout?: Checkout;
  onChange?: (data: CreateTopicData) => void;
  isPending?: boolean;
  isFailed?: boolean;
  errorMessages?: Map<string, string>;
};

export const CreateTopicForm = createHookForm<CreateTopicData, Props>(({
  control,
  watch,
  setValue,
  getValues,
  formState: { isSubmitting, errors },
  onChange,
  isPending,
  isFailed,
}) => {
  const withReward = watch("withReward");
  const rewardType = watch("topic.rewardType");
  const rewardAmount = watch("topic.rewardAmount") ?? 0;
  const rewardCurrency = watch("topic.rewardCurrency") ?? MoneyCurrency.Jpy;
  const rewardAnswersAmount = watch("topic.rewardAnswersAmount");
  const { upload, uploadedFile, isUploading } = useDirectUpload();
  const { fields, append, move, remove } = useFieldArray({ control, name: "positions" });
  const expiresAt = watch("topic.expiresAt");
  const [oldExpiresAt, setOldExpiresAt] = useState(expiresAt);
  const {
    checkout,
    paymentMethod,
    setPaymentMethod,
    isReady,
    isProcessing,
    calculate,
  } = useTopicCheckoutServiceContext();
  const [shownPaymentMethodsModal, showPaymentMethodsModal, hidePaymentMethodsModal] = useBooleanState(false);

  const hasReward = useMemo(() => rewardType !== TopicRewardType.None, [rewardType]);

  useEffect(() => {
    if (withReward) {
      if (getValues("topic.rewardType") === TopicRewardType.None) {
        setValue("topic.rewardType", TopicRewardType.AllForBest);
        setValue("topic.rewardAmount", 100);
        setValue("topic.rewardCurrency", MoneyCurrency.Jpy);
      }
    } else {
      setValue("topic.rewardType", TopicRewardType.None);
      setValue("topic.rewardAmount", undefined);
      setValue("topic.rewardCurrency", undefined);
    }
  }, [withReward]);

  useEffect(() => {
    if (rewardAmount) {
      if (rewardAmount < 100) {
        setValue("topic.rewardAmount", 100);
      } else if (rewardAmount > 100000) {
        setValue("topic.rewardAmount", 100000);
      }
    }
  }, [rewardAmount]);

  useEffect(() => {
    if (rewardType == TopicRewardType.DistributeByOwner || rewardType == TopicRewardType.DistributeByAuto) {
      if (!rewardAnswersAmount) {
        setValue("topic.rewardAnswersAmount", 10);
      }
    } else {
      setValue("topic.rewardAnswersAmount", undefined);
    }
  }, [rewardType, rewardAnswersAmount]);

  useEffect(() => {
    if (hasReward && rewardAmount && rewardCurrency) {
      calculate({ topic: { rewardType, rewardAmount, rewardCurrency, expiresAt } });
    }
  }, [hasReward, rewardAmount, rewardCurrency, expiresAt]);

  const rewardAmountValues = useMemo(() => {
    return [
      100,
      200,
      500,
      1000,
      2000,
      5000,
      10000,
    ];
  }, []);

  const onRewardAmountSliderChange = useCallback((value: number) => {
    setValue("topic.rewardAmount", value);
    onChange?.(getValues());
  }, []);

  const onBodyChange: EditorJsChangeEvent = useCallback((data) => {
    const text = editorjsDataToText(data);
    setValue("topic.body", JSON.stringify(data));
    setValue("topic.bodyText", text);
    onChange?.(getValues());
  }, [setValue]);

  const onAddPositionClick = useCallback(() => {
    append({ name: "", displayOrder: fields.length });
  }, [append, fields.length]);

  const onDragEnd = useCallback(({ active, over }: DragEndEvent) => {
    const activeIndex = fields.findIndex((field) => field.id === active.id);
    const overIndex = fields.findIndex((field) => field.id === over?.id);

    move(activeIndex, overIndex);
    onChange?.(getValues());
  }, [fields, move]);

  const onRemovePositionClick = useCallback((id: string) => {
    const index = fields.findIndex((field) => field.id === id);
    remove(index);
    onChange?.(getValues());
  }, [fields, remove]);

  const onExpiresAtChange = useCallback((close: () => void) => (date: Date | null) => {
    setValue("topic.expiresAt", date || undefined, { shouldDirty: true });
    close();
  }, [setValue]);

  const onExpiresAtClearClick = useCallback(() => {
    setValue("topic.expiresAt", undefined);
  }, [setValue]);

  const onChangeHandler = useCallback(() => {
    onChange?.(getValues());
  }, [onChange]);

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedFiles.length || isUploading) return;

    await Promise.all(acceptedFiles.map(async (file) => {
      const blob = await upload(file);
      setValue("topic.coverImage", blob.signed_id);
    }));
  }, [isUploading, upload, setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    onDropRejected: () => {
      toast.error("ファイルのアップロードに失敗しました");
    },
    disabled: isUploading || isSubmitting,
  });

  const coverImageUrl = useMemo(() => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile.file);
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (expiresAt?.getTime() !== oldExpiresAt?.getTime()) {
      setOldExpiresAt(expiresAt);
      onChange?.(getValues());
    }
  }, [expiresAt]);

  return (
    <div className="flex flex-col gap-4">
      <TextAreaControlGroup
        name="topic.title"
        label="話題・議題"
        placeholder="簡潔に内容を記載ください"
        required
        onChange={onChangeHandler}
      />
      <EditorJsControlGroup
        name="topic.bodyData"
        label="詳細・補足"
        placeholder="詳細や補足があれば記載ください"
        inputClassName="min-h-64"
        onChange={onBodyChange}
      />
      <div>
        <InputLabel label="選択肢" className="mb-2" />
        <DndContext onDragEnd={onDragEnd}>
          <SortableContext id="positions" items={fields.map((f) => f.id)}>
            <div className="flex flex-col gap-2">
              {fields.map((field, index) => (
                <PositionControlGroup
                  key={field.id}
                  id={field.id}
                  index={index}
                  onRemoveClick={onRemovePositionClick}
                />
              ))}
              {fields.length < 10 && (
                <Button block onClick={onAddPositionClick} className="mt-2">選択肢を追加する</Button>
              )}
            </div>
          </SortableContext>
        </DndContext>
      </div>

      <LoadingOverlay loading={isUploading}>
        <div>
          <InputLabel label="カバー画像" className="mb-2" />
          <div
            {...getRootProps()}
            className={classNames("relative mx-auto flex h-32 w-full items-center justify-center rounded border overflow-hidden", {
              "bg-white": !isDragActive,
              "bg-gray-100 border-dashed border-primary": isDragActive,
              "bg-gray-100": isUploading,
            })}
          >
            <input {...getInputProps()} />
            {coverImageUrl && (
              <img
                src={coverImageUrl}
                className="h-full w-full object-cover"
              />
            )}
            {!isUploading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <HiOutlineCamera
                  size={32}
                  className="mx-auto text-gray-300"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>

      <div>
        <InputLabel label="回答期限" className="mb-2" />
        <div>
          <Popover>
            {({ close }) => (
              <div className="flex justify-between">
                <Popover.Button>
                  <div className="flex items-center gap-1">
                    <HiOutlineCalendar size={24} />
                    {expiresAt ? (
                      <div className="text-black-400">
                        {formatDate(expiresAt)}
                      </div>
                    ) : (
                      <div className="text-black-400">未設定</div>
                    )}
                  </div>
                </Popover.Button>
                <Popover.Panel className="absolute left-12 z-10">
                  <ReactDatePicker selected={expiresAt} onChange={onExpiresAtChange(close)} inline />
                </Popover.Panel>
                {expiresAt && (
                  <button onClick={onExpiresAtClearClick}>
                    <HiXCircle size={24} className="text-black-400" />
                  </button>
                )}
              </div>
            )}
          </Popover>
          {errors.topic?.expiresAt?.message && (
            <ErrorMessage message={errors.topic.expiresAt.message} />
          )}
          <div className="text-black-400 mt-2 text-sm">
            回答期限を設定しない場合、自動的に1週間後に設定されます。
          </div>
        </div>
      </div>

      <div>
        <InputLabel label="報酬設定" className="mb-2" />
        <div className="flex flex-col gap-3">
          <ToggleControlGroup
            name="withReward"
            inputLabel="報酬を設定する"
            position="right"
          />
          {withReward && (
            <>
              <div className="flex grow items-center justify-end gap-1">
                <div className="flex items-center gap-2">
                  <span className="text-lg">
                    {formatCurrency(rewardCurrency)}
                  </span>
                  <NumberControlGroup
                    name="topic.rewardAmount"
                    className="w-24"
                    min={100}
                    max={100000}
                    inputClassName="text-right font-bold"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
              <AmountSlider
                value={rewardAmount}
                values={rewardAmountValues}
                onChange={onRewardAmountSliderChange}
                className="max-w-md"
              />
              <SelectControlGroup
                name="topic.rewardType"
                items={rewardSelectOptions}
                onChange={onChangeHandler}
              />
              {rewardType != TopicRewardType.AllForBest && (
                <div className="flex items-center justify-between gap-2">
                  <label>報酬の分配人数</label>
                  <NumberControlGroup
                    name="topic.rewardAnswersAmount"
                    inputClassName="!w-24 text-right"
                    onChange={onChangeHandler}
                  />
                </div>
              )}
              <div className="text-black-400 mt-2 rounded border bg-gray-50 p-3 text-sm">
                期限までに報酬が配布されなかった場合、自動的に配布が行われます。<br />
                報酬の配布期限は、回答期限から1週間です。<br />
                また6日経過して回答がつかない場合、トピックは自動的にキャンセルされ全額が返金されます。
              </div>
            </>
          )}
        </div>
      </div>

      {hasReward && (
        <>
          <div className="flex flex-col gap-3">
            <InputLabel label="お支払い方法" />
            {paymentMethod ? (
              <div className="flex items-center justify-between gap-3">
                <PaymentMethodListItem paymentMethod={paymentMethod} />
                <Button small onClick={showPaymentMethodsModal}>変更</Button>
              </div>
            ) : (
              <div className="flex items-center justify-between gap-3">
                <div className="text-gray-500">お支払い方法が登録されていません</div>
                <Button small onClick={showPaymentMethodsModal}>登録</Button>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <InputLabel label="お支払い内容" />
            <PaymentDetail checkout={checkout} />
          </div>
        </>
      )}

      <div>
        <InputLabel label="その他の設定" className="mb-2" />
        <ToggleControlGroup
          name="topic.asAnonymous"
          inputLabel="匿名で投稿する"
          position="right"
        />
      </div>

      {isFailed && (
        <ErrorMessage message="トピックの投稿に失敗しました" />
      )}

      <Button
        type="submit"
        block
        primary
        large
        disabled={(hasReward && !isReady)}
        loading={isSubmitting || isProcessing || isPending}
      >
        利用規約に同意して投稿する
      </Button>

      <PaymentMethodsModal
        selectedPaymentMethod={paymentMethod}
        onSelected={setPaymentMethod}
        open={shownPaymentMethodsModal}
        onClose={hidePaymentMethodsModal}
      />
    </div>
  );
}, {
  schema,
});
