import classNames from "classnames";

import { Vote } from "shared/models";
import { BFC } from "shared/types";

import { LikeButton } from "~/features/utils";

import { useLikeVote } from "../../hooks";
import { PositionBadge } from "../PositionBadge";

type Props = {
  vote: Vote;
};

export const VoteListItem: BFC<Props> = ({
  vote,
  className,
}) => {
  const { like, unlike, isMutating } = useLikeVote(vote.id);

  return (
    <div className={classNames("flex flex-col bg-white", className)}>
      <div className="flex flex-col gap-4 p-4">
        <div>
          {vote.body}
        </div>
        <div className="flex justify-end">
          <div className="flex justify-end gap-3">
            <PositionBadge position={vote.getPosition()} />
            <LikeButton like={like} unlike={unlike} likeable={vote} isMutating={isMutating} />
          </div>
        </div>
      </div>
    </div>
  );
};
