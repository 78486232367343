import classNames from "classnames";
import { useRouter } from "next/navigation";
import { MouseEvent, useCallback, useMemo } from "react";
import { HiOutlineChatBubbleOvalLeft, HiOutlineClock, HiOutlineStar, HiStar } from "react-icons/hi2";
import { PiCrownFill } from "react-icons/pi";

import { formatPriceWithCurrency } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { Proposal } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ClipButton, LikeButton } from "~/features/utils";

import { useLikeProposal, useClipProposal } from "../../hooks";
import { PositionBadge } from "../PositionBadge";
import { PrioritizedProgressBar } from "../PrioritizedProgressBar";
import { PrioritizedRemainingTime } from "../PrioritizedRemainingTime";
import { ProposalFooter } from "../ProposalFooter";
import { SupportBadge } from "../SupportBadge";

type Props = {
  proposal: Proposal;
  actionable?: boolean;
  showPosition?: boolean;
  showTopicTitle?: boolean;
  onClick?: (proposal: Proposal) => void;
};

export const ProposalListItem: BFC<Props> = ({
  proposal,
  actionable = true,
  showPosition = false,
  showTopicTitle = false,
  onClick,
  className,
}) => {
  const router = useRouter();
  const { like, unlike, isMutating: isLikeMutating } = useLikeProposal(proposal.id);
  const { clip, unclip, isMutating: isClipMutating } = useClipProposal(proposal.id);

  const topic = useMemo(() => proposal.getTopic(), [proposal]);
  const supports = useMemo(() => proposal.getSupports(), [proposal]);
  const position = useMemo(() => proposal.getPosition(), [proposal]);
  const bestProposal = useMemo(() => proposal.getBestProposal(), [proposal]);
  const bestIconClassName = useMemo(() => {
    return classNames({
      "text-yellow-500": bestProposal?.best,
      "text-gray-300": !bestProposal?.best,
    });
  }, [bestProposal]);

  const onClickHandler = useCallback(() => {
    onClick?.(proposal);
  }, [onClick, proposal]);

  const onSupportersClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    router.push(routes.PROPOSALS_SUPPORTERS(proposal.id));
  }, [proposal]);

  return (
    <div className={twClassNames("flex flex-col bg-white", className)} onClick={onClickHandler}>
      <div className="flex flex-col gap-4 p-4">
        {showTopicTitle && (
          <div className="text-black-500 truncate text-sm font-bold">
            {topic.title}
          </div>
        )}
        {supports.length > 0 && (
          <div className="flex items-center gap-2">
            {supports.map((support) => (
              <SupportBadge key={support.id} support={support} />
            ))}
          </div>
        )}
        {(bestProposal || (showPosition && position)) && (
          <div className="flex gap-3">
            {showPosition && position && (
              <PositionBadge position={position} />
            )}
            {bestProposal && (
              <div className="flex items-center gap-2">
                <PiCrownFill className={twClassNames(bestIconClassName)} size={20} />
                {bestProposal.best ? (
                  <div className="text-xs font-bold">ベストアンサー</div>
                ) : (
                  <div className="text-xs font-bold">ベターアンサー</div>
                )}
                {bestProposal.rewardAmount && (
                  <div className="text-black-400 rounded border px-1 py-px text-xs font-bold">
                    {formatPriceWithCurrency(bestProposal.rewardAmount.amount, bestProposal.rewardAmount.currency)}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="line-clamp-2">
          {proposal.bodyText}
        </div>
        <div className="flex justify-between">
          <div className="flex gap-1">
            <div className="flex items-center gap-1" onClick={onSupportersClick}>
              {proposal.supported ? (
                <HiStar className="text-yellow-500" size={20} />
              ) : (
                <HiOutlineStar className="text-black-500" size={20} />
              )}
              <div className="text-black-400">{proposal.supportsCount}</div>
            </div>
            {proposal.isPrioritized() && (
              <div className="ml-1 flex items-center gap-1 text-gray-400">
                <HiOutlineClock className="inline-block" size={20} />
                <div>
                  残り
                  <PrioritizedRemainingTime
                    endAt={proposal.getPrioritizedEndAt()}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end gap-3">
            <ClipButton
              clippable={proposal}
              clip={clip}
              unclip={unclip}
              isMutating={isClipMutating}
            />
            <div className="flex gap-1">
              <HiOutlineChatBubbleOvalLeft size={20} className="text-black-500" />
              <div className="text-black-400">{proposal.commentsCount}</div>
            </div>
            <LikeButton
              likeable={proposal}
              like={like}
              unlike={unlike}
              disabled={!actionable}
              isMutating={isLikeMutating}
            />
          </div>
        </div>
      </div>
      {proposal.isPrioritized() && (
        <PrioritizedProgressBar
          startAt={proposal.getPrioritizedStartAt()}
          endAt={proposal.getPrioritizedEndAt()}
          hideIfFinished
        />
      )}
      <ProposalFooter proposal={proposal} introductionClamp />
    </div>
  );
};
