"use client";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import { HiOutlineLockClosed } from "react-icons/hi2";

import { Button } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { PasswordNewForm, PasswordNewData } from "../../forms";
import { usePasswordReset } from "../../hooks";

export const PasswordNewPage: BFC = () => {
  const router = useRouter();
  const { sendResetMail } = usePasswordReset();
  const [isSentResetMail, setIsSentResetMail] = useState(false);

  const onSubmit = useCallback(async (data: PasswordNewData) => {
    return await sendResetMail(data, {
      onSuccess: () => setIsSentResetMail(true),
    });
  }, []);

  const onBackClick = useCallback(() => {
    router.push(routes.TOP);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white px-4 py-6">
        <h1 className="flex items-center gap-2 text-lg font-bold">
          <HiOutlineLockClosed size={24} className="text-black-500" />
          パスワード再設定
        </h1>
      </div>
      <div className="flex flex-col gap-4 border-t bg-white p-4 shadow">
        {isSentResetMail ? (
          <>
            <h2 className="text-lg font-bold">メール送信完了</h2>
            <p>
              パスワード再設定用のURLをご入力のメールアドレスに送信しました。<br />
              記載された内容に従って、パスワードの再設定を行なってください。
            </p>
            <Button block onClick={onBackClick}>戻る</Button>
          </>
        ) : (
          <>
            <PasswordNewForm onSubmit={onSubmit} />
          </>
        )}
      </div>
    </div>
  );
};
