import { useMemo } from "react";
import { z } from "zod";

import { Button, DateSelectControlGroup, Link, RadioControlsGroup, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";
import { UserMeGenderType } from "shared/models";

import { routes } from "~/constants";

const schema = z.object({
  user: z.object({
    email: z.string().min(1).email(),
    password: z.string().min(1).min(8, "最低8文字で入力してください"),
    passwordConfirmation: z.string().min(1),
    nickname: z.string().min(1),
    gender: z.nativeEnum(UserMeGenderType).optional(),
    birthday: z.date(),
  }).refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致しません",
      path: ["passwordConfirmation"],
    },
  ),
});

export type SignUpData = z.infer<typeof schema>;

type Props = {
  isPending?: boolean;
};

export const SignUpForm = createHookForm<SignUpData, Props>(({
  formState: { isSubmitting },
  isPending,
}) => {
  const genderItems = useMemo(() => {
    return [
      { value: UserMeGenderType.Unknown, label: "未設定" },
      { value: UserMeGenderType.Man, label: "男性" },
      { value: UserMeGenderType.Woman, label: "女性" },
    ];
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup
        name="user.nickname"
        label="ニックネーム"
        placeholder="トイニー太郎"
        note="20文字以内で入力してください"
        required
      />
      <TextControlGroup name="user.email" label="メールアドレス" placeholder="taro@toiny.net" required />
      <TextControlGroup
        name="user.password"
        type="password"
        label="パスワード"
        note="8文字以上で、他者に推測されづらい文字列を入力してください"
        required
      />
      <TextControlGroup
        name="user.passwordConfirmation"
        type="password"
        label="パスワード(確認)"
        note="確認のため、もう一度パスワードを入力してください"
        required
      />
      <DateSelectControlGroup
        name="user.birthday"
        label="生年月日"
        note="生年月日は登録後変更できません"
        required
      />
      <RadioControlsGroup
        name="user.gender"
        label="性別"
        items={genderItems}
      />
      <div className="text-sm">
        <Link href={routes.DOCUMENTS_PRIVACY_POLICY} decoration>プライバシーポリシー</Link>と
        <Link href={routes.DOCUMENTS_TERMS_OF_SERVICE} decoration>利用規約</Link>に同意の上ご登録ください
      </div>
      <Button type="submit" block primary large loading={isSubmitting || isPending}>
        同意して会員登録する
      </Button>
    </div>
  );
}, {
  schema,
  defaultValues: {
    user: {
      gender: UserMeGenderType.Unknown,
    },
  },
});
