import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { CarouselItem } from "shared/models";
import { useWebAPI } from "shared/services/api";

export const TOP_CAROUSEL_HANDLE = "top";

export const useCarouselItems = (id: string) => {
  const [carouselItems, setCarouselItems] = useState<CarouselItem[]>([]);

  const api = useWebAPI();
  const { isLoading } = useQuery(
    ["carousels/items", id],
    () => api.getCarouselItems(id),
    {
      onSuccess: ({ data: { items } }) => {
        setCarouselItems(items.map((item) => new CarouselItem(item)));
      },
    },
  );

  return {
    carouselItems,
    isLoading,
  };
};
