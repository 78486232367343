"use client";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { AiTwotoneMoneyCollect } from "react-icons/ai";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { isAPIError } from "shared/types";

import { routes } from "~/constants";

import { useConnectStripeAccount } from "../../hooks";

enum StripeConnectStatus {
  CONNECTING,
  CONNECTED,
  FAILED,
}

export const StripeConnectPage = () => {
  const router = useRouter();
  const { connect } = useConnectStripeAccount();
  const [status, setStatus] = useState<StripeConnectStatus>(StripeConnectStatus.CONNECTING);

  useEffect(() => {
    connect().then(() => {
      setStatus(StripeConnectStatus.CONNECTED);
      setTimeout(() => {
        router.push(routes.MYPAGE);
      }, 1000);
    }).catch((e) => {
      if (isAPIError(e)) {
        if (e.response.status === 409) {
          setStatus(StripeConnectStatus.CONNECTED);
          setTimeout(() => {
            router.push(routes.MYPAGE);
          }, 1000);
        } else {
          setStatus(StripeConnectStatus.FAILED);
        }
      } else {
        setStatus(StripeConnectStatus.FAILED);
      }
    });
  }, []);

  const onRetryClick = useCallback(() => {
    router.push(routes.MYPAGE_STRIPE_ACCOUNT);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <AiTwotoneMoneyCollect size={24} />
          Stripeアカウント設定
        </h1>
        <div className="text-black-400 text-sm">
          収益化のためにStripeアカウントの設定が必要です
        </div>
      </div>
      <div className="bg-white">
        {status === StripeConnectStatus.CONNECTING && (
          <div className="flex flex-col items-center gap-4 p-4">
            <ColorRing />
            <div className="text-center">Stripeと接続しています...</div>
          </div>
        )}
        {status === StripeConnectStatus.CONNECTED && (
          <div className="flex flex-col items-center gap-4 p-4">
            <div>🎉</div>
            <div>
              Stripeとの接続に成功しました！
            </div>
          </div>
        )}
        {status === StripeConnectStatus.FAILED && (
          <div className="flex flex-col items-center gap-4 p-4">
            <div>😭</div>
            <div>
              Stripeとの接続に失敗しました<br />
              お手数ですが、もう一度お試しください
            </div>
            <Button primary block large onClick={onRetryClick}>やり直す</Button>
          </div>
        )}
      </div>
    </div>
  );
};
