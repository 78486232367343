import classNames from "classnames";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

import { BFC } from "shared/types";

type Props = {
  title: string;
  message?: string;
};

export const WarningBlock: BFC<Props> = ({
  title,
  message,
  className,
}) => {
  return (
    <div className={classNames("border rounded p-4 bg-red-100", className)}>
      <div className="flex gap-3">
        <HiOutlineExclamationTriangle className="text-red-500" size={24} />
        <div
          className="font-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
      {message && (
        <div
          className="mt-3 break-all"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};
