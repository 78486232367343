import { CFC } from "shared/types";

import { NotificationBadge } from "../NotificationBadge";

type Props = {
  count: number;
};

export const WithNotificationBadge: CFC<Props> = ({
  count,
  children,
}) => {
  if (count === 0) return <>{children}</>;

  return (
    <div className="relative">
      {children}
      <div className="absolute -right-1 -top-1">
        <NotificationBadge count={count} />
      </div>
    </div>
  );
};
