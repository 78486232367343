"use client";
import { useRouter } from "next/navigation";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  UIEvent,
  useRef,
  useMemo,
} from "react";

import { Category } from "shared/models";
import { CFC } from "shared/types";

import { StandardLayout } from "~/components";
import { routes } from "~/constants";

import { useCategories } from "../../hooks";
import { CategorySelector } from "../CategorySelector";
import { SubCategorySelector } from "../SubCategorySelector";

type ContextType = {
  selectedCategory: Category | null;
  selectCategory: (category: Category | null) => void;
};

const CategorySelectorLayoutContext = createContext<ContextType>({
  selectedCategory: null,
  selectCategory: () => void(0),
});

export const useCategorySelectorLayout = () => useContext(CategorySelectorLayoutContext);

export const CategorySelectorLayout: CFC = ({
  children,
}) => {
  const router = useRouter();
  const { categories, rootCategories, findChildCategories } = useCategories();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedMainCategory, setSelectedMainCategory] = useState<Category | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<Category | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);

  const subCategories = useMemo(() => {
    if (!selectedMainCategory) return [];
    return findChildCategories(selectedMainCategory);
  }, [selectedMainCategory, findChildCategories]);

  const onMainSelect = useCallback((category: Category | null) => {
    if (category) {
      router.push(routes.CATEGORY_TOPICS(category.id));
    } else {
      router.push(routes.TOP);
    }
  }, [router]);

  const onSubSelect = useCallback((category: Category) => {
    router.push(routes.CATEGORY_TOPICS(category.id));
  }, [router]);

  const selectCategory = useCallback((category: Category | null) => {
    setSelectedCategory(category);
  }, []);

  useEffect(() => {
    const category = categories.find((c) => c.id === selectedCategory?.id);
    if (category) {
      if (!category.parent) {
        setSelectedMainCategory(category);
        setSelectedSubCategory(null);
      } else {
        setSelectedMainCategory(category.getParent());
        setSelectedSubCategory(category);
      }
    } else {
      setSelectedMainCategory(null);
      setSelectedSubCategory(null);
    }
  }, [categories, selectedCategory]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
    setShowLeftShadow(scrollLeft > 0);
    setShowRightShadow(scrollLeft + clientWidth + 1 < scrollWidth);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      setShowLeftShadow(scrollRef.current.scrollLeft > 0);
      setShowRightShadow(scrollRef.current.scrollLeft + scrollRef.current.clientWidth + 1 < scrollRef.current.scrollWidth);
    }
  }, [categories]);

  return (
    <StandardLayout>
      <CategorySelectorLayoutContext.Provider value={{ selectedCategory, selectCategory }}>
        <div className="sticky top-12 z-30 w-screen bg-white shadow-md md:w-full">
          <div
            ref={scrollRef}
            onScroll={onScroll}
            className="hidden-scrollbar overflow-x-auto"
          >
            <CategorySelector
              categories={rootCategories}
              onSelect={onMainSelect}
              selectedCategory={selectedMainCategory}
            />
          </div>
          {showLeftShadow && (
            <div className="absolute left-0 top-0 h-full w-12 bg-gradient-to-r from-white to-transparent" />
          )}
          {showRightShadow && (
            <div className="absolute right-0 top-0 h-full w-12 bg-gradient-to-l from-white to-transparent" />
          )}
        </div>
        {selectedMainCategory && subCategories.length > 0 && (
          <div className="hidden-scrollbar w-screen overflow-x-auto px-3 py-4 md:w-full">
            <SubCategorySelector
              mainCategory={selectedMainCategory}
              categories={subCategories}
              onSelect={onSubSelect}
              selectedCategory={selectedSubCategory}
            />
          </div>
        )}
        {children}
      </CategorySelectorLayoutContext.Provider>
    </StandardLayout>
  );
};
