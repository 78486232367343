import classNames from "classnames";
import { MouseEvent, useCallback } from "react";
import { FcPieChart } from "react-icons/fc";

import { Position } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  positions: Position[];
  totalProposalsCount?: number;
  selectedPosition?: Position;
  dontSelectedPosition?: boolean;
  hideChart?: boolean;
  onSelect?: (position?: Position) => void;
  onChartClick?: () => void;
};

export const PositionSelector: BFC<Props> = ({
  positions,
  totalProposalsCount = 0,
  selectedPosition,
  dontSelectedPosition,
  hideChart,
  onSelect,
  onChartClick,
  className,
}) => {
  const onChartClickHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (onChartClick) {
      onChartClick();
    }
  }, [onChartClick]);

  return (
    <div className={classNames("relative", className)}>
      <div className="hidden-scrollbar w-full overflow-x-scroll bg-white pr-12">
        <div className="flex w-max">
          <PositionSelectorItem
            proposalsCount={totalProposalsCount}
            selected={!selectedPosition && !dontSelectedPosition}
            onClick={onSelect}
            className="border-r"
          />
          {positions.map((position) => (
            <PositionSelectorItem
              key={position.id}
              position={position}
              proposalsCount={position.proposalsCount}
              selected={position.id === selectedPosition?.id && !dontSelectedPosition}
              onClick={onSelect}
              className="border-r"
            />
          ))}
        </div>
      </div>
      {!hideChart && (
        <div className="absolute inset-y-0 right-0 flex h-full items-center p-3">
          <FcPieChart size={28} className="rounded-full bg-gray-500/20" onClick={onChartClickHandler} />
        </div>
      )}
    </div>
  );
};

type PositionItemProps = {
  position?: Position;
  proposalsCount: number;
  selected?: boolean;
  onClick?: (position?: Position) => void;
};

export const PositionSelectorItem: BFC<PositionItemProps> = ({
  position,
  proposalsCount,
  selected = false,
  onClick,
  className,
}) => {
  const handleClick = useCallback(() => {
    onClick?.(position);
  }, [onClick, position]);

  return (
    <button
      className={classNames("flex items-center p-4 gap-2 cursor-pointer relative", className)}
      onClick={handleClick}
    >
      <div className={classNames("text-black-500", { "font-bold": selected })}>
        {position?.name || "すべて"}
      </div>
      <div
        className={classNames("rounded-full px-2 py-0.5 text-xs text-white", {
          "bg-primary": selected,
          "bg-gray-300": !selected,
        })}
      >
        {proposalsCount}
      </div>
      {selected && (
        <div className="bg-primary absolute inset-x-0 bottom-0 h-0.5" />
      )}
    </button>
  );
};
