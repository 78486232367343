import { z } from "zod";

import {
  Button,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1),
});

export type SignInData = z.infer<typeof schema>;

type Props = {
  isPending?: boolean;
};

export const SignInForm = createHookForm<SignInData, Props>(({
  formState: { isSubmitting },
  isPending,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup name="email" label="メールアドレス" placeholder="taro@toiny.net" required />
      <TextControlGroup name="password" type="password" label="パスワード" required />
      <Button type="submit" block primary large loading={isSubmitting || isPending}>
        ログインする
      </Button>
    </div>
  );
}, {
  schema,
});
