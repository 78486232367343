import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { HiOutlineChatBubbleOvalLeft, HiOutlineStar, HiOutlineClock, HiStar, HiShare } from "react-icons/hi2";
import { PiCrownFill } from "react-icons/pi";

import { formatPriceWithCurrency } from "shared/helpers";
import { useBooleanState } from "shared/hooks";
import { twClassNames } from "shared/lib/tailwind";
import { Proposal, Topic } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { UserThumbnailImage } from "~/features/users";
import { ClipButton, LikeButton, ShareMenuModal } from "~/features/utils";
import { EditorJsData } from "~/lib/editorjs";

import { useLikeProposal, useClipProposal } from "../../hooks";
import { Body } from "../Body";
import { PositionBadge } from "../PositionBadge";
import { PrioritizedProgressBar } from "../PrioritizedProgressBar";
import { PrioritizedRemainingTime } from "../PrioritizedRemainingTime";
import { ProposalFooter } from "../ProposalFooter";
import { SupportBadge } from "../SupportBadge";

type Props = {
  topic?: Topic;
  proposal: Proposal;
};

export const ProposalDetail: BFC<Props> = ({
  topic,
  proposal,
  className,
}) => {
  const router = useRouter();

  const { like, unlike, isMutating: isLikeMutating } = useLikeProposal(proposal.id);
  const { clip, unclip, isMutating: isClipMutating } = useClipProposal(proposal.id);
  const [shownShare, showShare, hideShare] = useBooleanState();

  const user = useMemo(() => proposal.getUser(), [proposal]);
  const position = useMemo(() => proposal.getPosition(), [proposal]);
  const supports = useMemo(() => proposal.getSupports().filter((s) => s.isPrioritized()), [proposal]);
  const bestProposal = useMemo(() => proposal.getBestProposal(), [proposal]);
  const bestIconClassName = useMemo(() => {
    return twClassNames({
      "text-yellow-500": bestProposal?.best,
      "text-gray-300": !bestProposal?.best,
    });
  }, [bestProposal]);
  const shareHashtags = useMemo(() => {
    const hashtags = [];
    if (topic) hashtags.push(topic.title.replace(/[#\r\n]/g, ""));
    if (position) hashtags.push(position.name);
    return hashtags;
  }, [topic, position]);

  const onSupportersClick = useCallback(() => {
    router.push(routes.PROPOSALS_SUPPORTERS(proposal.id));
  }, [proposal]);

  const onShareClick = useCallback(() => {
    showShare();
  }, [showShare]);

  return (
    <div className={twClassNames("bg-white", className)}>
      <h2 className="flex items-center justify-between gap-2 border-b p-3">
        <div className="flex items-center gap-1 font-bold">
          <UserThumbnailImage user={user} size={20} className="shrink-0" />
          <div className="flex">
            <div className="truncate">{user.getNickname()}</div>
            <div className="shrink-0">さんの発言</div>
          </div>
        </div>
        {position && (
          <PositionBadge position={position} className="shrink-0" />
        )}
      </h2>
      <div className="overflow-hidden border-b bg-white">
        <div>
          {supports.length > 0 && (
            <div className="hidden-scrollbar w-full overflow-x-scroll p-4 pb-0">
              <div className="flex w-max gap-2">
                {supports.map((support) => (
                  <SupportBadge key={support.id} support={support} />
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4 p-4">
            {bestProposal && (
              <div className="flex items-center gap-2">
                <PiCrownFill className={twClassNames(bestIconClassName)} size={20} />
                {bestProposal.best ? (
                  <div className="text-xs font-bold">ベストアンサー</div>
                ) : (
                  <div className="text-xs font-bold">ベターアンサー</div>
                )}
                {bestProposal.rewardAmount && (
                  <div className="text-black-400 rounded border px-1 py-px text-xs font-bold">
                    {formatPriceWithCurrency(bestProposal.rewardAmount.amount, bestProposal.rewardAmount.currency)}
                  </div>
                )}
              </div>
            )}
            <Body
              body={proposal.body}
              bodyData={proposal.bodyData as EditorJsData}
              bodyType={proposal.bodyType}
            />
            {bestProposal?.thanksComment && (
              <div className="flex flex-col gap-3 rounded border p-3">
                <div className="text-sm font-bold">トピックオーナーからのコメント</div>
                <Body
                  body={bestProposal.thanksComment}
                />
              </div>
            )}
            <div className="flex justify-between">
              <div className="flex gap-1">
                <div className="flex items-center gap-1" onClick={onSupportersClick}>
                  {proposal.supported ? (
                    <HiStar className="text-yellow-500" size={20} />
                  ) : (
                    <HiOutlineStar className="text-black-500" size={20} />
                  )}
                  <div className="text-black-400">{proposal.supportsCount}</div>
                </div>
                {proposal.isPrioritized() && (
                  <div className="ml-1 flex items-center gap-1 text-gray-400">
                    <HiOutlineClock className="inline-block" size={20} />
                    <div>
                      残り
                      <PrioritizedRemainingTime
                        endAt={proposal.getPrioritizedEndAt()}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex items-center gap-3">
                <ClipButton
                  clippable={proposal}
                  clip={clip}
                  unclip={unclip}
                  isMutating={isClipMutating}
                />
                <div className="flex items-center gap-1">
                  <HiOutlineChatBubbleOvalLeft className="text-black-500" size={20} />
                  <div className="text-black-400">{proposal.commentsCount}</div>
                </div>
                <LikeButton likeable={proposal} like={like} unlike={unlike} isMutating={isLikeMutating} />
                <HiShare className="text-black-500" size={20} onClick={onShareClick} />
              </div>
            </div>
          </div>
          {proposal.isPrioritized() && (
            <PrioritizedProgressBar startAt={proposal.getPrioritizedStartAt()} endAt={proposal.getPrioritizedEndAt()} />
          )}
          <ProposalFooter proposal={proposal} />
        </div>
      </div>
      <ShareMenuModal
        open={shownShare}
        onClose={hideShare}
        url={process.env.NEXT_PUBLIC_WEB_ORIGIN + "/topics/" + proposal.topicId + "/proposals/" + proposal.id}
        title={proposal.bodyText.slice(0, 30) + "..."}
        hashtags={shareHashtags}
      />
    </div>
  );
};
