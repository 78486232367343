import Script from "next/script";

import { BFC } from "shared/types";

const DEFAULT_CLIENT = "ca-pub-6979357145762566";

type Props = {
  client?: string;
};

export const GoogleAdsenseScript: BFC<Props> = ({
  client = DEFAULT_CLIENT,
}) => {
  return (
    <Script
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`}
      strategy="afterInteractive"
      async
      crossOrigin="anonymous"
    />
  );
};
