import classNames from "classnames";
import { RiTwitterLine } from "react-icons/ri";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

export const Footer: BFC = ({
  className,
}) => {
  return (
    <div className={classNames("bg-black-900 text-white flex flex-col py-8 gap-4", className)}>
      <div className="text-center">
        その「声」には価値がある
      </div>
      <div className="text-black-200 flex place-content-center gap-5">
        <div className="place-center flex rounded-full border p-1">
          <a href={routes.SNS_TWITTER} target="_blank" rel="noreferrer">
            <RiTwitterLine size={20} />
          </a>
        </div>
      </div>
      <div className="flex justify-center gap-3">
        <a href="https://befool.co.jp" target="_blank" rel="noreferrer" className="text-gray-300">運営会社</a> |
        <Link href={routes.INQUIRIES_NEW} className="text-gray-300">お問い合わせ</Link> |
        <Link href={routes.DOCUMENTS_TERMS_OF_SERVICE} className="text-gray-300">利用規約</Link>
      </div>
      <div className="flex flex-wrap justify-center gap-3">
        <Link href={routes.DOCUMENTS_PRIVACY_POLICY} className="text-gray-300">プライバシーポリシー</Link> |
        <Link href={routes.DOCUMENTS_TOKUSHOHO} className="text-gray-300">特定商取引法に基づく表示</Link>
      </div>
      <div className="flex justify-center">
        <img
          src="/images/logo/logo_secondary.png"
          className="h-7 w-auto"
        />
      </div>
      <div className="text-center text-sm">
        © BEFOOL, Inc. All rights reserved.
      </div>
    </div>
  );
};
