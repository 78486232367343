"use client";
import { useRouter } from "next/navigation";
import {
  Fragment, useCallback, useEffect, useMemo, useRef, useState
} from "react";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { TConductorInstance } from "react-canvas-confetti/dist/types";
import { FcComments } from "react-icons/fc";
import { HiOutlineChatBubbleOvalLeft, HiMiniPencilSquare } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { Money, Position, ProposalType, TopicType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { CommentData, CommentForm, SupportData } from "~/features/topics/forms";

import {
  CommentListItem,
  SupportStarIcon,
  TopicDetailAccordion,
  OrderedPositionSelector,
  ProposalListItem,
  ProposalDetail,
} from "../../components";
import { useProposal, useTopic, useProposalComments, useSupportCheckoutServiceContext } from "../../hooks";

type Props = {
  topic: TopicType;
  proposal?: ProposalType;
};

export const ProposalsShowPage: BFC<Props> = ({
  topic: topicData,
  proposal: proposalData,
}) => {
  const router = useRouter();
  const { isSignedIn } = useAuthContext();
  const { topic, checkout, positions, notes, refetch: refetchTopic } = useTopic(topicData.id, topicData);
  const { proposal, threadProposals, isOwner, refetch } = useProposal(proposalData?.id, proposalData);
  const {
    comments,
    create,
    refetch: refetchComments,
    isLoading: isCommentLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useProposalComments(proposal.id);
  const { execute } = useSupportCheckoutServiceContext();
  const [commented, setCommented] = useState(false);
  const [supportedMoney, setSupportedMoney] = useState<Money | null>(null);
  const [fireworksConductor, setFireworksConductor] = useState<TConductorInstance>();

  const position = useMemo(() => {
    return proposal.getPosition();
  }, [proposal]);

  const onPositionSelect = useCallback((position?: Position) => {
    if (position?.id) {
      router.push(routes.TOPICS_POSITIONS_SHOW(topic.id, position.id));
    } else {
      router.push(routes.TOPICS_SHOW(topic.id));
    }
  }, [topic]);

  const onChartClick = useCallback(() => {
    router.push(routes.TOPICS_VOTES(topic.id));
  }, [topic, fireworksConductor]);

  const onCommentSubmit = useCallback(async (data: CommentData) => {
    if (data.support) {
      await execute(data as SupportData, () => {
        setCommented(true);
        setSupportedMoney(new Money(data.support));
        fireworksConductor?.shoot();
        refetch();
        refetchComments();
      });
    } else {
      await create(data);
      setCommented(true);
      refetchComments();
    }
  }, [create, execute, fireworksConductor, refetch]);

  const onCmmentBackClick = useCallback(() => {
    setCommented(false);
  }, []);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const onChangeVisibility = useCallback(() => {
    refetchTopic();
  }, [refetchTopic]);

  const onFireworksInit = useCallback(({ conductor }: { conductor: TConductorInstance }) => {
    setFireworksConductor(conductor);
  }, []);

  const topicRef = useRef<HTMLDivElement>(null);
  const proposalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topicRef.current && proposalRef.current && threadProposals.length > 1) {
      const topicRect = topicRef.current.getBoundingClientRect();
      const proposalRect = proposalRef.current.getBoundingClientRect();
      const top = proposalRect.top - topicRect.top - topicRect.height - 16;
      window.scrollTo({ top, behavior: "smooth" });
    }
  }, [proposal.id]);

  return (
    <div className="flex flex-col gap-4">
      <div ref={topicRef}>
        <TopicDetailAccordion
          topic={topic}
          notes={notes}
          checkout={checkout}
          onChangeVisibility={onChangeVisibility}
        />
      </div>
      <div className="flex flex-col">
        {threadProposals.map((p, index) => (
          <Fragment key={p.id}>
            {p.id !== proposal.id ? (
              <Link href={routes.TOPICS_PROPOSALS_SHOW(topic.id, p.id)}>
                <ProposalListItem
                  proposal={p}
                  className="mx-1 rounded border"
                />
              </Link>
            ) : (
              <div className="border-y" ref={proposalRef}>
                <ProposalDetail
                  topic={topic}
                  proposal={proposal}
                />
                <OrderedPositionSelector
                  topic={topic}
                  positions={positions}
                  selectedPosition={position}
                  onSelect={onPositionSelect}
                  onChartClick={onChartClick}
                  hideChart={positions.length === 0}
                />
              </div>
            )}
            {index < threadProposals.length - 1 && (
              <div className="mx-3 h-8 border-l-8 border-gray-300" />
            )}
          </Fragment>
        ))}
        {isSignedIn && isOwner && (
          <div>
            <div className="mx-3 h-4 border-l-8 border-gray-300" />
            <div className="px-1">
              <Button block large href={routes.TOPICS_PROPOSALS_NEW(topic.id, position?.id, proposal.id)}>発言を追加する</Button>
            </div>
          </div>
        )}
      </div>

      <div className="bg-white">
        <h3 className="flex gap-1 border-b p-4 font-bold">
          <HiMiniPencilSquare size={20} className="text-black-500" />
          コメントを投稿する
        </h3>
        <div className="p-4">
          {commented ? (
            <div className="flex flex-col items-center gap-4">
              <div className="flex items-center gap-3">
                <FcComments size={30} className="animate-bounce" />
                {supportedMoney && (
                  <SupportStarIcon amount={supportedMoney} size={30} className="animate-bounce" />
                )}
              </div>
              <div>コメント{supportedMoney && "・支援"}を投稿しました</div>
              <Button block onClick={onCmmentBackClick}>OK</Button>
            </div>
          ) : (
            <CommentForm onSubmit={onCommentSubmit} resetOnSuccess disabled={topic.isClosed()} />
          )}
        </div>
      </div>

      <div className="bg-white">
        <h3 className="flex gap-1 border-b p-4 font-bold">
          <HiOutlineChatBubbleOvalLeft size={20} className="text-black-500" />
          みんなのコメント
        </h3>
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="flex flex-col divide-y">
            {comments.map((comment) => (
              <CommentListItem key={comment.id} comment={comment} />
            ))}
          </div>
        </InfiniteScroll>
        {comments.length === 0 && !isFetchingNextPage && !isCommentLoading && (
          <div className="text-black-500 flex flex-col items-center justify-center py-8">
            コメントがまだありません
          </div>
        )}
        {(isCommentLoading || isFetchingNextPage) && (
          <div className="flex items-center justify-center">
            <ColorRing />
          </div>
        )}
      </div>
      <Fireworks onInit={onFireworksInit} />
    </div>
  );
};
