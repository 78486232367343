import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Topic, TopicType, Position, Checkout, TopicNote } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useTopic = (id: string, defaultData?: TopicType) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [topic, setTopic] = useState(new Topic(defaultData));
  const [positions, setPositions] = useState<Position[]>([]);
  const [notes, setNotes] = useState<TopicNote[]>([]);
  const [checkout, setCheckout] = useState<Checkout | null>(null);

  const { refetch, isLoading } = useQuery(["topics", id], () => api.getTopic(id), {
    onSuccess: ({ data }) => {
      setTopic(new Topic(data.topic));
      setPositions(data.positions.map((position) => new Position(position)));
      setNotes(data.notes.map((note) => new TopicNote(note)));
      if (data.checkout) {
        setCheckout(new Checkout(data.checkout));
      }
    },
  });

  return {
    topic,
    positions,
    notes,
    checkout,
    refetch,
    isLoading,
  };
};
