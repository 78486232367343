import classNames from "classnames";

import { Proposal } from "shared/models";
import { BFC } from "shared/types";

import { UserThumbnailImage, JobTitleBadge } from "~/features/users";

type Props = {
  proposal: Proposal;
  introductionClamp?: boolean;
};

export const ProposalFooter: BFC<Props> = ({
  proposal,
  introductionClamp = false,
  className,
}) => {
  const user = proposal.getUser();

  const introductionClassName = classNames({
    "line-clamp-1": introductionClamp,
  });

  return (
    <div className={classNames("flex flex-col gap-1 bg-gray-50 p-3", className)}>
      <div className="flex items-center gap-2">
        <UserThumbnailImage user={user} size={30} />
        <div className="text-black-500">
          {user.getNickname()}
        </div>
        {user.jobTitle && <JobTitleBadge title={user.jobTitle} />}
      </div>
      {user.introduction && (
        <div className={classNames("text-black-300 text-sm", introductionClassName)}>
          {user.introduction}
        </div>
      )}
    </div>
  );
};
