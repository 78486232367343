import { useQuery, useInfiniteQuery, QueryKey, UseQueryOptions, UseInfiniteQueryOptions } from "@tanstack/react-query";

import { strapiFetch } from "../helpers";
import { StrapiRequestParameters, StrapiModel, StrapiPaginationMeta, StrapiResponse } from "../types";

type StrapiModelFromResponse<T> = T extends StrapiResponse<infer U> ? U extends Array<infer V> ? V : U : never;

export const useStrapiQuery = <T extends StrapiResponse<StrapiModel | StrapiModel[]>>(
  keys: QueryKey,
  path: string,
  params?: StrapiRequestParameters<StrapiModelFromResponse<T>>,
  options?: UseQueryOptions<T>,
) => {
  return useQuery(
    keys,
    async () => {
      const res = await strapiFetch(path, {
        query: params,
      });
      return res.json() as Promise<T>;
    },
    options,
  );
};

export const useStrapiInfiniteQuery = <T extends StrapiResponse<StrapiModel | StrapiModel[], StrapiPaginationMeta>>(
  keys: QueryKey,
  path: string,
  params?: StrapiRequestParameters<StrapiModelFromResponse<T>>,
  options?: UseInfiniteQueryOptions<T>,
) => {
  return useInfiniteQuery(
    keys,
    async ({ pageParam = 1 }) => {
      const { pagination = {} } = params || {};

      const res = await strapiFetch(
        path,
        {
          query: {
            ...params,
            pagination: {
              ...pagination,
              page: pageParam,
            },
          },
        },
      );
      return res.json() as Promise<T>;
    },
    {
      getNextPageParam: ({ meta }) => {
        if (meta.pagination.page < meta.pagination.total) {
          return meta.pagination.page + 1;
        }
        return undefined;
      },
      ...options,
    },
  );
};
