import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { PiCrownFill } from "react-icons/pi";

import { NumberControlGroup, TextAreaControlGroup } from "shared/components";
import { formatCurrency } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { MoneyCurrency, Proposal } from "shared/models";
import { BFC } from "shared/types";

import { PositionBadge } from "~/features/topics/components/PositionBadge";
import { UserThumbnailImage } from "~/features/users";

type Props = {
  index: number;
  proposal: Proposal;
  rewardCurrency?: MoneyCurrency;
  onProposalClick?: (proposal: Proposal) => void;
  onBestProposalClick?: (proposal: Proposal) => void;
  onRewardAmountChange?: () => void;
};

export const BestProposalControlGroup: BFC<Props> = ({
  index,
  proposal,
  rewardCurrency,
  onProposalClick,
  onBestProposalClick,
  onRewardAmountChange,
  className,
}) => {
  const { watch } = useFormContext();
  const best = watch(`bestProposals.${index}.best`);

  const user = useMemo(() => proposal.getUser(), [proposal]);
  const position = useMemo(() => proposal.getPosition(), [proposal]);

  const onClick = useCallback(() => {
    onProposalClick?.(proposal);
  }, [onProposalClick, proposal]);

  const onBestClick = useCallback(() => {
    onBestProposalClick?.(proposal);
  }, [onBestProposalClick, proposal]);

  return (
    <div className={twClassNames("flex gap-3", className)}>
      <div className="flex w-10 flex-col items-center gap-1">
        {best ? (
          <>
            <PiCrownFill className="text-yellow-500" size={20} />
            <div className="text-xs font-bold">ベスト</div>
          </>
        ) : (
          <PiCrownFill className="text-gray-300" size={20} onClick={onBestClick} />
        )}
      </div>
      <div className="flex w-full flex-col gap-3">
        <div className="flex cursor-pointer justify-between gap-2" onClick={onClick}>
          <div className="flex items-center gap-1 text-sm">
            <UserThumbnailImage user={user} size={20} />
            <div className="truncate">{user.getNickname()}さんの発言</div>
          </div>
          {position && (
            <PositionBadge position={position} />
          )}
        </div>
        <div className="line-clamp-3 w-full cursor-pointer" onClick={onClick}>
          {proposal.bodyText}
        </div>
        <TextAreaControlGroup
          name={`bestProposals.${index}.thanksComment`}
          rows={2}
          placeholder="お礼のコメントをご入力ください"
        />
        {rewardCurrency && (
          <div className="flex w-full items-start justify-end gap-1">
            <NumberControlGroup
              name={`bestProposals.${index}.rewardAmount`}
              onChange={onRewardAmountChange}
              unit={formatCurrency(rewardCurrency)}
              inputClassName="w-32"
            />
          </div>
        )}
      </div>
    </div>
  );
};
