import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { z } from "zod";

import { Button, CheckboxControlGroup, InputGroup, } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

import { useStripeContext } from "~/features/checkouts";

const schema = z.object({
  paymentMethod: z.object({
    stripePaymentMethodId: z.string().min(1),
  }),
  isDefault: z.boolean().optional(),
});

export type PaymentMethodData = z.infer<typeof schema>;

type Props = {
  submitText?: string;
  showDefault?: boolean;
};

export const PaymentMethodForm = createHookForm<PaymentMethodData, Props>(({
  setValue,
  onSubmit,
  onError,
  handleSubmit,
  submissionError,
  formState: { isSubmitting },
  submitText = "登録する",
  showDefault,
}) => {
  const { stripe, elements } = useStripeContext();
  const onSubmitClick = useCallback(async () => {
    if (stripe && elements) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (cardNumberElement) {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
        });
        if (paymentMethod) {
          setValue("paymentMethod.stripePaymentMethodId", paymentMethod.id);
          handleSubmit(onSubmit, onError)();
        }
      }
    };
  }, [stripe, elements]);

  return (
    <div className="flex flex-col gap-4">
      <InputGroup
        label="カード番号"
        required
        error={submissionError ? "このカードは登録できません" : undefined}
        input={CardNumberInput}
      />
      <div className="flex gap-4">
        <InputGroup
          label="有効期限"
          required
          input={CardExpiryInput}
          className="flex-1"
        />
        <InputGroup
          label="セキュリティコード"
          required
          input={CardCvcInput}
          className="flex-1"
        />
      </div>
      {showDefault && <CheckboxControlGroup name="isDefault" inputLabel="いつも使用する支払い方法にする" />}

      <Button block primary large loading={isSubmitting} onClick={onSubmitClick}>
        {submitText}
      </Button>
    </div>
  );
}, {
  schema,
  defaultValues: {
    paymentMethod: {
      stripePaymentMethodId: "",
    },
    isDefault: false,
  },
});

const CardNumberInput = () => (
  <div className="rounded border p-3">
    <CardNumberElement options={{ showIcon: true, placeholder: "0000 0000 0000 0000" }} />
  </div>
);

const CardExpiryInput = () => (
  <div className="rounded border p-3">
    <CardExpiryElement />
  </div>
);

const CardCvcInput = () => (
  <div className="rounded border p-3">
    <CardCvcElement />
  </div>
);

