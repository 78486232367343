import { useCallback } from "react";

import { twClassNames } from "shared/lib/tailwind";
import { Category } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  categories: Category[];
  selectedCategory?: Category | null;
  onSelect?: (category: Category | null) => void;
};

export const CategorySelector: BFC<Props> = ({
  categories,
  selectedCategory,
  onSelect,
  className,
}) => {
  const onSelectFactory = useCallback((category: Category | null) => () => {
    if (onSelect) onSelect(category);
  }, [onSelect]);

  return (
    <div className={twClassNames(className)}>
      <div className="flex w-max">
        <CategoryItem
          label="すべて"
          selected={!selectedCategory}
          onClick={onSelectFactory(null)}
        />
        {categories.map((category) => (
          <CategoryItem
            key={category.id}
            label={category.name}
            onClick={onSelectFactory(category)}
            selected={category.id === selectedCategory?.id}
          />
        ))}
      </div>
    </div>
  );
};

type CategoryItemProp = {
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

const CategoryItem: BFC<CategoryItemProp> = ({
  label,
  selected,
  onClick,
  className,
}) => {
  const classes = twClassNames("px-4 py-3 font-bold", {
    "text-black-400": !selected,
    "border-b-2 border-primary text-primary": selected,
  }, className);

  return (
    <div className={classes} onClick={onClick}>
      {label}
    </div>
  );
};
