"use client";
import { useCallback } from "react";
import { HiPaperClip } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { CommentListItem } from "../../components";
import { useClippedComments } from "../../hooks";

export const ClippedCommentsIndexPage: BFC = () => {
  const { fetchNextPage, comments, hasNextPage, isLoading, isFetchingNextPage } = useClippedComments();

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <HiPaperClip size={24} />
          クリップ
        </h1>
        <div className="text-black-400 text-sm">
          クリップしたトピックに発言があると通知が届きます
        </div>
      </div>

      <div className="border-y bg-white">
        <div className="flex divide-x border-b">
          <Link className="px-6 py-4" href={routes.CLIPPED_TOPICS}>トピック</Link>
          <Link className="px-6 py-4" href={routes.CLIPPED_PROPOSALS}>発言</Link>
          <div className="border-b-primary !border-r border-b-2 px-6 py-4 font-bold">コメント</div>
        </div>
        <div className="divide-y">
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              {comments.map((comment) => (
                comment.isProposal() && (
                  <Link href={routes.TOPICS_PROPOSALS_SHOW(comment.proposal.topicId, comment.proposal.id)}>
                    <CommentListItem
                      comment={comment}
                    />
                  </Link>
                )
              ))}
            </div>
          </InfiniteScroll>
          {comments.length === 0 && !isFetchingNextPage && !isLoading && (
            <div className="text-black-500 flex flex-col items-center justify-center py-8">
              クリップしたコメントがありません
            </div>
          )}
          {(isLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
