import classNames from "classnames";

import { formatDate } from "shared/helpers";
import { MagazineArticle } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  article: MagazineArticle;
};

export const MagazineArticleCardWide: BFC<Props> = ({
  article,
  className,
}) => {
  const { cover, title, publishAt } = article;
  const categories = article.getCategories();

  return (
    <div className={classNames("bg-white flex gap-3", className)}>
      {cover && (
        <img
          src={cover.url}
          alt={title}
          className="aspect-square h-20 w-20 shrink-0 rounded border object-cover"
        />
      )}
      <div className="flex flex-1 flex-col gap-1">
        {categories.length > 0 && (
          <div className="flex gap-1">
            {categories.map((category) => (
              <span
                key={category.id}
                className="rounded bg-neutral-100 px-2 py-0.5 text-xs text-gray-500"
              >
                {category.name}
              </span>
            ))}
          </div>
        )}
        <h3 className="line-clamp-2 font-bold">{title}</h3>
        <div className="flex flex-1 items-end justify-end text-sm text-gray-400">
          {formatDate(publishAt)}
        </div>
      </div>
    </div>
  );
};
