"use client";
import { useCallback, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";
import { RoundButton } from "~/../../packages/shared/components";

import { User, UserType } from "shared/models";
import { BFC } from "shared/types";

import { useAuthContext } from "~/features/auth";
import { TopicCardList } from "~/features/topics";

import { UsersLayout } from "../../components";
import { useUserTopics } from "../../hooks";

type Props = {
  userData: UserType;
};

type Filter = "all" | "closed" | "waitingBestProposals";

export const UsersTopicsPage: BFC<Props> = ({
  userData,
}) => {
  const user = useMemo(() => new User(userData), [userData]);
  const { user: currentUser } = useAuthContext();
  const [filter, setFilter] = useState<Filter>("all");

  const params = useMemo(() => {
    switch (filter) {
      case "closed":
        return { closed: true };
      case "waitingBestProposals":
        return { waitingBestProposals: true };
      default:
        return {};
    }
  }, [filter]);

  const {
    topics,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useUserTopics(user.id, params);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const filterButtonAttrs = useCallback((f: Filter) => {
    return {
      selected: filter === f,
      onClick: () => setFilter(f),
    };
  }, [filter]);

  return (
    <UsersLayout user={user} selectedTab="topics">
      {currentUser.isMe(user) && (
        <div className="flex flex-wrap gap-3 border-t bg-white p-4">
          <RoundButton {...filterButtonAttrs("all")}>全て</RoundButton>
          <RoundButton {...filterButtonAttrs("waitingBestProposals")}>ベストアンサー待ち</RoundButton>
          <RoundButton {...filterButtonAttrs("closed")}>非公開</RoundButton>
        </div>
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <TopicCardList topics={topics} />
      </InfiniteScroll>
      {topics.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          投稿したトピックはありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center border-y bg-white p-4">
          <ColorRing />
        </div>
      )}
    </UsersLayout>
  );
};
