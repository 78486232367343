"use client";
import { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { BFC } from "shared/types";

import { useSearchLayout } from "~/components";

import { UserListItem } from "../../components";
import { useSearchUsers } from "../../hooks";

export const UsersSearchPage: BFC = () => {
  const { selectTab, keyword } = useSearchLayout();
  const { users, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useSearchUsers({ keyword });

  useEffect(() => {
    selectTab("users");
  }, [selectTab]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="pt-3">
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <div className="flex flex-col gap-3">
          {users.map((user) => (
            <UserListItem key={user.id} user={user} className="border-y" />
          ))}
        </div>
      </InfiniteScroll>
      {users.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          該当するユーザーがいません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center">
          <ColorRing />
        </div>
      )}
    </div>
  );
};
