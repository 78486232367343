import classNames from "classnames";
import { useMemo } from "react";
import { HiStar, HiOutlineStar } from "react-icons/hi2";

import { MoneyType } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  amount: MoneyType;
  size?: number;
};

const templates = [
  { amount: 0, icon: HiOutlineStar, className: "text-gray-400", color: "#9ca3af" },
  { amount: 100, icon: HiStar, className: "text-blue-500", color: "#3b82f6" },
  { amount: 200, icon: HiStar, className: "text-sky-300", color: "#7dd3fc" },
  { amount: 500, icon: HiStar, className: "text-lime-500", color: "#84cc16" },
  { amount: 1000, icon: HiStar, className: "text-yellow-500", color: "#eab308" },
  { amount: 2000, icon: HiStar, className: "text-orange-500", color: "#f97316" },
  { amount: 5000, icon: HiStar, className: "text-pink-400", color: "#f472b6" },
  { amount: 10000, icon: HiStar, className: "text-rose-600", color: "#e11d48" },
];

export const SupportStarIcon: BFC<Props> = ({
  amount,
  size = 20,
  className,
}) => {
  const template = useMemo(() => {
    const template = [...templates].reverse().find((c) => c.amount <= amount.amountCents);
    if (!template) return templates[0];
    return template;
  }, [amount]);

  return template.icon({ className: classNames(template.className, className), size });
};

export function getSupportStarColorClassName(amount: number): string {
  const template = [...templates].reverse().find((c) => c.amount <= amount);
  if (!template) return templates[0].className;
  return template.className;
}

export function getSupportStarColor(amount: number): string {
  const template = [...templates].reverse().find((c) => c.amount <= amount);
  if (!template) return templates[0].color;
  return template.color;
}
