import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useCancelTopicCheckout = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: cancel, isLoading: isCancelLoading } = useMutation(
    ["topics/checkout/cancel", id],
    () => api.cancelTopicCheckout(id),
  );

  return {
    cancel,
    isCancelLoading,
    isMutating: isCancelLoading,
  };
};
