"use client";
import classNames from "classnames";
import { useCallback, useMemo } from "react";

import { BFC } from "shared/types";

import { EditorJsBlock, HeaderBlock } from "~/lib/editorjs";

type Props = {
  blocks: EditorJsBlock[];
  onClick?: (id?: string) => void;
};

export const TableOfContents: BFC<Props> = ({
  blocks,
  onClick,
  className,
}) => {
  const headerBlocks = useMemo(() => {
    return blocks.filter((block): block is HeaderBlock => block.type === "header");
  }, [blocks]);

  const onClickFactory = useCallback((id?: string) => () => {
    onClick?.(id);
  }, [onClick]);

  if (headerBlocks.length === 0) {
    return null;
  }

  return (
    <div className={classNames("bg-gray-100 border-y", className)}>
      <div className="flex items-center border-b p-4">
        <div className="grow">目次</div>
      </div>
      <ul className="p-4">
        {headerBlocks.map((block) => {
          const classes = classNames("cursor-pointer", {
            "not-first:border-t py-2": block.data.level === 2,
            "py-2 pl-2": block.data.level === 3,
            "py-2 pl-4": block.data.level === 4,
            "py-2 pl-6": block.data.level === 5,
            "py-2 pl-8": block.data.level === 6,
          });

          return (
            <li className={classes} key={block.id} onClick={onClickFactory(block.id)}>
              {block.data.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
