import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  src: string;
  caption?: string;
  withBorder?: boolean;
  stretched?: boolean;
  className?: string;
};

export const ImageBlock: BFC<Props> = ({
  src,
  caption,
  withBorder,
  stretched,
  className,
}) => {
  const classes = classNames(className, {
    "border": withBorder,
    "w-full": stretched,
  });

  return (
    <div className="flex justify-center">
      <img src={src} className={classNames("rounded-lg mx-auto block max-w-full", classes)} alt={caption} />
    </div>
  );
};
