import classNames from "classnames";

import { formatTopicRewardType, TopicRewardType } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  type: TopicRewardType;
};

export const TopicRewardTypeBadge: BFC<Props> = ({
  type,
  className,
}) => {
  return (
    <div className={classNames("bg-black-300 text-white text-sm font-bold inline-block items-center rounded py-0.5 px-2", className)}>
      {formatTopicRewardType(type)}
    </div>
  );
};
