"use client";
import { createContext, useContext } from "react";

import { Checkout, ProposalType } from "shared/models";
import { CFC } from "shared/types";

import { useSupportCheckoutService } from "./useSupportCheckoutService";

type SupportCheckoutServiceContextType = ReturnType<typeof useSupportCheckoutService>;

const SupportCheckoutServiceContext = createContext<SupportCheckoutServiceContextType>({
  checkout: new Checkout(),
  support: null,
  paymentMethod: null,
  setPaymentMethod: () => void(0),
  execute: () => Promise.resolve(),
  calculate: () => Promise.resolve(),
  isFailed: false,
  isReady: false,
  isLoading: false,
  isMutating: false,
  isProcessing: false,
  isCalculateLoading: false,
  errorMessages: new Map,
});

export const useSupportCheckoutServiceContext = () => {
  return useContext(SupportCheckoutServiceContext);
};

type ProviderProps = {
  proposal: ProposalType;
};

export const SupportCheckoutServiceProvider: CFC<ProviderProps> = ({
  proposal,
  children,
}) => {
  const value = useSupportCheckoutService(proposal.id);

  return (
    <SupportCheckoutServiceContext.Provider value={value}>
      {children}
    </SupportCheckoutServiceContext.Provider>
  );
};
