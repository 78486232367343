import { useMutation } from "@tanstack/react-query";

import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useTopicVisibility = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });

  const { mutateAsync: close, isLoading: isClosing } = useMutation(
    ["topics/close", id],
    () => api.closeTopic(id),
  );

  const { mutateAsync: open, isLoading: isOpening } = useMutation(
    ["topics/open", id],
    () => api.openTopic(id),
  );

  return {
    close,
    open,
    isClosing,
    isOpening,
    isMutating: isClosing || isOpening,
  };
};
