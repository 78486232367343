import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { Comment } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { PostProposalCommentRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

export const useProposalComments = (id: string) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const { accessToken, getAccessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const guestableApi = useWebAPI({ accessToken: getAccessToken(true) });

  const { fetchNextPage, refetch, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["topics/proposals/comments", id],
    ({ pageParam }) => api.getProposalComments(id, pageParam),
    {
      enabled: !!id,
      getNextPageParam: (last) => last?.meta.paging.next,
      onSuccess: ({ pages }) => {
        setComments(pages.flatMap(({ data: { comments } }) => comments.map((c) => new Comment(c))));
      },
    },
  );

  const { mutateAsync: create, isLoading: isCreating } = useMutation(
    ["topics/proposals/comments/create", id],
    (params: PostProposalCommentRequest) => guestableApi.postProposalComment(id, params),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    comments,
    create,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    isCreating,
    isMutating: isCreating,
  };
};
