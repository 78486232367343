import classNames from "classnames";

import { BaseInputGroupProps, InputLabel, ErrorMessage } from "shared/components";
import { BFC } from "shared/types";

import { EditorJsInput } from "../EditorjsInput";

export type EditorJsInputGroupProps = Omit<BaseInputGroupProps<typeof EditorJsInput>, "input">;

export const EditorJsInputGroup: BFC<EditorJsInputGroupProps> = ({
  label,
  error,
  required,
  note,
  inputClassName,
  inputContainerClassName,
  labelContainerClassName,
  className,
  ...props
}) => {
  const classes = classNames(
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none",
    inputClassName,
  );

  return (
    <div className={classNames(className)}>
      {(label || required) && (
        <InputLabel label={label} required={required} className={classNames("mb-2", labelContainerClassName)} />
      )}
      <div className={inputContainerClassName}>
        <EditorJsInput {...props} className={classes} />
      </div>
      {error && <ErrorMessage message={error} hideIcon />}
      {note && (
        <div className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
};
