"use client";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useTransition } from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { HiOutlineUserCircle } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { SignUpForm, SignUpData } from "~/features/users/forms";
import { useSignUp } from "~/features/users/hooks";

type Props = {
  origin?: string;
};

export const SignUpPage: BFC<Props> = ({
  origin,
}) => {
  const router = useRouter();
  const { signUp, isSignUpLoading } = useSignUp();
  const [inPending, startTransition] = useTransition();

  const onSubmit = useCallback(async (data: SignUpData) => {
    await signUp(data);
    startTransition(() => {
      router.push(origin || routes.TOP);
    });
  }, [origin]);

  const onTwitterSignUp = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_TWITTER({ return_to: origin }));
  }, [origin]);

  const signInUrl = useMemo(() => {
    return routes.USERS_SIGN_IN(origin);
  }, [origin]);

  if (isSignUpLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between border-b bg-white px-4 py-6">
        <h1 className="flex items-center gap-1 text-xl font-bold">
          <HiOutlineUserCircle size={24} className="text-black-500" />
          会員登録
        </h1>
        <div>
          ログインは
          <Link href={signInUrl} decoration className="font-bold">こちら</Link>
        </div>
      </div>
      <div className="border-t bg-white p-4 shadow">
        <div className="mx-auto flex w-full max-w-2xl flex-col gap-4">
          <SignUpForm
            onSubmit={onSubmit}
            isPending={inPending}
          />
          <div className="border-t py-3">
            <Button
              block
              large
              className="flex items-center gap-2"
              onClick={onTwitterSignUp}
            >
              <FaSquareXTwitter size={20} />
              Xアカウントで登録
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
