"use client";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { HiOutlineUserCircle } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { CardBrandLogo } from "~/features/checkouts";
import { useBalances } from "~/features/revenues";

import { BalancesNote } from "./BalancesNote";
import { MypageMenuItem } from "../../components";
import { usePaymentMethods, useSignOut } from "../../hooks";

export const MyPage = () => {
  const router = useRouter();
  const { user, isLoading } = useAuthContext();
  const { defaultPaymentMethod } = usePaymentMethods();
  const { balances, isLoading: isBalancesLoading } = useBalances();
  const { signOut } = useSignOut();
  const connectedTwitter = user.getProviders().some((provider) => provider.isTwitter());

  const onSignOutHandler = useCallback(() => {
    signOut();
    router.push(routes.TOP);
  }, [signOut]);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ColorRing />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineUserCircle size={24} className="text-black-500" />
          マイページ
        </h1>
      </div>

      <div className="bg-white">
        <h2 className="border-b p-4 font-bold">会員情報</h2>
        <div className="flex flex-col divide-y">
          <MypageMenuItem
            label="プロフィール"
            note={user.nickname}
            href={routes.MYPAGE_PROFILE_EDIT}
          />
          <MypageMenuItem
            label="メールアドレス"
            note={user.email}
            href={routes.MYPAGE_EMAIL_EDIT}
          />
          <MypageMenuItem
            label="お支払い方法"
            note={defaultPaymentMethod ? (
              <div className="flex items-center gap-2">
                <CardBrandLogo brand={defaultPaymentMethod.card.brand} /> **** **** **** {defaultPaymentMethod.card.last4}
              </div>
            ) : "未登録"}
            href={routes.MYPAGE_PAYMENT_METHODS}
          />
          <MypageMenuItem
            label="収益状況"
            note={(
              <BalancesNote
                balances={balances}
                isLoading={isBalancesLoading}
                stripeAccountVerified={user.stripeAccountVerified}
              />
            )}
            href={routes.MYPAGE_REVENUES}
          />
          {/*
          <MypageMenuItem
            label="メールマガジンの受信設定"
            note={<div className="line-clamp-1">{user.emailMarketingAgreed ? "受信する" : "受信しない"}</div>}
            href={routes.MYPAGE_EMAIL_MARKETING_AGREEMENTS}
          />
          */}
          <MypageMenuItem
            label="パスワードの変更"
            href={routes.MYPAGE_PASSWORD_EDIT}
          />
          <MypageMenuItem
            label="退会する"
            href={routes.MYPAGE_DEACTIVATE}
          />
        </div>
      </div>

      <div className="bg-white">
        <h2 className="border-b p-4 font-bold">アカウント連携</h2>
        <div className="flex flex-col divide-y">
          <MypageMenuItem
            label={(<div className="flex gap-2"><FaSquareXTwitter size={20} />Xアカウント連携</div>)}
            note={connectedTwitter ? "連携済み" : "未連携"}
            href={routes.MYPAGE_PROVIDERS}
          />
        </div>
      </div>

      <div className="bg-white p-4">
        <Button large block onClick={onSignOutHandler}>ログアウト</Button>
      </div>
    </div>
  );
};
