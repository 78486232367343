"use client";
import classNames from "classnames";
import dynamic from "next/dynamic";
import { HiExclamationCircle } from "react-icons/hi2";

import { twClassNames } from "shared/lib/tailwind";
import { BFC } from "shared/types";

import { EditorJsData } from "~/lib/editorjs";

const Editor = dynamic(() => import("./Editor"), { ssr: false });

export type EditorJsChangeEvent = (data: EditorJsData) => void;

export type EditorJsInputProps = {
  invalid?: boolean;
  containerClassName?: string;
  placeholder?: string;
  value?: EditorJsData;
  className?: string;
  onChange?: EditorJsChangeEvent;
  onReady?: () => void;
};

export const EditorJsInput: BFC<EditorJsInputProps> = ({
  value,
  invalid,
  className,
  containerClassName,
  placeholder,
  onChange,
  onReady,
}) => {
  const classes = classNames(
    {
      "border-red-300 text-red-900 pr-10": invalid,
    },
  );

  return (
    <div className={twClassNames("relative", containerClassName)}>
      <Editor
        className={twClassNames(classes, className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onReady={onReady}
      />
      {invalid && (
        <div className="pointer-events-none absolute right-3 top-3 flex">
          <HiExclamationCircle className="h-5 w-5 text-red-500" />
        </div>
      )}
    </div>
  );
};
