import classNames from "classnames";
import { useState } from "react";
import { useCallback } from "react";
import { MouseEventHandler } from "react";
import { useEffect } from "react";
import { HiHeart } from "react-icons/hi2";
import { HiOutlineHeart } from "react-icons/hi2";

import { BFC } from "shared/types";

type Likeable = {
  likesCount: number;
  liked: boolean;
};

type Props = {
  likeable: Likeable;
  like: () => Promise<unknown>;
  unlike: () => Promise<unknown>;
  disabled?: boolean;
  isMutating: boolean;
};

export const LikeButton: BFC<Props> = ({
  likeable,
  like,
  unlike,
  disabled,
  isMutating,
}) => {
  const [liked, setLiked] = useState(likeable.liked);
  const [likesCount, setLikesCount] = useState(likeable.likesCount);

  const toggleLike = useCallback(async () => {
    if (disabled) return;

    if (liked) {
      await unlike();
      setLiked(false);
      setLikesCount(likesCount - 1);
    } else {
      await like();
      setLiked(true);
      setLikesCount(likesCount + 1);
    }
  }, [liked, likesCount]);

  const onLikeClick: MouseEventHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleLike();
  }, [toggleLike]);

  useEffect(() => {
    setLiked(likeable.liked);
    setLikesCount(likeable.likesCount);
  }, [likeable]);

  return (
    <button className="flex items-center gap-1 ring-0" onClick={onLikeClick} disabled={isMutating}>
      {liked || isMutating ? (
        <HiHeart
          size={20}
          className={classNames("transition", { "text-red-500": !isMutating, "text-black-300 animate-pulse": isMutating })}
        />
      ) : (
        <HiOutlineHeart size={20} className="text-black-500" />
      )}
      <div className="text-black-400">{likesCount}</div>
    </button>
  );
};
