import { useCallback, useEffect, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { HiClock, HiExclamationTriangle } from "react-icons/hi2";
import { PiCrownFill } from "react-icons/pi";
import { z } from "zod";

import { Button } from "shared/components";
import { formatDate, formatNumber, formatPriceWithCurrency } from "shared/helpers";
import { createHookForm } from "shared/lib/hook-form";
import { twClassNames } from "shared/lib/tailwind";
import { Proposal, Topic } from "shared/models";

import { BestProposalControlGroup } from "../../components";

const bestProposalSchema = z.object({
  proposalId: z.string(),
  best: z.boolean(),
  rewardAmount: z.number().min(100, "最低金額は100円からです").max(100000, "最大10万円までです").optional(),
  thanksComment: z.string().max(1000, "お礼のコメントが長過ぎます").optional(),
});

type BestProposalData = z.infer<typeof bestProposalSchema>;

const schema = z.object({
  bestProposals: z.array(bestProposalSchema).min(1, "ベストアンサーを選択してください").superRefine((bestProposals, ctx) => {
    const bestProposalIndex = bestProposals.findIndex((bestProposal) => bestProposal.best);
    const bestProposal = bestProposals[bestProposalIndex];
    if (bestProposal) {
      const moreExpensive = bestProposals.filter(
        (bp): bp is BestProposalData & { rewardAmount: number } => !!bp.rewardAmount
      ).some(
        (bp) => bp.rewardAmount > (bestProposal.rewardAmount || 0)
      );
      if (moreExpensive) {
        ctx.addIssue({
          path: [bestProposalIndex, "rewardAmount"],
          code: "custom",
          message: "ベストアンサーは最高金額の報酬を設定してください",
        });
      }
    }
  }),
});

export type CreateBestProposalsData = z.infer<typeof schema>;

const defaultValues = {
  bestProposals: [],
};

type Props = {
  topic: Topic;
  proposals?: Proposal[];
  onProposalClick?: (proposal: Proposal) => void;
};

export const CreateBestProposalsForm = createHookForm<CreateBestProposalsData, Props>(({
  formState: { isSubmitting, isValid, errors },
  control,
  watch,
  setValue,
  setError,
  clearErrors,
  trigger,
  topic,
  proposals = [],
  onProposalClick,
}) => {
  const { fields: bestProposalFields, append, remove } = useFieldArray({ control, name: "bestProposals" });
  const [bestProposalId, setBestProposalId] = useState("");
  const [sumRewardAmount, setSumRewardAmount] = useState(0);
  const rewardAmount = useMemo(() => topic.rewardAmount?.amount || 0, [topic]);

  const rewardAmountClassNames = useMemo(() => {
    if (sumRewardAmount === rewardAmount) {
      return "text-green-500";
    }
    if (sumRewardAmount > rewardAmount || sumRewardAmount === 0) {
      return "text-red-500";
    }
  }, [sumRewardAmount, rewardAmount]);

  const getProposal = useCallback((proposalId: string) => proposals.find((p) => p.id === proposalId), [proposals]);

  const onProposalClickHandler = useCallback((proposal: Proposal) => {
    onProposalClick?.(proposal);
  }, [onProposalClick]);

  const onBestClickHandler = useCallback((proposal: Proposal) => {
    setBestProposalId(proposal.id);
  }, []);

  const onRewardAmountChange = useCallback(() => {
    const bestProposals = watch("bestProposals");
    const sum = bestProposals.reduce((acc, { rewardAmount }) => acc + (rewardAmount || 0), 0);
    setSumRewardAmount(sum);
    if (sum > rewardAmount) {
      bestProposals.forEach((_, index) => {
        setError(`bestProposals.${index}.rewardAmount`, { type: "custom", message: "配布金額を超えています" });
      });
    } else {
      bestProposals.forEach((_, index) => {
        trigger(`bestProposals.${index}.rewardAmount`);
        clearErrors(`bestProposals.${index}.rewardAmount`);
      });
    }
  }, [rewardAmount]);

  useEffect(() => {
    const bestProposals = watch("bestProposals");
    const proposalsFields = proposals.map((proposal) => {
      const find = bestProposals.find((p) => p.proposalId === proposal.id);
      if (!find) {
        return {
          proposalId: proposal.id,
          best: false,
          rewardAmount: topic.hasReward() ? Math.max((topic.rewardAmount?.amount || 0) - sumRewardAmount, 0) : undefined,
        } as BestProposalData;
      } else {
        return null;
      }
    }).filter((v): v is BestProposalData => !!v);
    append(proposalsFields);

    bestProposals.forEach((bestProposal, index) => {
      if (!proposals.some((p) => p.id === bestProposal.proposalId)) {
        remove(index);
      }
    });

    setTimeout(() => {
      onRewardAmountChange();
    }, 100);
  }, [proposals]);

  useEffect(() => {
    const find = bestProposalFields.find((p) => p.proposalId === bestProposalId);
    const bestId = find?.proposalId || bestProposalFields[0]?.proposalId;
    setBestProposalId(bestId || "");
    bestProposalFields.forEach((bestProposal, index) => {
      setValue(`bestProposals.${index}.best`, bestId === bestProposal.proposalId);
      trigger(`bestProposals.${index}.rewardAmount`);
    });
  }, [bestProposalId, bestProposalFields]);

  return (
    <div>
      <h2 className="flex items-center justify-between border-b p-4">
        <div className="flex items-center gap-1 font-bold">
          <PiCrownFill size={20} />
          ベストアンサー
        </div>
        <div>
          <span className="font-bold">{proposals.length}</span> / {topic.rewardAnswersAmount}
        </div>
      </h2>
      <div className="flex flex-col divide-y">
        {bestProposalFields.map((field, index) => {
          const proposal = getProposal(field.proposalId);
          if (!proposal) return null;

          return (
            <BestProposalControlGroup
              key={field.id}
              index={index}
              proposal={proposal}
              rewardCurrency={topic.rewardAmount?.currency}
              onProposalClick={onProposalClickHandler}
              onBestProposalClick={onBestClickHandler}
              onRewardAmountChange={onRewardAmountChange}
              className="p-4"
            />
          );
        })}
        {proposals.length < topic.rewardAnswersAmount && (
          <div className="p-4">
            <div className="text-black-300 flex min-h-24 items-center justify-center rounded border bg-gray-100 p-3">
              対象の発言を選択してください
            </div>
          </div>
        )}
        {topic.hasReward() ? (
          <div className="flex flex-col gap-3 p-4">
            <div className="flex justify-center gap-1">
              <span className={twClassNames("text-xl font-bold", rewardAmountClassNames)}>
                {formatPriceWithCurrency(sumRewardAmount, topic.rewardAmount?.currency)}
              </span>
              /
              <span className="text-xl">{formatNumber(topic.rewardAmount?.amount || 0)}</span>
            </div>
            {sumRewardAmount < rewardAmount && sumRewardAmount > 0 ? (
              <div className="rounded border bg-red-100 p-3 text-red-600">
                <div className="flex justify-center gap-1">
                  <HiExclamationTriangle size={20} />
                  提示金額よりも、配布金額が少ないようです
                </div>
                <div className="mt-3 text-sm text-red-500">
                  このままでも配布可能ですが、差額は収益として返金されるため、受け取りには収益化設定が必要になります
                </div>
              </div>
            ) : topic.rewardExpiresAt && (
              <div className="rounded border bg-red-100 p-3 text-red-600">
                <div className="flex items-center justify-center gap-2">
                  <HiClock size={20} />
                  <div>
                    {formatDate(topic.rewardExpiresAt)}までに配布してください
                  </div>
                </div>
                <div className="mt-3 text-sm text-red-500">
                  期限までに報酬が配布されなかった場合、いいねの数に応じて自動的に配布されます。<br />
                  回答が一つもつかなかった場合は全額が返金されますが、受け取りには収益化設定が必要です
                </div>
              </div>
            )}
            <Button
              type="submit"
              primary
              block
              large
              disabled={!isValid || bestProposalFields.length === 0}
              loading={isSubmitting}
            >
              報酬を配布する
            </Button>
          </div>
        ) : (
          <div className="flex flex-col gap-3 p-4">
            <Button
              type="submit"
              primary
              block
              large
              disabled={!isValid || bestProposalFields.length === 0}
              loading={isSubmitting}
            >
              ベストアンサーを決定する
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}, {
  schema,
  defaultValues,
});
