"use client";
import { useCallback, useMemo, useState } from "react";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import { TConductorInstance } from "react-canvas-confetti/dist/types";
import { toast } from "react-hot-toast";
import { HiOutlineChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { PiCrown, PiCrownFill } from "react-icons/pi";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Button, SelectInput } from "shared/components";
import { Proposal, TopicType } from "shared/models";
import { BFC } from "shared/types";

import { SimpleModal } from "~/components";
import { CreateBestProposalsData, CreateBestProposalsForm } from "~/features/topics/forms";
import { UserThumbnailImage, JobTitleBadge } from "~/features/users";
import { CurrencyIcon } from "~/features/utils";
import { EditorJsData } from "~/lib/editorjs";

import { Body, TopicDetailAccordion, ProposalListItem, BestProposalListItem } from "../../components";
import { useTopic, useProposals, useBestProposals, UseProposalsParams, UseProposalsOrder } from "../../hooks";

type Props = {
  id: string;
  topic?: TopicType;
};

const ListMode = {
  LIKED: "liked",
  ALL: "all",
};
type ListMode = typeof ListMode[keyof typeof ListMode];

export const SelectBestProposalPage: BFC<Props> = ({
  id,
  topic: topicData,
}) => {
  const { topic, checkout, refetch } = useTopic(id, topicData);
  const { bestProposals, create, refetch: refetchBestProposals } = useBestProposals(topic.id);
  const [proposal, setProposal] = useState<Proposal | null>(null);
  const [selectedProposals, setSelectedProposals] = useState<Proposal[]>([]);
  const [fireworksConductor, setFireworksConductor] = useState<TConductorInstance>();

  const [listMode, setListMode] = useState<ListMode>(ListMode.LIKED);
  const listModeItems = useMemo(() => [
    { value: ListMode.LIKED, label: "いいねした発言" },
    { value: ListMode.ALL, label: "全ての発言" },
  ], []);
  const params: UseProposalsParams = useMemo(() => {
    if (listMode === ListMode.LIKED) {
      return { liked: true };
    } else {
      return { order: UseProposalsOrder.LikesCountDesc };
    }
  }, [listMode]);
  const { proposals, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useProposals(topic.id, params);

  const proposalUser = useMemo(() => proposal?.getUser(), [proposal]);
  const isSelected = useMemo(() => selectedProposals.some((p) => p.id === proposal?.id), [selectedProposals, proposal]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const onProposalClick = useCallback((proposal: Proposal) => {
    setProposal(proposal);
  }, []);

  const onProposalSelect = useCallback((proposal: Proposal) => () => {
    setSelectedProposals((prev) => {
      const find = prev.find((p) => p.id === proposal.id);
      if (!find) {
        return [...prev, proposal];
      } else {
        return prev.filter((p) => p.id !== proposal.id);
      }
    });
    setProposal(null);
  }, [topic]);

  const onCloseProposalModal = useCallback(() => {
    setProposal(null);
  }, []);

  const onListModeChange = useCallback((value: ListMode) => {
    setListMode(value);
  }, []);

  const onSubmit = useCallback(async (data: CreateBestProposalsData) => {
    await create(data);
    fireworksConductor?.shoot();
    refetch();
    refetchBestProposals();
    toast.success("ベストアンサーを選出しました");
    window.scrollTo(0, 0);
  }, [create, refetch, fireworksConductor]);

  const onFireworksInit = useCallback(({ conductor }: { conductor: TConductorInstance }) => {
    setFireworksConductor(conductor);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          {topic.hasReward() ? (
            <>
              <CurrencyIcon currency={topic.rewardAmount?.currency} size={24} />
              報酬を配布する
            </>
          ) : (
            <>
              <PiCrown size={24} />
              ベストアンサーを選ぶ
            </>
          )}
        </h1>
        <div className="text-black-400 text-sm">
          {topic.hasReward() ? (
            "ベスト回答に報酬を配布しましょう"
          ) : (
            "ベストアンサーを選びましょう"
          )}
        </div>
        <TopicDetailAccordion
          topic={topic}
          checkout={checkout}
          hideActions
          className="overflow-hidden rounded border"
        />
      </div>

      <div className="flex flex-col gap-4">
        <div className="border-y bg-white">
          {topic.isFixed() ? (
            <div>
              <h2 className="flex items-center justify-between border-b p-4">
                <div className="flex items-center gap-1 font-bold">
                  <PiCrownFill size={20} />
                  ベストアンサー
                </div>
                <div>
                  <span className="font-bold">{bestProposals.length}</span> / {topic.rewardAnswersAmount}
                </div>
              </h2>
              <div className="flex flex-col divide-y">
                {bestProposals.map((proposal) => (
                  <BestProposalListItem
                    key={proposal.id}
                    bestProposal={proposal}
                    onClick={onProposalClick}
                    className="p-4"
                  />
                ))}
              </div>
            </div>
          ) : (
            <CreateBestProposalsForm
              topic={topic}
              proposals={selectedProposals}
              onProposalClick={onProposalClick}
              onSubmit={onSubmit}
            />
          )}
        </div>

        <div className="border-y bg-white">
          <h2 className="flex items-center justify-between border-b px-4 py-2">
            <div className="flex items-center gap-1 font-bold">
              <HiOutlineChatBubbleOvalLeftEllipsis size={20} />
              発言一覧
            </div>
            <SelectInput
              items={listModeItems}
              onChangeValue={onListModeChange}
              defaultValue={ListMode.LIKED}
              className="text-sm"
            />
          </h2>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <div className="grid grid-cols-1 divide-y">
              {proposals.map((proposal) => (
                <ProposalListItem
                  key={proposal.id}
                  proposal={proposal}
                  showPosition
                  onClick={onProposalClick}
                />
              ))}
            </div>
          </InfiniteScroll>
          {proposals.length === 0 && !isFetchingNextPage && !isLoading && (
            <div className="text-black-500 flex flex-col items-center justify-center py-8">
              発言がありません
            </div>
          )}
          {(isLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
      </div>

      <SimpleModal open={!!proposal} onClose={onCloseProposalModal} className="h-2/3 max-h-full min-h-64">
        {proposal && proposalUser && (
          <div className="flex h-full flex-col">
            <div className="flex grow flex-col gap-4 overflow-y-scroll p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1 text-sm">
                  <UserThumbnailImage user={proposalUser} size={20} />
                  <div>{proposalUser.getNickname()}さんの発言</div>
                  {proposalUser.jobTitle && <JobTitleBadge title={proposalUser.jobTitle} />}
                </div>
              </div>
              <Body
                body={proposal.body}
                bodyData={proposal.bodyData as EditorJsData}
                bodyType={proposal.bodyType}
              />
            </div>
            <div className="border-t p-4">
              <Button large block primary onClick={onProposalSelect(proposal)} disabled={topic.isFixed()}>
                {isSelected ? "選択を解除する" : "この発言を選択する"}
              </Button>
            </div>
          </div>
        )}
      </SimpleModal>
      <Fireworks onInit={onFireworksInit} />
    </div>
  );
};
