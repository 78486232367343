import classNames from "classnames";
import { MouseEvent, useCallback, useMemo } from "react";
import { FcPieChart } from "react-icons/fc";

import { Position } from "shared/models";
import { BFC } from "shared/types";

import { PositionSelectorItem } from "../PositionSelector";

type Props = {
  positions: Position[];
  selectedPosition: Position;
  dontSelectedPosition?: boolean;
  hideChart?: boolean;
  onSelect?: (position?: Position) => void;
  onChartClick?: () => void;
};

export const GroupedPositionSelector: BFC<Props> = ({
  positions,
  selectedPosition,
  hideChart,
  onSelect,
  onChartClick,
  className,
}) => {
  const otherProposalsCount = useMemo(() => {
    return positions.reduce((acc, position) => acc + position.proposalsCount, 0) - selectedPosition.proposalsCount;
  }, [positions, selectedPosition]);

  const otherPosition = useMemo(() => {
    return new Position({
      name: "その他",
      proposalsCount: otherProposalsCount,
    });
  }, [otherProposalsCount]);

  const onChartClickHandler = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    if (onChartClick) {
      onChartClick();
    }
  }, [onChartClick]);

  return (
    <div className={classNames("relative", className)}>
      <div className="hidden-scrollbar w-full overflow-x-scroll bg-white pr-12">
        <div className="flex w-max">
          <PositionSelectorItem
            position={selectedPosition}
            selected
            proposalsCount={selectedPosition.proposalsCount}
            onClick={onSelect}
            className="border-r"
          />
          <PositionSelectorItem
            position={otherPosition}
            proposalsCount={otherProposalsCount}
            onClick={onSelect}
            className="border-r"
          />
        </div>
      </div>
      {!hideChart && (
        <div className="absolute inset-y-0 right-0 flex h-full items-center p-3">
          <FcPieChart size={28} className="rounded-full bg-gray-500/20" onClick={onChartClickHandler} />
        </div>
      )}
    </div>
  );
};
