import classNames from "classnames";

import { formatMoney } from "shared/helpers";
import { Support } from "shared/models";
import { BFC } from "shared/types";

import { SupportStarIcon } from "../SupportStarIcon";

type Props = {
  support: Support;
  size?: number;
};

export const SupportIcon: BFC<Props> = ({
  support,
  size = 20,
  className,
}) => {
  return (
    <div className={classNames("flex items-center gap-1", className)}>
      <SupportStarIcon amount={support.amount} size={size} />
      <div>
        {formatMoney(support.amount)}
      </div>
    </div>
  );
};
