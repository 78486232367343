"use client";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { Button } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { PasswordEditForm, PasswordEditData } from "../../forms";
import { useUpdatePassword } from "../../hooks";

export const PasswordEditPage: BFC = () => {
  const { update } = useUpdatePassword();
  const [isUpdatedPassword, setIsUpdatedPassword] = useState(false);
  const router = useRouter();

  const onSubmit = useCallback(async (data: PasswordEditData) => {
    await update(data, {
      onSuccess: () => setIsUpdatedPassword(true),
    });
  }, []);

  const onCancelClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onCancelClick} />
          パスワードの変更
        </h1>
      </div>
      <div className="bg-white p-3">
        {isUpdatedPassword ? (
          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-bold">パスワードの変更が完了しました</h2>
            <Button block large onClick={onCancelClick}>マイページに戻る</Button>
          </div>
        ) : (
          <PasswordEditForm
            onSubmit={onSubmit}
            onCancelClick={onCancelClick}
          />
        )}
      </div>
    </div>
  );
};
