"use client";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { BFC, isAPIError } from "shared/types";

import { useAuthContext } from "~/features/auth";

import { useEmailConfirmation } from "../../hooks/useEmailConfirmation";

type Props = {
  confirmationToken: string;
  returnTo?: string;
};

export const ConfirmationEmailPage: BFC<Props> = ({
  confirmationToken,
  returnTo = "/",
}) => {
  const router = useRouter();
  const { user: { emailConfirmed }, isLoading } = useAuthContext();
  const { confirmEmail, sendConfirmationMail, isConfirmEmailLoading } = useEmailConfirmation();
  const [failed, setFailed] = useState<boolean | null>(null);
  const [sent, setSent] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) return;

    if (confirmationToken) {
      confirmEmail({ confirmationToken }).then(() => {
        setFailed(false);
      }).catch((e) => {
        // conflictの場合は、すでに確認済み
        if (isAPIError(e) && e.response.status === 409) {
          setFailed(false);
        } else {
          setFailed(true);
        }
      });
    }
  }, [confirmationToken, isLoading]);

  useEffect(() => {
    if (emailConfirmed) {
      const timeoutId = setTimeout(() => {
        router.push(returnTo);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [emailConfirmed]);

  const handleResend = useCallback(() => {
    sendConfirmationMail({ returnTo }).then(() => {
      setSent(true);
    });
  }, [sendConfirmationMail, returnTo]);

  if (isConfirmEmailLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="bg-white p-3">
      {failed === false && emailConfirmed && (
        <div className="flex flex-col gap-4">
          <h1 className="text-center font-bold">メールアドレス認証ありがとうございます</h1>
          <div className="flex items-center justify-center text-5xl">
            🎉
          </div>
          <div>
            引き続きToinyをお楽しみください
          </div>
          <Link href={returnTo}>
            <Button primary block>戻る</Button>
          </Link>
        </div>
      )}
      {failed && !sent && (
        <div className="flex flex-col gap-4">
          <h1 className="text-center font-bold">メールアドレスの認証ができませんでした</h1>
          <div className="flex items-center justify-center text-5xl">
            😢
          </div>
          <div>
            お手数ですが、もう一度メールアドレスの認証をお願いします
          </div>
          <Button primary block onClick={handleResend}>再送信</Button>
        </div>
      )}
      {failed && sent && (
        <div className="flex flex-col gap-4">
          <h1 className="text-center font-bold">再送信しました</h1>
          <div className="flex items-center justify-center text-5xl">
            ✉️
          </div>
          <div>
            お手数ですが、もう一度メールアドレスの認証をお願いします
          </div>
        </div>
      )}
    </div>
  );
};
