"use client";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { createContext, useContext } from "react";

import { CFC } from "shared/types";

type StripeContextType = {
  stripe: Stripe | null,
  elements: StripeElements | null;
};

const StripeContext = createContext<StripeContextType>({
  stripe: null,
  elements: null,
});

export const useStripeContext = () => {
  return useContext(StripeContext);
};

export const StripeProvider: CFC = ({
  children,
}) => {
  const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY || "");

  return (
    <Elements stripe={stripePromise}>
      <StripeContextProvider>
        {children}
      </StripeContextProvider>
    </Elements>
  );
};

const StripeContextProvider: CFC = ({
  children,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  return (
    <StripeContext.Provider value={{ stripe, elements }}>
      {children}
    </StripeContext.Provider>
  );
};
