import classNames from "classnames";

import { BFC } from "shared/types";

import { EditorJsData } from "~/lib/editorjs";

import {
  CodeBlock,
  DelimiterBlock,
  EmbedBlock,
  HeadingBlock,
  ImageBlock,
  LinkBlock,
  ListBlock,
  ParagraphBlock,
  QuoteBlock,
  TableBlock,
  WarningBlock,
} from "./blocks";

type Props = {
  data?: EditorJsData;
};

export const EditorjsRenderer: BFC<Props> = ({
  data,
  className,
}) => {
  const blocks = data?.blocks || [];

  return (
    <div className={classNames("flex flex-col gap-4", className)}>
      {blocks.map((block) => {
        switch (block.type) {
          case "header":
            return (
              <HeadingBlock key={block.id} id={block.id} {...block.data} />
            );
          case "paragraph":
            return (
              <ParagraphBlock key={block.id} {...block.data} />
            );
          case "image":
            return (
              <ImageBlock key={block.id} {...block.data} src={block.data.file.url} />
            );
          case "linkTool":
            return (
              <LinkBlock key={block.id} {...block.data} />
            );
          case "list":
            return (
              <ListBlock key={block.id} {...block.data} />
            );
          case "code":
            return (
              <CodeBlock key={block.id} {...block.data} />
            );
          case "delimiter":
            return (
              <DelimiterBlock key={block.id} />
            );
          case "quote":
            return (
              <QuoteBlock key={block.id} {...block.data} />
            );
          case "table":
            return (
              <TableBlock key={block.id} {...block.data} />
            );
          case "embed":
            return (
              <EmbedBlock key={block.id} {...block.data} />
            );
          case "warning":
            return (
              <WarningBlock key={block.id} {...block.data} />
            );
          default:
            console.warn("unsupported block", block);
            return null;
        }
      })}
    </div>
  );
};
