import { EditorJsData, ListBlock } from "~/lib/editorjs/types";

function listItemToText(items: ListBlock["data"]["items"]): string {
  return items.map((item) => {
    if (typeof item === "string") {
      return item;
    } else {
      return [
        item.content,
        listItemToText(item.items),
      ].join("\n");
    }
  }).join("\n");
}

export function editorjsDataToText(data: EditorJsData): string {
  return data.blocks.map((block) => {
    switch (block.type) {
      case "header":
        return block.data.text;
      case "paragraph":
        return block.data.text.replace(/<[^>]+>/g, "");
      case "list":
        return listItemToText(block.data.items);
      case "linkTool":
        return block.data.link;
      case "quote":
        return block.data.text;
      case "code":
        return block.data.code;
      case "delimiter":
        return "-----";
      case "image":
        return block.data.caption;
      case "embed":
        return block.data.source;
      case "table":
        return block.data.content.map((row) => row.join("\t")).join("\n");
      default:
        return "";
    }
  }).join("\n");
}
