"use client";
import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode, Suspense } from "react";

import { useBooleanState } from "shared/hooks";
import { CFC } from "shared/types";

import { Footer } from "~/components/layouts/Footer";
import { Header } from "~/components/layouts/Header";
import { Sidebar } from "~/components/layouts/Sidebar";
import { GoogleAdsenseUnit } from "~/features/ads";

type Props = {
  headerContent?: ReactNode;
  headerClassName?: string;
};

export const StandardLayout: CFC<Props> = ({
  children,
  headerClassName,
}) => {
  const [sidebarOpen, openSidebar, hideSidebar] = useBooleanState();

  return (
    <div>
      <div className="sticky top-0 z-40 bg-white md:border-b">
        <Suspense fallback={null}>
          <Header
            className={classNames("md:max-w-6xl md:mx-auto", headerClassName)}
            onMenuClick={openSidebar}
          />
        </Suspense>
      </div>
      <div className="md:mx-auto md:max-w-6xl md:border-x">
        <div className="flex min-h-screen flex-col">
          <div className="flex flex-1">
            <div className="hidden shrink-0 bg-white md:block md:w-64 md:border-r">
              <Sidebar className="sticky top-12" />
            </div>
            <div className="max-w-full flex-1 bg-gray-100 md:max-w-[calc(100%-256px)]">
              {children}
              <GoogleAdsenseUnit
                slot="9893144148"
                className="my-4"
              />
            </div>
          </div>
        </div>
        { /* sidebar for mobile */ }
        <Transition show={sidebarOpen}>
          <Dialog onClose={hideSidebar} className="md:hidden">
            <div className="fixed inset-0 z-40 bg-gray-600/75" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-40 flex w-full max-w-xs flex-col overflow-y-scroll bg-gray-200">
              <Sidebar className="flex-1" onClose={hideSidebar} />
            </Dialog.Panel>
          </Dialog>
        </Transition>
        { /* sidebar for desktop */ }
      </div>
      <Footer />
    </div>
  );
};
