import { useRouter, usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo } from "react";
import { GoPin } from "react-icons/go";
import { z } from "zod";

import { Button, InputLabel, TextAreaControlGroup, TextControlGroup, ToggleControlGroup } from "shared/components";
import { formatCurrency, formatMinutesDuration } from "shared/helpers";
import { useBooleanState } from "shared/hooks";
import { createHookForm } from "shared/lib/hook-form";
import { Money, MoneyCurrency } from "shared/models";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { PaymentDetail, PaymentMethodsModal } from "~/features/checkouts";
import { SupportStarIcon } from "~/features/topics/components";
import { useSupportCheckoutServiceContext } from "~/features/topics/hooks";
import { PaymentMethodListItem } from "~/features/users";
import { AmountSlider } from "~/features/utils";

const schema = z.object({
  doSupport: z.boolean(),
  comment: z.object({
    body: z.string().min(1).max(1000),
    asAnonymous: z.boolean(),
  }),
  support: z.object({
    amount: z.number().min(100).max(100000),
    currency: z.nativeEnum(MoneyCurrency),
  }).optional(),
});

export type CommentData = z.infer<typeof schema>;
export type SupportData = Required<CommentData>;

type Props = {
  disabled?: boolean;
};

const defaultValues: CommentData = {
  doSupport: false,
  comment: {
    body: "",
    asAnonymous: false,
  },
};

export const CommentForm = createHookForm<CommentData, Props>(({
  formState: { isSubmitting },
  watch,
  setValue,
  disabled,
}) => {
  const { isSignedIn } = useAuthContext();
  const router = useRouter();
  const pathname = usePathname();
  const comment = watch("comment");
  const support = watch("support");
  const doSupport = watch("doSupport");
  const supportAmount = useMemo(() => new Money(support), [support?.amount, support?.currency]);
  const { amount = 0, currency = MoneyCurrency.Jpy } = support ?? {};
  const {
    checkout,
    support: csupport,
    paymentMethod,
    setPaymentMethod,
    isReady,
    isProcessing,
    calculate,
  } = useSupportCheckoutServiceContext();
  const [shownPaymentMethodsModal, showPaymentMethodsModal, hidePaymentMethodsModal] = useBooleanState(false);

  useEffect(() => {
    if (doSupport) {
      setValue("support.amount", 100);
      setValue("support.currency", MoneyCurrency.Jpy);
    } else {
      setValue("support", undefined);
    }
  }, [doSupport]);

  useEffect(() => {
    if (amount) {
      if (amount < 100) {
        setValue("support.amount", 100);
      } else if (amount > 100000) {
        setValue("support.amount", 100000);
      } else {
        setValue("support.amount", amount);
      }
    }
  }, [amount]);

  useEffect(() => {
    if (doSupport && support) {
      calculate({ support, comment });
    }
  }, [doSupport, support?.amount]);

  const amountValues = useMemo(() => {
    return [
      100,
      200,
      500,
      1000,
      2000,
      5000,
      10000,
    ];
  }, []);

  const onAmountSliderChange = useCallback((value: number) => {
    setValue("support.amount", value);
  }, []);

  const toggleDoSupport = useCallback(() => {
    if (!isSignedIn) {
      router.push(routes.USERS_SIGN_UP(pathname));
    } else {
      setValue("doSupport", !doSupport);
    }
  }, [doSupport, isSignedIn]);

  return (
    <div className="flex flex-col gap-4">
      <TextAreaControlGroup
        name="comment.body"
        label="本文"
        inputClassName="h-32"
        required
      />
      <div className="flex flex-col gap-3">
        <InputLabel label="支援する" />
        {isSignedIn ? (
          <div className="flex items-center justify-between rounded border">
            <div className="flex w-14 items-center justify-center border-r p-3" onClick={toggleDoSupport}>
              <SupportStarIcon amount={supportAmount} size={24} />
            </div>
            {doSupport ? (
              <div className="flex grow items-center justify-end gap-1">
                <div className="flex grow items-center gap-1 p-3">
                  <GoPin className="text-gray-500" size={20} />
                  {formatMinutesDuration(csupport?.getPrioritizedMinutes() ?? 0)}
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-lg">
                    {formatCurrency(currency)}
                  </span>
                  <TextControlGroup
                    name="support.amount"
                    className="w-24"
                    inputMode="numeric"
                    type="number"
                    min={100}
                    max={100000}
                    inputClassName="border-0 shadow-none text-right font-bold focus:ring-0"
                  />
                </div>
              </div>
            ) : (
              <div className="p-3 text-gray-400">星を投げてこの発言を応援しよう</div>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-between rounded border">
            <div className="flex w-14 items-center justify-center border-r p-3" onClick={toggleDoSupport}>
              <SupportStarIcon amount={supportAmount} size={24} />
            </div>
            <div className="p-3 text-gray-400">会員登録してこの発言を応援しよう</div>
          </div>
        )}
        {doSupport && (
          <div className="flex w-full justify-center p-3">
            <AmountSlider
              value={amount}
              values={amountValues}
              onChange={onAmountSliderChange}
              className="max-w-md"
            />
          </div>
        )}
      </div>
      {doSupport && (
        <>
          <div className="flex flex-col gap-3">
            <InputLabel label="お支払い方法" />
            {paymentMethod ? (
              <div className="flex items-center justify-between gap-3">
                <PaymentMethodListItem paymentMethod={paymentMethod} />
                <Button small onClick={showPaymentMethodsModal}>変更</Button>
              </div>
            ) : (
              <div className="flex items-center justify-between gap-3">
                <div className="text-gray-500">お支払い方法が登録されていません</div>
                <Button small onClick={showPaymentMethodsModal}>登録</Button>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-3">
            <InputLabel label="お支払い内容" />
            <PaymentDetail checkout={checkout} notRefundable />
          </div>
        </>
      )}
      {isSignedIn && (
        <ToggleControlGroup
          name="comment.asAnonymous"
          inputLabel="匿名で投稿する"
          position="right"
        />
      )}
      <Button
        type="submit"
        block primary large
        disabled={(doSupport && !isReady) || disabled}
        loading={isSubmitting || isProcessing}
      >
        利用規約に同意して投稿する
      </Button>
      <PaymentMethodsModal
        selectedPaymentMethod={paymentMethod}
        onSelected={setPaymentMethod}
        open={shownPaymentMethodsModal}
        onClose={hidePaymentMethodsModal}
      />
    </div>
  );
}, {
  schema,
  defaultValues,
});
