import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  text: string;
  caption?: string;
  alignment?: "left" | "center" | "right";
};

export const QuoteBlock: BFC<Props> = ({
  text,
  caption,
  className,
}) => {
  return (
    <div className={classNames("border rounded p-4 bg-gray-100 text-black-500", className)}>
      <div
        dangerouslySetInnerHTML={{ __html: text }}
      />
      {caption && (
        <div className="text-black-400 mt-3 text-sm underline">
          {caption}
        </div>
      )}
    </div>
  );
};
