import { useMemo } from "react";

import { BFC } from "shared/types";

type Props = {
  count: number;
};

export const NotificationBadge: BFC<Props> = ({
  count,
}) => {
  const value = useMemo(() => {
    if (count === 0) return null;
    if (count > 20) return "20+";
    return count;
  }, [count]);

  return (
    <div className="min-w-4 flex h-4 items-center justify-center rounded-full bg-red-500 px-1 text-xs font-bold text-white">
      {value}
    </div>
  );
};
