import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";
import { HiChevronRight } from "react-icons/hi";
import { HiXMark } from "react-icons/hi2";

import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { useCategories } from "~/features/categories";
import { useMagazineCategiories } from "~/features/magazines";

type MenuItem = {
  label: string;
  href: string;
};

type MenuItemGroup = {
  label: string;
  items: MenuItem[];
};

type Props = {
  onClose?: () => void;
};

export const Sidebar: BFC<Props> = ({
  onClose,
  className,
}) => {
  const { isSignedIn } = useAuthContext();
  const { rootCategories } = useCategories();
  const { categories: magazineCategories } = useMagazineCategiories();

  const menuItemGroups = useMemo(() => {
    const groups: MenuItemGroup[] = [];

    if (isSignedIn) {
      groups.push({
        label: "マイメニュー",
        items: [
          { label: "マイページ", href: routes.MYPAGE },
          { label: "クリップ", href: routes.CLIPPED_TOPICS },
        ],
      });
    } else {
      groups.push({
        label: "マイメニュー",
        items: [
          { label: "新規会員登録", href: routes.USERS_SIGN_UP() },
          { label: "ログイン", href: routes.USERS_SIGN_IN() },
        ],
      });
    }

    groups.push({
      label: "話題を探す",
      items: [{
        label: "すべて",
        href: routes.TOP,
      }].concat(rootCategories.map((c) => ({
        label: c.name,
        href: routes.CATEGORY_TOPICS(c.id),
      }))),
    });

    groups.push({
      label: "Toinyマガジン",
      items: [
        { label: "マガジンTOP", href: routes.MAGAZINE_TOP },
      ].concat(magazineCategories.map((category) => ({
        label: category.name,
        href: routes.MAGAZINE_CATEGORIES_SHOW(category.slug),
      }))),
    });

    return groups;
  }, [isSignedIn, rootCategories]);

  return (
    <div className={classNames("flex flex-col bg-white justify-between", className)}>
      <div>
        <div className="flex items-center justify-between gap-3 p-3 md:hidden">
          <div className="flex-1">
            <img
              src="/images/logo/logo_primary.png"
              className="h-6 w-auto"
            />
          </div>
          <button className="text-gray-600" onClick={onClose}>
            <HiXMark size={24} />
          </button>
        </div>
        <div className="flex flex-col overflow-y-auto border-b">
          {menuItemGroups.map((group) => (
            <div key={group.label}>
              <div className="bg-black-50 text-black-400 p-2 text-sm">
                {group.label}
              </div>
              <div className="text-black-600 flex flex-col divide-y">
                {group.items.map((item) => (
                  <Link key={item.href} href={item.href} className="flex items-center justify-between p-3" onClick={onClose}>
                    {item.label}
                    <HiChevronRight size={20} className="text-black-400" />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-10 flex flex-col gap-3 p-4 text-gray-500">
        <div>
          <a href={routes.LANDING_PAGE} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <Image src="/favicons/android-chrome-512x512.png" alt="Toiny" className="rounded-full" width={20} height={20} />
            Toinyとは
          </a>
        </div>
        {/*
        <div>
          <a href={routes.FAQ} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <HiOutlineQuestionMarkCircle size={20} className="text-gray-500" />
            よくあるご質問
          </a>
        </div>
        */}
      </div>
    </div>
  );
};
