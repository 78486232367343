import { useMemo } from "react";

import { formatMoney } from "shared/helpers";
import { Balance, MoneyCurrency  } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  balances: Balance[];
  stripeAccountVerified: boolean;
  isLoading: boolean;
};

export const BalancesNote: BFC<Props> = ({
  balances,
  stripeAccountVerified,
  isLoading,
}) => {
  const balancesByCurrency = balances.reduce((acc, balance) => {
    const currency = balance.amount.currency;
    if (!acc[currency]) {
      acc[currency] = balance;
    } else {
      acc[currency].amount.amount += balance.amount.amount;
      acc[currency].amount.amountCents += balance.amount.amountCents;
    }
    return acc;
  }, {} as Record<MoneyCurrency, Balance>);

  const firstLine = useMemo(() => {
    if (!stripeAccountVerified) {
      return "収益化未設定";
    }

    if (isLoading) {
      return "読み込み中...";
    }

    return Object.values(balancesByCurrency).map((balance) => {
      return formatMoney(balance.amount);
    }).join(", ");
  }, [balancesByCurrency, stripeAccountVerified]);

  return (
    <div>
      <div>{firstLine}</div>
    </div>
  );
};
