"use client";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { Button } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { EmailEditForm, EmailEditData } from "../../forms";
import { useUpdateEmail } from "../../hooks";

export const EmailEditPage: BFC = () => {
  const { email, update } = useUpdateEmail();
  const [isUpdatedEmail, setIsUpdatedEmail] = useState(false);
  const router = useRouter();

  const onSubmit = useCallback(async (data: EmailEditData) => {
    await update(data, {
      onSuccess: () => setIsUpdatedEmail(true),
    });
  }, []);

  const onCancelClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  const defaultValues = {
    user: { email },
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onCancelClick} />
          メールアドレスの設定
        </h1>
      </div>
      <div className="bg-white p-3">
        {isUpdatedEmail ? (
          <div className="flex flex-col gap-3">
            <h2 className="text-lg font-bold">メール送信完了</h2>
            <p>
              メールアドレス認証用のURLをご入力のメールアドレスに送信しました。<br />
              メールに記載されたURLをクリックして、メールアドレスの変更を完了してください。
            </p>
            <Button block large onClick={onCancelClick}>マイページに戻る</Button>
          </div>
        ) : (
          <EmailEditForm
            onSubmit={onSubmit}
            onCancelClick={onCancelClick}
            defaultValues={defaultValues}
          />
        )}
      </div>
    </div>
  );
};
