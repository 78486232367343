"use client";
import { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Category, CategoryType } from "shared/models";
import { BFC } from "shared/types";

import { GoogleAdsenseUnit } from "~/features/ads";
import { useCategorySelectorLayout } from "~/features/categories";

import { TopicCardList } from "../../components";
import { useTopics } from "../../hooks";

type Props = {
  category?: CategoryType | null;
};

export const TopicsIndexPage: BFC<Props> = ({
  category,
}) => {
  const { selectCategory } = useCategorySelectorLayout();
  const { fetchNextPage, topics, hasNextPage, isLoading, isFetchingNextPage } = useTopics({ category: category?.id });

  useEffect(() => {
    if (category) {
      selectCategory(new Category(category));
    }
  }, [category?.id]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex">
      <div className="flex-1">
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <TopicCardList topics={topics} className="md:px-3" />
        </InfiniteScroll>
        {topics.length === 0 && !isFetchingNextPage && !isLoading && (
          <div className="text-black-500 flex flex-col items-center justify-center py-8">
            該当する話題はありません
          </div>
        )}
        {(isLoading || isFetchingNextPage) && (
          <div className="flex items-center justify-center">
            <ColorRing />
          </div>
        )}
      </div>
      <div className="sticky hidden border-l border-t bg-white p-4 md:top-16 md:w-64">
        <GoogleAdsenseUnit
          slot="8492432568"
        />
      </div>
    </div>
  );
};
