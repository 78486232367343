import * as Slider from "@radix-ui/react-slider";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

import { BFC } from "shared/types";

type Props = {
  value: number;
  values: number[];
  onChange?: (value: number) => void;
};

export const AmountSlider: BFC<Props> = ({
  value,
  values,
  onChange,
  className,
}) => {
  const min = useMemo(() => 0, []);
  const max = useMemo(() => values.length - 1, [values]);
  const [sliderValue, setSliderValue] = useState([0]);

  const valueToSliderValue = useCallback((value: number) => {
    const v = [...values].reverse().findIndex((v) => v <= value);
    if (v === -1) return [0];
    return [max - v];
  }, [values, max]);

  const onValueChange = useCallback((value: number[]) => {
    setSliderValue(value);
    onChange?.(values[value[0]]);
  }, [onChange]);

  useEffect(() => {
    setSliderValue(valueToSliderValue(value));
  }, [value]);

  return (
    <Slider.Root
      value={sliderValue}
      onValueChange={onValueChange}
      min={min}
      max={max}
      className={classNames("relative flex h-5 w-full touch-none select-none items-center", className)}
    >
      <Slider.Track className="relative h-1 grow rounded-full bg-gray-300">
        <Slider.Range className="bg-primary absolute h-full rounded-full" />
      </Slider.Track>
      <Slider.Thumb
        className={classNames(
          "bg-primary block h-5 w-5 rounded-full",
          "focus:outline-none focus:shadow-[0_0_0_5px_rgba(0,0,0,0.2)]",
        )}
      />
    </Slider.Root>
  );
};
