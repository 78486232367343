"use client";
import { createContext, useContext } from "react";

import { Checkout } from "shared/models";
import { CFC } from "shared/types";

import { useTopicCheckoutService } from "./useTopicCheckoutService";

type TopicCheckoutServiceContextType = ReturnType<typeof useTopicCheckoutService>;

const TopicCheckoutServiceContext = createContext<TopicCheckoutServiceContextType>({
  checkout: new Checkout(),
  paymentMethod: null,
  setPaymentMethod: () => void(0),
  execute: () => Promise.resolve(),
  calculate: () => Promise.resolve(),
  isFailed: false,
  isReady: false,
  isLoading: false,
  isMutating: false,
  isProcessing: false,
  isCalculateLoading: false,
  errorMessages: new Map,
});

export const useTopicCheckoutServiceContext = () => {
  return useContext(TopicCheckoutServiceContext);
};

export const TopicCheckoutServiceProvider: CFC = ({
  children,
}) => {
  const value = useTopicCheckoutService();

  return (
    <TopicCheckoutServiceContext.Provider value={value}>
      {children}
    </TopicCheckoutServiceContext.Provider>
  );
};
