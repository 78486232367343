import qs from "qs";

import { EditorJsBlock } from "~/lib/editorjs";

const STRAPI_API_ORIGIN = process.env.STRAPI_API_ORIGIN || process.env.NEXT_PUBLIC_STRAPI_API_ORIGIN || "http://localhost:1337";

export function toStrapiImageURL(url: string) {
  if (url.startsWith("http")) {
    return url;
  }

  return `${process.env.NEXT_PUBLIC_STRAPI_API_ORIGIN}${url}`;
}

export function pickBodyText(blocks: EditorJsBlock[]) {
  const texts = blocks.map((block) => {
    switch (block.type) {
      case "paragraph":
        return block.data.text;
      case "header":
        return block.data.text;
      case "code":
        return block.data.code;
      case "quote":
        return block.data.text;
    }
  });
  return texts.join("\n");
}

type FetchOptions = RequestInit & {
  query?: Record<string, unknown>;
};

export function strapiFetch(
  path: string,
  options?: FetchOptions,
) {
  const { query, headers = {}, ...rest } = options || {};

  return fetch(
    `${STRAPI_API_ORIGIN}${path}?${qs.stringify(query)}`,
    {
      ...rest,
      headers: {
        ...headers,
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_TOKEN}`,
      },
    },
  );
}
