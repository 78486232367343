import classNames from "classnames";
import { useCallback, HTMLAttributes, useMemo } from "react";
import toast from "react-hot-toast";
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineClock,
  HiOutlineLockClosed,
  HiOutlineLockOpen,
  HiOutlineXCircle,
} from "react-icons/hi2";

import { Link } from "shared/components";
import { PopoverMenu } from "shared/features/utils";
import { formatDate } from "shared/helpers";
import { useBooleanToggleState } from "shared/hooks";
import { Checkout, Topic, TopicNote } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { CategoryBadge } from "~/features/categories";
import { Note } from "~/features/topics/components/Note";
import { UserThumbnailImage } from "~/features/users";
import { EditorJsData } from "~/lib/editorjs";

import { useCancelTopicCheckout, useTopicVisibility } from "../../hooks";
import { Body } from "../Body";
import { TopicActions } from "../TopicActions";
import { TopicRewardBadge } from "../TopicRewardBadge";
import { TopicRewardDetail } from "../TopicRewardDetail";

type Props = {
  topic: Topic;
  notes?: TopicNote[];
  checkout?: Checkout | null;
  onChangeVisibility?: () => void;
  hideActions?: boolean;
  hideMenu?: boolean;
};

export const TopicDetailAccordion: BFC<Props> = ({
  topic,
  notes = [],
  checkout,
  onChangeVisibility,
  hideActions,
  hideMenu,
  className,
}) => {
  const { user } = useAuthContext();
  const { close, open } = useTopicVisibility(topic.id);
  const { cancel } = useCancelTopicCheckout(topic.id);
  const categories = useMemo(() => topic.getCategories(), [topic]);
  const owner = useMemo(() => topic.getUser(), [topic]);
  const [opened, toggle] = useBooleanToggleState(false);

  const closeHandler = useCallback(async () => {
    await close();
    onChangeVisibility?.();
    toast.success("トピックを非公開にしました");
  }, [close]);

  const openHandler = useCallback(async () => {
    await open();
    onChangeVisibility?.();
    toast.success("トピックを公開しました");
  }, [open]);

  const cancelHandler = useCallback(async () => {
    await cancel();
    onChangeVisibility?.();
    toast.success("トピックの支払いをキャンセルしました");
  }, [cancel]);

  const onNoteDestroy = useCallback(() => {
    toast.success("補足を削除しました");
    onChangeVisibility?.();
  }, [onChangeVisibility]);

  const coverImage = useMemo(() => topic.coverImage?.webp ?? topic.coverImage, [topic.coverImage]);
  const coverAttributesFactory = useCallback((className: string): HTMLAttributes<HTMLDivElement> => {
    return {
      style: coverImage && opened ? { backgroundImage: `url("${coverImage.url}")` } : {},
      className: classNames("px-4 pt-4", className, {
        "h-40 bg-cover text-white border-b": coverImage?.url && opened,
      }),
    };
  }, [topic, coverImage, opened]);

  return (
    <div className={classNames("border-b bg-white", className)}>
      <div {...coverAttributesFactory("flex flex-col justify-between")}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {topic.isClosed() && (
              <div className="flex items-center gap-1 rounded-full bg-neutral-600 px-2 py-1 text-sm text-white">
                <HiOutlineLockClosed />非公開
              </div>
            )}
            {categories.length > 0 && (
              <div className="flex items-center gap-1">
                {categories.map((category) => (
                  <CategoryBadge key={category.id} category={category} />
                ))}
              </div>
            )}
            {categories.length === 0 && (
              <CategoryBadge label="未分類" />
            )}
            {topic.hasReward() && !opened && (
              <TopicRewardBadge type={topic.rewardType} amount={topic.getRewardAmount()} />
            )}
          </div>
          {!hideMenu && (
            <PopoverMenu>
              <PopoverMenu.Item
                onClick={closeHandler}
                hidden={topic.isClosed()}
                disabled={!user.isSignedIn() || !topic.isOwner}
                className="flex items-center gap-1"
              >
                <HiOutlineLockClosed size={18} />
                非公開にする
              </PopoverMenu.Item>
              <PopoverMenu.Item
                onClick={openHandler}
                hidden={topic.isOpen()}
                disabled={!user.isSignedIn() || !topic.isOwner || (!!checkout && !checkout.isCancellable())}
                className="flex items-center gap-1"
              >
                <HiOutlineLockOpen size={18} />
                公開する
              </PopoverMenu.Item>
              <PopoverMenu.Item
                onClick={cancelHandler}
                hidden={topic.isOpen() || !checkout}
                disabled={!user.isSignedIn() || !topic.isOwner || !checkout?.isCancellable()}
                className="flex items-center gap-1"
              >
                <HiOutlineXCircle size={18} />
                報酬をキャンセル
              </PopoverMenu.Item>
            </PopoverMenu>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <Link href={routes.TOPICS_SHOW(topic.id)} className="text-lg font-bold">
          {topic.title}
        </Link>

        {opened && (
          <>
            <Body
              body={topic.body}
              bodyData={topic.bodyData as EditorJsData}
              bodyType={topic.bodyType}
            />

            {notes.map((note, index) => (
              <Note
                key={note.id}
                topicId={topic.id}
                note={note}
                index={index}
                showMenu={topic.isOwner}
                onDestroy={onNoteDestroy}
                className="rounded border p-4"
              />
            ))}

            {topic.hasReward() && (
              <TopicRewardDetail topic={topic} checkout={checkout} />
            )}

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-1 text-sm">
                <UserThumbnailImage user={owner} size={20} />
                <div>{owner.nickname}</div>
              </div>
              {topic.publishedAt && (
                <div className="text-black-500 flex items-center gap-1 text-sm">
                  <HiOutlineClock size={18} />
                  {formatDate(topic.publishedAt)}
                </div>
              )}
            </div>
            {!hideActions && (
              <TopicActions
                topic={topic}
              />
            )}
          </>
        )}
      </div>
      <div className="flex justify-center p-4 pt-0" onClick={toggle}>
        {opened ? (
          <HiOutlineChevronUp />
        ) : (
          <HiOutlineChevronDown />
        )}
      </div>
    </div>
  );
};
