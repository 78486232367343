import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { TopicBestProposal } from "shared/models";
import { useWebAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

import { CreateBestProposalsData } from "../forms";

export const useBestProposals = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [bestProposals, setBestProposals] = useState<TopicBestProposal[]>([]);

  const { refetch, isLoading } = useQuery(
    ["topics/bestProposals", id],
    () => api.getBestProposals(id),
    {
      enabled: !!id,
      onSuccess: ({ data: { bestProposals } }) => {
        setBestProposals(bestProposals.map((p) => new TopicBestProposal(p)));
      },
    },
  );

  const { mutateAsync: create, isLoading: isCreating } = useMutation(
    ["topics/createBestProposals", id],
    (data: CreateBestProposalsData) => api.createBestProposals(id, data),
  );

  return {
    bestProposals,
    create,
    refetch,
    isLoading,
    isCreating,
    isMutating: isCreating,
  };
};
