import { MagazineArticle } from "shared/models";

import { StrapiChildModel, StrapiChildrenModel } from "./request";

export type StrapiArticle = {
  id: number;
  attributes: {
    body: string;
    title: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
    publishAt?: string;
    publishedAt: string;
    categories: StrapiChildrenModel<StrapiCategory>;
    tags: StrapiChildrenModel<StrapiTag>;
    cover?: {
      data: StrapiArticleImage;
    };
    thumbnail?: {
      data: StrapiArticleImage;
    };
    editor?: StrapiChildModel<StrapiEditor>;
  };
};

export type StrapiImageFileAttributes = {
  url: string;
  mime: string;
  size: string;
  height: number;
  width: number;
  alt: string;
};

export type StrapiArticleImage = {
  id: number;
  attributes: StrapiImageFileAttributes;
};

export type StrapiEditor = {
  id: number;
  attributes: {
    name: string;
    introduction: string;
    thumbnail?: {
      data: StrapiArticleImage;
    };
  };
};

export type StrapiCategory = {
  id: number;
  attributes: {
    name: string;
    slug: string;
  };
};

export type StrapiTag = {
  id: number;
  attributes: {
    name: string;
    slug: string;
  };
};

export type StrapiPickupArticle = {
  id: number;
  attributes: {
    article: StrapiChildModel<StrapiArticle>;
  };
};

export function convertStrapiArticleToMagazineArticle(data: StrapiArticle): MagazineArticle {
  const { id, attributes } = data;
  const { title, slug, body } = attributes;
  // const { id: imageId, attributes: imageAttrs } = article_image?.data || {};

  return new MagazineArticle({
    id,
    title,
    slug,
    body,
  });
}
