import { useCallback } from "react";

import { Link } from "shared/components";
import { twClassNames } from "shared/lib/tailwind";
import { User } from "shared/models";
import { CFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { UserProfileDetail } from "~/features/users/components";

export type UsersLayoutTabType = "proposals" | "topics" | "comments";

type Props = {
  user: User;
  selectedTab?: UsersLayoutTabType;
};

export const UsersLayout: CFC<Props> = ({
  user,
  selectedTab = "proposals",
  children,
}) => {
  const { user: currentUser } = useAuthContext();
  const tabAttributesFactory = useCallback((tab: UsersLayoutTabType, href: string, className?: string) => {
    return {
      href,
      className: twClassNames("p-4 px-5 cursor-pointer text-black-400 min-w-24 text-center", {
        "font-bold border-b-primary border-b-2 text-black-700": selectedTab === tab,
      }, className),
    };
  }, [selectedTab]);

  return (
    <div className="flex flex-col gap-4">
      <UserProfileDetail user={user} className="border-b" showEditButton={currentUser.isMe(user)} />

      <div>
        <div className="flex divide-x border-t bg-white">
          <Link {...tabAttributesFactory("proposals", routes.USERS_SHOW(user.handle))}>発言</Link>
          <Link {...tabAttributesFactory("topics", routes.USERS_TOPICS(user.handle))}>トピック</Link>
          <Link {...tabAttributesFactory("comments", routes.USERS_COMMENTS(user.handle), "!border-r")}>コメント</Link>
        </div>
        {children}
      </div>
    </div>
  );
};
