import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Proposal } from "shared/models";
import { useWebAPI } from "shared/services/api";
import { PostTopicProposalRequest, PublishProposalRequest, DeleteProposalRequest } from "shared/services/api/web";

import { useAuthContext } from "~/features/auth";

type UpdateProposalParams = PostTopicProposalRequest & {
  id: string;
};

export const useCreateProposal = (id: string) => {
  const { accessToken } = useAuthContext();
  const api = useWebAPI({ accessToken });
  const [drafts, setDrafts] = useState<Proposal[]>([]);

  const { isLoading, refetch } = useQuery(
    ["topics/proposals/draft", id],
    () => api.getTopicDraftProposals(id),
    {
      onSuccess: ({ data: { proposals } }) => {
        setDrafts(proposals.map((proposal) => new Proposal(proposal)));
      },
    },
  );

  const { mutateAsync: createProposal, isLoading: isCreating } = useMutation(
    ["topics/proposals/create", id],
    (params: PostTopicProposalRequest) => api.postTopicProposal(id, params),
  );

  const { mutateAsync: updateProposal, isLoading: isUpdating } = useMutation(
    ["topics/proposals/update", id],
    ({ id, ...params }: UpdateProposalParams) => api.putProposal(id, params),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { mutateAsync: deleteProposal, isLoading: isDeleting } = useMutation(
    ["topics/proposals/destroy", id],
    ({ id }: DeleteProposalRequest) => api.deleteProposal(id),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { mutateAsync: publishProposal, isLoading: isPublishing } = useMutation(
    ["topics/proposals/publish", id],
    ({ id }: PublishProposalRequest) => api.publishProposal(id),
  );

  return {
    drafts,
    createProposal,
    updateProposal,
    deleteProposal,
    publishProposal,
    isLoading,
    isCreating,
    isUpdating,
    isDeleting,
    isPublishing,
    isMutating: isCreating || isUpdating || isDeleting || isPublishing,
    isDraftLoading: isLoading,
  };
};
