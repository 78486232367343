import classNames from "classnames";
import { useCallback, useState } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi2";

import { formatDate } from "shared/helpers";
import { Topic } from "shared/models";
import { BFC } from "shared/types";

import { CategoryBadge } from "~/features/categories";
import { UserThumbnailImage } from "~/features/users";
import { EditorJsData } from "~/lib/editorjs";

import { Body } from "../Body";
import { TopicRewardBadge } from "../TopicRewardBadge";
import { TopicRewardTypeBadge } from "../TopicRewardTypeBadge";

type Props = {
  topic: Topic;
};

export const TopicSummary: BFC<Props> = ({
  topic,
  className,
}) => {
  const categories = topic.getCategories();
  const user = topic.getUser();
  const [open, setOpen] = useState(false);

  const onToggleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className={classNames("flex flex-col gap-4 bg-white p-4", className)}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          {categories.length > 0 && (
            <div className="flex items-center gap-1">
              {categories.map((category) => (
                <CategoryBadge key={category.id} category={category} />
              ))}
            </div>
          )}
          {categories.length === 0 && (
            <CategoryBadge label="未分類" />
          )}
          {topic.hasReward() && (
            <>
              <TopicRewardBadge type={topic.rewardType} amount={topic.getRewardAmount()} />
              <TopicRewardTypeBadge type={topic.rewardType} />
            </>
          )}
        </div>
      </div>
      <div className="text-lg font-bold">
        {topic.title}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1 text-sm">
          <UserThumbnailImage user={user} size={20} />
          <div>{user.nickname}</div>
        </div>
        {topic.expiresAt && (
          <div className="text-black-500 text-sm">
            {formatDate(topic.expiresAt)}まで
          </div>
        )}
      </div>
      {open && (
        <Body
          body={topic.body}
          bodyData={topic.bodyData as EditorJsData}
          bodyType={topic.bodyType}
        />
      )}
      {open ? (
        <div onClick={onToggleClick} className="mt-3 flex w-full items-center justify-center">
          <HiChevronUp size={24} />
        </div>
      ) : (
        <div onClick={onToggleClick} className="flex w-full items-center justify-center gap-3 rounded">
          <HiChevronDown size={24} />
        </div>
      )}
    </div>
  );
};
