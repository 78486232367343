import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import {
 createElement, FC, useCallback, useEffect, useState, ComponentType, BaseSyntheticEvent
} from "react";
import {
 useForm, FieldValues, FormProvider, UseFormProps, Path, UseFormReturn, DeepPartial, UseFormSetError
} from "react-hook-form";
import { ZodSchema } from "zod";

import { BFC, isAPIError } from "shared/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OnSubmitType<Data extends FieldValues> = (data: Data, event: BaseSyntheticEvent | undefined, apis: UseFormReturn<Data>) => any | Promise<any>;

type FormProps<Data extends FieldValues> = {
  onSubmit: OnSubmitType<Data>;
  onError?: (e: unknown, setError: UseFormSetError<Data>) => void;
  defaultValues?: DeepPartial<Data>;
  resetOnSuccess?: boolean;
};

type FormOptions<Data extends FieldValues> = {
  schema?: ZodSchema<Data>;
  as?: "div" | "tr" | ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
} & UseFormProps<Data>;

type ChildrenProps<Data extends FieldValues> = {
  submissionError?: string;
  onSubmit: (data: Data, event?: BaseSyntheticEvent) => Promise<unknown>;
  onError: (e: unknown) => void;
} & UseFormReturn<Data>;

// eslint-disable-next-line @typescript-eslint/ban-types
export function createHookForm<Data extends FieldValues, Props = {}>(
  children: FC<ChildrenProps<Data> & Props>,
  options?: FormOptions<Data>,
) {
  const {
    schema,
    defaultValues: originalDefaultValues,
    as = "div",
    className: containerClassName,
    ...formOptions
  } = options ?? {};

  const Form: BFC<FormProps<Data> & Props> = ({
    onSubmit,
    onError,
    defaultValues,
    resetOnSuccess = false,
    className,
    ...props
  }) => {
    const apis = useForm<Data>({
      ...formOptions,
      defaultValues: defaultValues ?? originalDefaultValues,
      resolver: schema ? zodResolver(schema) : undefined,
    });

    const { reset, formState, handleSubmit, setError } = apis;
    const { isSubmitSuccessful } = formState;
    const [submissionError, setSubmissionError] = useState<string>();

    const onErrorHandler = useCallback(async (e: unknown) => {
      if(isAPIError(e)) {
        const res = await e.response.clone().json();
        setSubmissionError(res.error.message);
        if (res.error.code === "invalid_record") {
          const { error: { validations } } = res;
          validations.forEach((validation) => {
            setError(
              `${validation.model}.${validation.key}` as Path<Data>,
              { type: "server", message: validation.message },
            );
          });
        }
      } else {
        setSubmissionError("エラーが発生しました");
      }

      if (onError) {
        onError(e, setError);
      }
    }, [onError]);

    const onSubmitHandler = useCallback(async (data: Data, event?: BaseSyntheticEvent) => {
      try {
        setSubmissionError(undefined);
        await onSubmit(data, event, apis);
      } catch (e) {
        await onErrorHandler(e);
      }
    }, [onSubmit, onErrorHandler]);

    useEffect(() => {
      reset(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
      if (resetOnSuccess && isSubmitSuccessful) {
        reset();
      }
    }, [reset, resetOnSuccess, isSubmitSuccessful]);

    return (
      <FormProvider {...apis}>
        {createElement(as, { className: classNames(className, containerClassName) }, (
          <form onSubmit={handleSubmit(onSubmitHandler, onErrorHandler)}>
            {children({
              ...apis,
              submissionError,
              onSubmit: onSubmitHandler,
              onError: onErrorHandler,
              ...props as Props,
            })}
          </form>
        ))}
      </FormProvider>
    );
  };

  return Form;
}
