"use client";
import { useRouter } from "next/navigation";
import { useCallback, useMemo, useTransition } from "react";
import { toast } from "react-hot-toast";
import { HiOutlinePencilAlt } from "react-icons/hi";

import { TopicBodyType, TopicRewardType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { CreateTopicData, CreateTopicForm } from "../../forms";
import { useCreateTopic, useTopicCheckoutServiceContext } from "../../hooks";

export const TopicsNewPage: BFC = () => {
  const router = useRouter();
  const { draft, saveDraft, clearDraft, createTopic } = useCreateTopic();
  const { execute, isFailed, errorMessages } = useTopicCheckoutServiceContext();
  const [isPending, startTransition] = useTransition();

  const onSubmit = useCallback(async (data: CreateTopicData) => {
    if (data.topic.rewardType !== TopicRewardType.None) {
      await execute(data, async (topic) => {
        clearDraft();
        toast.success("トピックを投稿しました");
        startTransition(() => {
          router.push(routes.TOPICS_SHOW(topic.id));
        });
      });
    } else {
      const { data: { topic } } = await createTopic(data);
      clearDraft();
      toast.success("トピックを投稿しました");
      startTransition(() => {
        router.push(routes.TOPICS_SHOW(topic.id));
      });
    }
  }, [createTopic, execute]);

  const onChange = useCallback((data: CreateTopicData) => {
    saveDraft(data);
  }, [saveDraft]);

  const defaultValues = useMemo(() => {
    return draft ? draft : { topic: { bodyType: TopicBodyType.Editorjs } };
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <HiOutlinePencilAlt size={24} />
          トピックを投稿する
        </h1>
        <div className="text-black-400 text-sm">
          みんなに相談したい事や、議論したい話題を投稿しよう！
        </div>
      </div>

      <div className="border-y bg-white p-4">
        <CreateTopicForm
          onSubmit={onSubmit}
          onChange={onChange}
          defaultValues={defaultValues}
          isPending={isPending}
          isFailed={isFailed}
          errorMessages={errorMessages}
        />
      </div>
    </div>
  );
};
