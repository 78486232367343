"use client";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { UserMeGenderType } from "shared/models";
import { BFC, isAPIError } from "shared/types";

import { routes } from "~/constants";

import { SignUpForm, SignUpData } from "../../forms";
import { SignConnectErrorType, useSignConnect, useSessionOmniauthValue } from "../../hooks";

enum SignConnectPageState {
  Loading,
  Connected,
  SignUp,
  Error,
}

type Props = {
  returnTo?: string;
};

export const SignConnectPage: BFC<Props> = ({
  returnTo,
}) => {
  const router = useRouter();
  const { connect, errorType } = useSignConnect();
  const [signConnectPageState, setSignConnectPageState] = useState<SignConnectPageState>(SignConnectPageState.Loading);
  const { provider, info } = useSessionOmniauthValue();

  // ページ読み込み時に一度 connect を試行する
  useEffect(() => {
    connect({}).then(() => {
      setSignConnectPageState(SignConnectPageState.Connected);
    }).catch((e) => {
      if (isAPIError(e) && e.response.status === 400) {
        setSignConnectPageState(SignConnectPageState.SignUp);
      } else {
        setSignConnectPageState(SignConnectPageState.Error);
      }
    });
  }, []);

  const handleSignUpFormSubmit = useCallback(async (data: SignUpData) => {
    return await connect(data).then(() => {
      setSignConnectPageState(SignConnectPageState.Connected);
    }).catch(() => {
      setSignConnectPageState(SignConnectPageState.Error);
    });
  }, [connect]);

  useEffect(() => {
    if (signConnectPageState === SignConnectPageState.Connected) {
      router.push(returnTo || routes.TOP);
    }
  }, [signConnectPageState, returnTo]);

  const onSignUpByTwitter = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_TWITTER({ return_to: returnTo }));
  }, [returnTo]);

  const signupDefaultValues = useMemo(() => {
    if (!info) return { user: {} };

    switch (provider) {
      case "twitter":
        return {
          user: {
            email: info.email,
            nickname: info.name,
            gender: UserMeGenderType.Unknown,
          },
        };
      default:
        return { user: {} };
    }
  }, [info, provider]);

  if (signConnectPageState === SignConnectPageState.Error) {
    return (
      <div className="flex flex-col gap-3 bg-white p-3 py-6 pb-10 shadow">
        {(errorType === SignConnectErrorType.NotFound || errorType === SignConnectErrorType.Unknown) && (
          <div className="flex flex-col gap-4">
            <h1 className="text-center font-bold">アカウントの接続に失敗しました</h1>
            <div className="flex items-center justify-center text-5xl">
              😢
            </div>
            <div>
              お手数ですが、もう一度操作をお願いします
            </div>
            <Button block large className="flex items-center gap-2" onClick={onSignUpByTwitter}>
              <FaSquareXTwitter size={20} />Xアカウントで登録
            </Button>
          </div>
        )}
        {errorType === SignConnectErrorType.AlreadyConnected && (
          <div className="flex flex-col gap-4">
            <h1 className="text-center font-bold">このアカウントは既に接続済みです</h1>
            <div className="flex items-center justify-center text-5xl">
              😢
            </div>
            <div>
              お手数ですが、もう一度操作をお願いします
            </div>
            <Button block large className="flex items-center gap-2" onClick={onSignUpByTwitter}>
              <FaSquareXTwitter size={20} />Xアカウントで登録
            </Button>
          </div>
        )}
      </div>
    );
  }

  if (signConnectPageState === SignConnectPageState.SignUp) {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between border-b bg-white px-4 py-6">
          <h1 className="flex items-center gap-1 text-xl font-bold">
            <HiOutlineUserCircle size={24} className="text-black-500" />
            会員登録
          </h1>
        </div>
        <div className="border-y bg-white p-4">
          <SignUpForm
            onSubmit={handleSignUpFormSubmit}
            defaultValues={signupDefaultValues}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <ColorRing />
    </div>
  );
};
