"use client";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { toast } from "react-hot-toast";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { ProfileData, ProfileForm } from "../../forms";
import { useUpdateProfile } from "../../hooks";

export const ProfileEditPage = () => {
  const router = useRouter();
  const { user, isLoading } = useAuthContext();
  const { update } = useUpdateProfile();

  const onSubmit = useCallback(async (data: ProfileData) => {
    await update(data);
    toast.success("プロフィールを更新しました");
    router.push(routes.MYPAGE);
  }, [update]);

  const onCancelClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ColorRing />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 px-4 py-6 text-lg font-bold">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onCancelClick} />
          プロフィール編集
        </h1>
      </div>

      <div className="bg-white p-4">
        <ProfileForm
          user={user}
          onSubmit={onSubmit}
          defaultValues={{
            user: {
              handle: user?.handle,
              nickname: user?.nickname,
              jobTitle: user?.jobTitle,
              introduction: user?.introduction,
            },
          }}
        />
        <Button block onClick={onCancelClick} className="mt-3">キャンセル</Button>
      </div>
    </div>
  );
};
