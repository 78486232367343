import { MouseEvent, useCallback } from "react";
import { HiShare } from "react-icons/hi";
import { HiOutlineChatBubbleOvalLeftEllipsis, HiOutlineStar, HiStar } from "react-icons/hi2";

import { useBooleanState } from "shared/hooks";
import { Topic } from "shared/models";
import { BFC } from "shared/types";

import { ClipButton, ShareMenuModal } from "~/features/utils";

import { useClipTopic } from "../../hooks";

type Props = {
  topic: Topic;
};

export const TopicActions: BFC<Props> = ({
  topic,
  className,
}) => {
  const { proposalsCount, supported, supportsCount } = topic;
  const { clip, unclip, isMutating } = useClipTopic(topic.id);
  const [shownShare, showShare, hideShare] = useBooleanState();

  const onShareClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    showShare();
  }, [showShare]);

  return (
    <div className={className}>
      <div className="flex justify-between">
        <div className="flex gap-1">
          <HiOutlineChatBubbleOvalLeftEllipsis className="text-black-500" size={20} />
          <div className="text-black-400">{proposalsCount}</div>
        </div>
        <div className="flex gap-1">
          <ClipButton
            clippable={topic}
            clip={clip}
            unclip={unclip}
            isMutating={isMutating}
          />
        </div>
        <div className="flex gap-1">
          {supported ? (
            <HiStar className="text-yellow-500" size={20} />
          ) : (
            <HiOutlineStar className="text-black-500" size={20} />
          )}
          <div className="text-black-400">{supportsCount}</div>
        </div>
        <div className="flex gap-1">
          <HiShare className="text-black-500" size={20} onClick={onShareClick} />
        </div>
      </div>
      <ShareMenuModal
        open={shownShare}
        onClose={hideShare}
        url={process.env.NEXT_PUBLIC_WEB_ORIGIN + "/topics/" + topic.id}
        title={topic.title}
      />
    </div>
  );
};
