import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { ReactNode, useCallback } from "react";

import { BFC } from "shared/types";

type Props = {
  slides: ReactNode[];
  containerClassName?: string;
  slideClassName?: string;
};

export const TopCarousel: BFC<Props> = ({
  slides,
  className,
  containerClassName,
  slideClassName,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollTo = useCallback((index: number) => () => {
    emblaApi?.scrollTo(index);
  }, [emblaApi]);

  return (
    <div ref={emblaRef} className={classNames("relative overflow-hidden", className)}>
      <div className={classNames("flex items-center h-full", containerClassName)}>
        {slides.map((slide, index) => (
          <div key={index} className={classNames("flex-[0_0_100%] min-w-0 h-full", slideClassName)}>
            {slide}
          </div>
        ))}
      </div>
      <div className="absolute inset-x-0 bottom-3 flex items-center justify-center">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            className="mx-1 h-2 w-2 rounded-full border bg-white"
            onClick={scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};
