import { Blob } from "@rails/activestorage";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { HiOutlineCamera } from "react-icons/hi2";
import { z } from "zod";
import { UserMe } from "~/../../packages/shared/models";

import { Button, LoadingOverlay, TextAreaControlGroup, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

import { useDirectUpload } from "~/features/utils";

export type UploadedFile = {
  file: File;
  blob: Blob;
};

const schema = z.object({
  user: z.object({
    handle: z.string().min(1).max(20).regex(/^[a-zA-Z0-9][a-zA-Z0-9_]*$/),
    nickname: z.string().min(1).max(20),
    jobTitle: z.string().optional(),
    introduction: z.string().optional(),
    thumbnailImage: z.string().optional(),
  }),
});

export type ProfileData = z.infer<typeof schema>;

type Props = {
  user: UserMe;
};

export const ProfileForm = createHookForm<ProfileData, Props>(({
  formState: { isSubmitting },
  getValues,
  setValue,
  user,
}) => {
  const { upload } = useDirectUpload();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const thumbnailImageUrl = useMemo(() => {
    if (uploadedFile) {
      return URL.createObjectURL(uploadedFile.file);
    } else {
      return user.thumbnailImage?.webp?.url;
    }
  }, [user, uploadedFile]);

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!acceptedFiles.length || isUploading) return;

      setIsUploading(true);
      await Promise.all(
        acceptedFiles.map(async (file) => {
          const blob = await upload(file);
          setUploadedFile({ file, blob });
          setValue("user.thumbnailImage", blob.signed_id);
        })
      ).finally(() => {
        setIsUploading(false);
      });
    },
    [getValues, setValue, upload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [],
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    onDropRejected: () => {
      toast.error("ファイルのアップロードに失敗しました");
    },
    disabled: isUploading || isSubmitting,
  });

  return (
    <div className="flex flex-col gap-4">
      <LoadingOverlay loading={isUploading}>
        <div className="">
          <div
            {...getRootProps()}
            className={classNames("relative mx-auto flex h-32 w-32 items-center justify-center rounded-full border overflow-hidden", {
              "bg-white": !isDragActive,
              "bg-gray-100 border-dashed border-primary": isDragActive,
              "bg-gray-100": isUploading,
            })}
          >
            <input {...getInputProps()} />
            {thumbnailImageUrl && (
              <img
                src={thumbnailImageUrl}
                className="h-full w-full object-cover"
              />
            )}
            {!isUploading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <HiOutlineCamera
                  size={32}
                  className="mx-auto text-gray-300"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      </LoadingOverlay>

      <TextControlGroup
        name="user.handle"
        label="ハンドルネーム"
        placeholder="toiny"
        required
        note="英数字とアンダースコア(_)のみ使用できます"
      />
      <TextControlGroup
        name="user.nickname"
        label="ニックネーム"
        placeholder="トイニー太郎"
        required
        note="20文字以内"
      />
      <TextControlGroup
        name="user.jobTitle"
        label="肩書き"
        placeholder="エンジニア、デザイナー、etc..."
        note="専門分野がわかりやすい肩書きがおすすめです"
      />
      <TextAreaControlGroup
        name="user.introduction"
        label="自己紹介"
        placeholder="こんにちは！"
        inputClassName="h-32"
      />
      <Button type="submit" block primary large loading={isSubmitting}>
        保存する
      </Button>
    </div>
  );
}, {
  schema,
});
