import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";

import { Notification, NotificationKindType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { UserThumbnailImage } from "~/features/users";

import { NotificationKindIcon } from "../NotificationKindIcon";

type Props = {
  notification: Notification;
};

export const NotificationListItem: BFC<Props> = ({
  notification,
}) => {
  const router = useRouter();

  const users = useMemo(() => notification.getUsers().slice(0, 10), [notification]);

  const userNames = useMemo(() => {
    if (users.length === 0) return "";
    if (users.length === 1) return `${users[0].getNickname()}さん`;
    return `${users[0].getNickname()}さん他${notification.usersCount - 1}人`;
  }, [users, notification]);

  const notifiableName = useMemo(() => {
    if (notification.kind === NotificationKindType.Like) {
      switch (notification.notifiableType) {
        case "Proposal":
          return "発言";
        case "Comment":
          return "コメント";
      }
    } else if (notification.kind === NotificationKindType.Comment) {
      return "発言";
    } else if (notification.kind === NotificationKindType.Support) {
      return "発言";
    } else if (notification.kind === NotificationKindType.Proposal) {
      return "トピック";
    }
  }, [notification]);

  const notifiableText = useMemo(() => {
    switch (notification.notifiableFactor) {
      case "owner":
        return `あなたが投稿した${notifiableName}`;
      case "clipped":
        return `あなたがクリップした${notifiableName}`;
    }
  }, [notification, notifiableName]);

  const notifiableBody = useMemo(() => {
    switch (notification.notifiableType) {
      case "Proposal":
        return notification.proposal?.bodyText;
      case "Comment":
        return notification.comment?.body;
    }
  }, [notification]);

  const notifiableUrl = useMemo(() => {
    switch (notification.notifiableType) {
      case "Proposal":
        if (notification.proposal) {
          return routes.TOPICS_PROPOSALS_SHOW(notification.proposal.topicId, notification.proposal.id);
        }
    }
  }, [notification]);

  const message = useMemo(() => {
    if (notification.message) return notification.message;

    switch (notification.kind) {
      case "like":
        return <><span className="font-bold">{userNames}</span>が{notifiableText}にいいねしました</>;
      case "comment":
        return <><span className="font-bold">{userNames}</span>が{notifiableText}にコメントしました</>;
      case "support":
        return <><span className="font-bold">{userNames}</span>から{notifiableText}に支援いただきました</>;
      case "proposal":
        return <><span className="font-bold">{userNames}</span>が{notifiableText}に発言しました</>;
    }
  }, [notification, userNames, notifiableText]);

  const onClick = useCallback(() => {
    if (notification.url) {
      router.push(notification.url);
    } else if (notifiableUrl) {
      router.push(notifiableUrl);
    }
  }, [notifiableUrl, notification]);

  return (
    <div className="flex gap-3 p-4" onClick={onClick}>
      <NotificationKindIcon kind={notification.kind} className="shrink-0" />
      <div className="flex flex-col gap-1">
        {users.length > 0 && (
          <div className="flex gap-1">
            {users.map((user) => (
              <UserThumbnailImage key={user.id} user={user} size={24} />
            ))}
          </div>
        )}
        {message && <div className="">{message}</div>}
        {notifiableBody && <div className="line-clamp-3 text-gray-500">{notifiableBody}</div>}
      </div>
    </div>
  );
};
