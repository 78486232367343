"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { createContext, useCallback, useContext, useState } from "react";

import { CFC } from "shared/types";

import { StandardLayout } from "~/components";
import { routes } from "~/constants";

import { Tab } from "./Tab";

type TabType = "topics" | "proposals" | "users";

type ContextType = {
  selectedTab: TabType;
  selectTab: (tab: TabType) => void;
  setKeyword: (keyword: string) => void;
  keyword: string;
  inSearchPage: boolean;
};

const SearchLayoutContext = createContext<ContextType>({
  selectedTab: "topics",
  selectTab: () => void(0),
  setKeyword: () => void(0),
  keyword: "",
  inSearchPage: false,
});

export const useSearchLayout = () => useContext(SearchLayoutContext);

export const SearchLayout: CFC = ({
  children,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [keyword, setKeyword] = useState(searchParams?.get("keyword") ?? "");
  const [selectedTab, setSelectedTab] = useState<TabType>("topics");

  const onTabSelect = useCallback((tab: TabType) => () => {
    switch (tab) {
      case "topics":
        router.push(routes.SEARCH_TOPICS(keyword));
        break;
      case "proposals":
        router.push(routes.SEARCH_PROPOSALS(keyword));
        break;
      case "users":
        router.push(routes.SEARCH_USERS(keyword));
        break;
    }
  }, [keyword, router]);

  const selectTab = useCallback((tab: TabType) => {
    setSelectedTab(tab);
  }, []);

  return (
    <SearchLayoutContext.Provider value={{ selectedTab, selectTab, setKeyword, keyword, inSearchPage: true }}>
      <StandardLayout>
        <div className="sticky top-12 z-10 w-screen border-b bg-white md:w-full">
          <div
            className="hidden-scrollbar overflow-x-auto"
          >
            <div className="flex w-max">
              <Tab selected={selectedTab == "topics"} onSelect={onTabSelect("topics")}>トピック</Tab>
              <Tab selected={selectedTab == "proposals"} onSelect={onTabSelect("proposals")}>発言</Tab>
              <Tab selected={selectedTab == "users"} onSelect={onTabSelect("users")}>ユーザー</Tab>
            </div>
          </div>
        </div>
        {children}
      </StandardLayout>
    </SearchLayoutContext.Provider>
  );
};
