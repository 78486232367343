"use client";
import { ArcElement, ChartData, Tooltip, Legend, Chart } from "chart.js";
import { useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Pie } from "react-chartjs-2";
import { HiOutlineChartPie, HiOutlineChatBubbleOvalLeft } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { Position, TopicType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { PositionSelector, TopicDetail, VoteListItem, VoteResult } from "../../components";
import { VoteForm, VoteData } from "../../forms";
import { useTopic, useVotes } from "../../hooks";

Chart.register(ArcElement, Tooltip, Legend);

type Props = {
  topic: TopicType;
};

export const VotesIndexPage: BFC<Props> = ({
  topic: topicData,
}) => {
  const router = useRouter();
  const { topic, positions, refetch } = useTopic(topicData.id, topicData);
  const { votes, voted, vote, fetchNextPage, hasNextPage, isLoading: isVotesLoading, isFetchingNextPage } = useVotes(topic.id);
  const [showResults, setShowResults] = useState(false);

  const onPositionSelect = useCallback((position?: Position) => {
    if (position) {
      router.push(routes.TOPICS_POSITIONS_SHOW(topic.id, position.id));
    } else {
      router.push(routes.TOPICS_SHOW(topic.id));
    }
  }, [topic]);

  const onShowResultsClick = useCallback(() => {
    setShowResults(true);
  }, []);

  const onHideResultsClick = useCallback(() => {
    setShowResults(false);
  }, []);

  const onVoteSubmit = useCallback(async (data: VoteData) => {
    await vote(data);
    await refetch();
  }, [vote]);

  const sortedPositions = useMemo(() => {
    return [...positions].sort((a, b) => b.votesCount - a.votesCount);
  }, [positions]);

  const hasVotes = useMemo(() => {
    return positions.some((position) => position.votesCount > 0);
  }, [positions]);

  const data = useMemo((): ChartData<"pie"> => {
    return {
      labels: sortedPositions.map((position) => position.name),
      datasets: [
        {
          label: topic.title,
          data: sortedPositions.map((position) => hasVotes ? position.votesAmount : 1),
          backgroundColor: [
            "#C2CFA1",
            "#9AB5A3",
            "#608696",
            "#626580",
            "#D17780",
            "#F6A07B",
          ],
        },
      ],
    };
  }, [sortedPositions, hasVotes]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (voted) {
      setShowResults(true);
    }
  }, [voted]);

  return (
    <div className="flex flex-col gap-4">
      <TopicDetail topic={topic} />

      <div className="border-y bg-white">
        <h2 className="flex items-center justify-between border-b p-3">
          <div className="flex items-center gap-1 font-bold">
            <HiOutlineChartPie size={20} />
            みんなの投票
          </div>
        </h2>
        <PositionSelector
          positions={positions}
          totalProposalsCount={topic.proposalsCount}
          hideChart
          dontSelectedPosition
          onSelect={onPositionSelect}
          className="border-b"
        />

        {showResults ? (
          <div className="p-4">
            <div className="w-full">
              <Pie
                data={data}
                options={{
                  plugins: {
                    legend: {
                      position: "left",
                    },
                  },
                }}
              />
            </div>
            <VoteResult positions={positions} voted={voted} />
            {!voted && (
              <Button block onClick={onHideResultsClick} className="mt-3">投票に戻る</Button>
            )}
          </div>
        ) : (
          <div className="p-4">
            {isVotesLoading ? (
              <div className="flex items-center justify-center">
                <ColorRing />
              </div>
            ) : (
              <>
                <VoteForm
                  positions={positions}
                  onSubmit={onVoteSubmit}
                />
                <Button block onClick={onShowResultsClick} className="mt-3">結果だけ見る</Button>
              </>
            )}
          </div>
        )}
      </div>

      {showResults && (
        <div className="border-y bg-white">
          <h3 className="flex gap-1 border-b p-4 font-bold">
            <HiOutlineChatBubbleOvalLeft size={20} className="text-black-500" />
            みんなのコメント
          </h3>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <div className="flex flex-col divide-y">
              {votes.map((vote) => (
                <VoteListItem key={vote.id} vote={vote} />
              ))}
            </div>
          </InfiniteScroll>
          {votes.length === 0 && !isFetchingNextPage && !isVotesLoading && (
            <div className="text-black-500 flex flex-col items-center justify-center py-8">
              投票にコメントがまだありません
            </div>
          )}
          {(isVotesLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
