"use client";
import { useMemo } from "react";
import { ColorRing } from "react-loader-spinner";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  HatenaShareButton,
  HatenaIcon,
} from "react-share";

import { MagazineArticleType } from "shared/models";
import { BFC } from "shared/types";

import { MagazineArticle } from "../../components";
import { useMagazineArticle } from "../../hooks";

type Props = {
  slug: string;
  article: Partial<MagazineArticleType>;
};

export const MagazineArticlesShowPage: BFC<Props> = ({
  slug,
  article: initialArticle,
}) => {
  const { article, isLoading } = useMagazineArticle(slug, initialArticle);

  const url = useMemo(() => {
    return process.env.NEXT_PUBLIC_WEB_ORIGIN + "/magazine/articles/" + slug;
  }, [slug]);

  if (isLoading && !article) {
    return <div className="flex h-full items-center justify-center"><ColorRing /></div>;
  }

  return (
    <div>
      <div>
        <MagazineArticle article={article} />
      </div>
      <div className="mt-4 bg-white p-4">
        <div className="text-center font-bold">
          シェアする
        </div>
        <div className="mt-4 flex justify-center gap-8">
          <FacebookShareButton
            url={url}
            className="flex w-16 flex-col items-center justify-center gap-1"
          >
            <FacebookIcon size={32} round />
            <div className="text-sm text-gray-500">
              Facebook
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            title={article.title}
            hashtags={["Toiny", "言論ファウンディングプラットフォーム"]}
            className="flex w-16 flex-col items-center justify-center gap-1"
          >
            <XIcon size={32} round />
            <div className="text-sm text-gray-500">
              X(Twitter)
            </div>
          </TwitterShareButton>
          <LineShareButton
            url={url}
            title={article.title}
            className="flex w-16 flex-col items-center justify-center gap-1"
          >
            <LineIcon size={32} round />
            <div className="text-sm text-gray-500">
              LINE
            </div>
          </LineShareButton>
          <HatenaShareButton
            url={url}
            title={article.title}
            className="flex w-16 flex-col items-center justify-center gap-1"
          >
            <HatenaIcon size={32} round />
            <div className="text-sm text-gray-500">
              はてな
            </div>
          </HatenaShareButton>
        </div>
      </div>
    </div>
  );
};
