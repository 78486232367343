import classNames from "classnames";

import { formatNumber } from "shared/helpers";
import { MoneyType, TopicRewardType } from "shared/models";
import { BFC } from "shared/types";

import { CurrencyIcon } from "~/features/utils";

type Props = {
  type: TopicRewardType;
  amount: MoneyType;
  iconSize?: number;
};

export const TopicRewardBadge: BFC<Props> = ({
  amount,
  iconSize = 20,
  className,
}) => {
  return (
    <div className={classNames("text-black-500 text-base font-bold flex gap-0.5 items-center", className)}>
      <CurrencyIcon currency={amount.currency} size={iconSize} />
      {formatNumber(amount.amount)}
    </div>
  );
};
