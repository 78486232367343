import classNames from "classnames";
import EmblaAutoPlay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useRef, useState } from "react";

import { Link } from "shared/components";
import { formatDate } from "shared/helpers";
import { MagazineArticle } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

type Props = {
  articles: MagazineArticle[];
};

export const MagazineArticleCarousel: BFC<Props> = ({
  articles,
  className,
}) => {
  const [selectedScrollSnap, setSelectedScrollSnap] = useState(0);

  const autoPlay = useRef(EmblaAutoPlay());
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true },
    [autoPlay.current],
  );

  const scrollTo = useCallback((index: number) => () => {
    emblaApi?.scrollTo(index);
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    setSelectedScrollSnap(emblaApi?.selectedScrollSnap() ?? 0);
  }, [emblaApi]);

  useEffect(() => {
    emblaApi?.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const textShadowColor = "rgba(0, 0, 0, 0.3)";
  const textShadow = Array.from(Array(3).keys()).map(() => `0 0 3px ${textShadowColor}`).join(", ");

  return (
    <div className={classNames(className)}>
      <div ref={emblaRef} className={classNames("overflow-hidden")}>
        <div className={classNames("flex h-60")}>
          {articles.map((article) => (
            <Link key={article.id} href={routes.MAGAZINE_ARTICLES_SHOW(article.slug)} className="flex-[0_0_100%]">
              <div key={article.id} className="relative h-full w-full">
                <img src={article.cover?.url} className={classNames("absolute inset-0 w-full h-full object-cover z-10")} />
                <div className="absolute bottom-0 z-20 p-4">
                  <div className="flex gap-2">
                    {article.getCategories().map((category) => (
                      <span key={category.id} className="rounded bg-neutral-100 px-2 py-0.5 text-xs text-gray-500">
                        {category.name}
                      </span>
                    ))}
                  </div>
                  <div className="mt-2 text-lg font-bold text-white" style={{ textShadow }}>{article.title}</div>
                  <div className="mt-0.5 text-sm text-white" style={{ textShadow }}>{formatDate(article.getPublishDate())}</div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="mt-4 flex items-center justify-center gap-2">
          {articles.map((_, index) => (
            <button
              key={index}
              type="button"
              className={classNames("w-2 h-2 rounded-full", {
                "bg-gray-500": index === selectedScrollSnap,
                "bg-gray-300": index !== selectedScrollSnap,
              })}
              onClick={scrollTo(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
