import { useCallback } from "react";

import { PopoverMenu } from "shared/features/utils";
import { TopicNote } from "shared/models";
import { BFC } from "shared/types";

import { EditorJsData } from "~/lib/editorjs";

import { useNotes } from "../../hooks";
import { Body } from "../Body";

type Props = {
  topicId: string;
  note: TopicNote;
  index: number;
  showMenu?: boolean;
  onDestroy?: () => void;
};

export const Note: BFC<Props> = ({
  topicId,
  note,
  index,
  showMenu,
  onDestroy,
  className,
}) => {
  const { destroy } = useNotes(topicId);

  const onDestroyClick = useCallback(async () => {
    await destroy(note.id);
    onDestroy?.();
  }, [destroy]);

  return (
    <div className={className}>
      <div className="flex justify-between">
        <span className="text-black-500 rounded bg-gray-100 px-2 py-1 text-sm font-bold">補足{index + 1}</span>
        {showMenu && (
          <PopoverMenu>
            <PopoverMenu.Item onClick={onDestroyClick}>削除</PopoverMenu.Item>
          </PopoverMenu>
        )}
      </div>
      <Body
        body={note.body}
        bodyData={note.bodyData as EditorJsData}
        bodyType={note.bodyType}
        className="mt-2"
      />
    </div>
  );
};
