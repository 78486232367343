import { useCallback, useMemo } from "react";
import { DeepPartial } from "react-hook-form";
import { useDebounce } from "react-use";
import { z } from "zod";

import { Button, HiddenControl, SelectControlGroup, ToggleControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";
import { Position, Proposal, ProposalBodyType, Topic } from "shared/models";

import { EditorJsChangeEvent, EditorJsControlGroup } from "~/components";
import { EditorJsData, editorjsDataToText } from "~/lib/editorjs";

const schema = z.object({
  proposal: z.object({
    body: z.string().min(1).max(100000),
    bodyText: z.string().min(1),
    bodyType: z.nativeEnum(ProposalBodyType),
    bodyData: z.any(),
    positionId: z.string().optional(),
    threadId: z.string().optional(),
    asAnonymous: z.boolean().optional(),
  }),
});

export type CreateProposalData = z.infer<typeof schema>;

type Props = {
  topic: Topic;
  draft?: DeepPartial<Proposal>;
  positions: Position[];
  onDraftSave?: (data: CreateProposalData) => void;
  isDraftSaving?: boolean;
  positionChangeable?: boolean;
  forceAsAnonymous?: boolean;
  isPending?: boolean;
};

export const CreateProposalForm = createHookForm<CreateProposalData, Props>(({
  formState: { isSubmitting, isValid },
  watch,
  getValues,
  setValue,
  draft,
  positions,
  onDraftSave,
  isDraftSaving,
  positionChangeable = true,
  forceAsAnonymous,
  isPending,
}) => {
  const bodyText = watch("proposal.bodyText");
  const positionId = watch("proposal.positionId");
  const bodyTime = watch("proposal.bodyData.time") as number | undefined;
  const asAnonymous = watch("proposal.asAnonymous");
  const draftTime = draft?.bodyData ? (draft.bodyData as EditorJsData).time : undefined;

  const isDirty = useMemo(() => {
    if (!draft?.id && (!bodyText || bodyText === "")) return false;

    if (positionId !== draft?.position?.id) return true;

    if (asAnonymous !== draft?.asAnonymous) return true;

    if (!draftTime && bodyTime) return true;

    if (draftTime && bodyTime) {
      return draftTime < bodyTime;
    }

    return false;
  }, [draft, bodyText, draftTime, bodyTime, positionId, asAnonymous]);

  const positionOptions = useMemo(() => positions.map((position) => ({
    label: position.name,
    value: position.id,
  })), [positions]);

  const onBodyChange: EditorJsChangeEvent = useCallback((data) => {
    const text = editorjsDataToText(data);
    setValue("proposal.body", JSON.stringify(data));
    setValue("proposal.bodyText", text);
    setValue("proposal.bodyData", data);
  }, [setValue]);

  useDebounce(() => {
    if (onDraftSave && isDirty) {
      onDraftSave(getValues());
    }
  }, 1500, [isDirty, bodyText, positionId, asAnonymous]);

  return (
    <div className="flex flex-col gap-4">
      {positionOptions.length > 0 && (
        <SelectControlGroup
          name="proposal.positionId"
          items={positionOptions}
          label="選択肢"
          placeholder="選択してください"
          required
          disabled={!positionChangeable}
          note={positionChangeable ? "" : "スレッドに追加する場合、選択肢は変更できません"}
        />
      )}
      <EditorJsControlGroup
        name="proposal.bodyData"
        label="本文"
        placeholder="あなたの声をお聞かせください"
        inputClassName="min-h-64"
        onChange={onBodyChange}
        required
      />
      <HiddenControl name="proposal.body" />
      <HiddenControl name="proposal.bodyText" />
      <ToggleControlGroup
        name="proposal.asAnonymous"
        inputLabel="匿名で投稿する"
        position="right"
        disabled={forceAsAnonymous}
        note={forceAsAnonymous ? "スレッドに追加する場合、匿名設定の変更はできません" : ""}
      />
      <Button
        type="submit"
        block
        primary
        large
        loading={isSubmitting || isDraftSaving || isPending}
        disabled={!isValid || isDirty}
      >
        {isDraftSaving ? "下書き保存中..." : "利用規約に同意して発言する"}
      </Button>
    </div>
  );
}, {
  schema,
});
