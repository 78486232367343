"use client";
import { useCallback } from "react";
import { HiStar } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { ProposalType } from "shared/models";
import { BFC } from "shared/types";

import { ProposalDetailAccordion, SupporterListItem } from "../../components";
import { useProposal, useTopic, useProposalSupporters } from "../../hooks";

type Props = {
  proposal?: ProposalType;
};

export const SupportersIndexPage: BFC<Props> = ({
  proposal: proposalData,
}) => {
  const { proposal } = useProposal(proposalData?.id, proposalData);
  const { topic } = useTopic(proposal.topicId);
  const {
    supporters,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isSupportersLoading,
  } = useProposalSupporters(proposal.id);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <HiStar className="text-yellow-500" size={24} />
          支援者一覧
        </h1>
        <div className="text-black-400 text-sm">
          この発言はこれらの方々によって支持されています
        </div>
        <ProposalDetailAccordion topic={topic} proposal={proposal} className="rounded border" />
      </div>
      <div className="border-y bg-white">
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="flex flex-col divide-y">
            {supporters.map((supporter, index) => (
              <SupporterListItem
                key={supporter.user.id}
                rank={index + 1}
                supporter={supporter}
              />
            ))}
          </div>
        </InfiniteScroll>
        {supporters.length === 0 && !isFetchingNextPage && !isSupportersLoading && (
          <div className="text-black-500 flex flex-col items-center justify-center py-8">
            支援者がまだいません
          </div>
        )}
        {(isSupportersLoading || isFetchingNextPage) && (
          <div className="flex items-center justify-center p-4">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  );
};
