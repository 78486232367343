"use client";
import { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { BFC } from "shared/types";

import { useSearchLayout } from "~/components";

import { TopicCardList } from "../../components";
import { useSearchTopics } from "../../hooks";

export const TopicsSearchPage: BFC = () => {
  const { selectTab, keyword } = useSearchLayout();
  const { fetchNextPage, topics, hasNextPage, isLoading, isFetchingNextPage } = useSearchTopics({ keyword });

  useEffect(() => {
    selectTab("topics");
  }, [selectTab]);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="pt-3">
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <TopicCardList topics={topics} className="md:px-3" />
      </InfiniteScroll>
      {topics.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="text-black-500 flex flex-col items-center justify-center py-8">
          該当するトピックはありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center">
          <ColorRing />
        </div>
      )}
    </div>
  );
};
