import classNames from "classnames";

import { Position } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  position: Position;
};

export const PositionBadge: BFC<Props> = ({
  position,
  className,
}) => {
  return (
    <div className={classNames("border text-black-400 text-sm inline-block items-center rounded py-0.5 px-2", className)}>
      {position.name}
    </div>
  );
};
