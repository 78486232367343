"use client";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";

import { NotificationListItem, useNotificationsContext } from "../../components";

export const NotificationsPage = () => {
  const { notifications, read, isLoading, isFetchingNextPage } = useNotificationsContext();

  useEffect(() => {
    const notification = notifications[0];
    if (notification && !notification.isRead()) {
      read(notification.id);
    }
  }, [notifications]);

  return (
    <div className="flex flex-col divide-y bg-white">
      {notifications.map((notification) => (
        <NotificationListItem key={notification.id} notification={notification} />
      ))}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex items-center justify-center py-4">
          <ColorRing />
        </div>
      )}
      {notifications.length === 0 && !isLoading && (
        <div className="flex h-full items-center justify-center p-4">
          <div className="text-gray-400">通知はありません</div>
        </div>
      )}
    </div>
  );
};
