"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { useCallback, useTransition } from "react";
import { toast } from "react-hot-toast";
import { HiOutlineLockClosed } from "react-icons/hi2";

import { OnSubmitType } from "shared/lib/hook-form";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { PasswordResetForm, PasswordResetData } from "../../forms";
import { usePasswordReset } from "../../hooks";

export const PasswordResetPage: BFC = () => {
  const router = useRouter();
  const params = useSearchParams();
  const [isPending, startTransition] = useTransition();
  const resetPasswordToken = params?.get("reset_password_token") ?? "";
  const { reset } = usePasswordReset();
  const onSubmit: OnSubmitType<PasswordResetData> = useCallback(async (data, _, { setError }) => {
    await reset(data, {
      onError: () => {
        setError("user.resetPasswordToken", { message: "リンクの有効期限が切れています。もう一度メール送信からやり直してください" });
      },
    });
    toast.success("パスワードをリセットしました");
    startTransition(() => {
      router.push(routes.USERS_SIGN_IN());
    });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white px-4 py-6">
        <h1 className="flex items-center gap-2 text-lg font-bold">
          <HiOutlineLockClosed size={24} className="text-black-500" />
          パスワード再設定
        </h1>
      </div>
      <div className="flex flex-col gap-4 border-t bg-white p-4 shadow">
        <p>新しいパスワードを入力してください</p>
        <PasswordResetForm
          resetPasswordToken={resetPasswordToken}
          isPending={isPending}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
};
