import classNames from "classnames";
import { ColorRing } from "react-loader-spinner";

import { formatMoney } from "shared/helpers";
import { Checkout } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  checkout: Checkout;
  totalPriceTitle?: string;
  totalPriceSectionClassName?: string;
  totalPriceClassName?: string;
  notRefundable?: boolean;
  loading?: boolean;
};

export const PaymentDetail: BFC<Props> = ({
  checkout,
  className,
  totalPriceTitle = "お支払金額",
  totalPriceSectionClassName,
  totalPriceClassName,
  notRefundable = false,
  loading,
}) => {
  const {
    subtotalAmount,
    fee,
    feeRate,
    tax,
    taxRate,
    totalAmount,
  } = checkout;

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      {subtotalAmount.amount != totalAmount.amount && (
        <div className="flex items-center justify-between">
          <div>小計</div>
          <div>
            {formatMoney(subtotalAmount)}
          </div>
        </div>
      )}
      {fee.amount > 0 && (
        <div className="flex items-center justify-between">
          <div>決済手数料({feeRate}%)</div>
          <div>
            {formatMoney(fee)}
          </div>
        </div>
      )}
      {tax.amount > 0 && (
        <div className="flex items-center justify-between">
          <div>消費税({taxRate}%)</div>
          <div>
            {formatMoney(tax)}
          </div>
        </div>
      )}
      <div className={classNames("flex items-center justify-between", totalPriceSectionClassName)}>
        <div>
          {totalPriceTitle}
          {taxRate > 0 && (
            <span>(税込)</span>
          )}
        </div>
        {loading ? (
          <ColorRing width={40} height={40} />
        ) : (
          <div className={classNames(totalPriceClassName, "flex gap-3 items-center font-bold text-primary text-lg")}>
            {notRefundable && (
              <div className="rounded bg-neutral-600 px-2 py-1 text-sm text-white">返金不可</div>
            )}
            {formatMoney(totalAmount)}
          </div>
        )}
      </div>
    </div>
  );
};
