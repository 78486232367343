import { ReactNode } from "react";
import { HiChevronRight } from "react-icons/hi2";

import { Link } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  label: ReactNode;
  note?: ReactNode;
  href?: string;
};

export const MypageMenuItem: BFC<Props> = ({
  label,
  note,
  href,
}) => {
  return (
    href ? (
      <Link href={href} className="flex items-center justify-between gap-4 p-4">
        <div className="flex flex-col gap-2">
          {label}
          {note && (
            <div className="text-black-400 line-clamp-1">
              {note}
            </div>
          )}
        </div>
        <HiChevronRight size={24} className="text-black-300" />
      </Link>
    ) : (
      <div className="flex items-center justify-between gap-4 p-4">
        <div className="flex flex-col gap-2">
          {label}
          {note && (
            <div className="text-black-400">
              {note}
            </div>
          )}
        </div>
      </div>
    )
  );
};
