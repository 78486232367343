import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { BFC } from "shared/types";

import components from "./components";

type Props = {
  children: string;
};

export const Markdown: BFC<Props> = ({
  children,
  className,
}) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={components}
      className={className}
    >
      {children}
    </ReactMarkdown>
  );
};
