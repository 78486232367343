import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Category, CategoryType } from "shared/models";
import { useWebAPI } from "shared/services/api";

const { persistAtom } = recoilPersist({ key: "toiny.web.categories" });

const categoriesState = atom<CategoryType[]>({
  key: "categories",
  default: [],
  effects: [persistAtom],
});

export const useCategories = () => {
  const [categoriesCache, setCategoriesCache] = useRecoilState(categoriesState);
  const [categories, setCategories] = useState<Category[]>([]);

  const api = useWebAPI();
  const { isLoading } = useQuery(
    ["product/categories"],
    () => api.getCategories(), {
    onSuccess: ({ data: { categories } }) => {
      setCategoriesCache(categories);
    },
  });

  const rootCategories = useMemo(() => {
    return categories.filter((c) => c.isRoot);
  }, [categories]);

  const findChildCategories = useCallback((category: Category) => {
    return categories.filter((c) => c.parent?.id === category.id);
  }, [categories]);

  useEffect(() => {
    setCategories(categoriesCache.map((category) => new Category(category)));
  }, [categoriesCache]);

  return {
    categories,
    rootCategories,
    findChildCategories,
    isLoading,
  };
};
