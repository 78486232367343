import { z } from "zod";

import { Button, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    password: z.string().min(1).min(8, "最低8文字で入力してください"),
    passwordConfirmation: z.string().min(1),
  }).refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致しません",
      path: ["passwordConfirmation"],
    },
  ),
});

export type PasswordEditData = z.infer<typeof schema>;

type Props = {
  onCancelClick?: () => void;
};

export const PasswordEditForm = createHookForm<PasswordEditData, Props>(({
  formState: { isSubmitting },
  onCancelClick,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup
        name="user.password"
        label="パスワード"
        required
        note="8文字以上で推測されづらい文字列を入力してください"
      />
      <TextControlGroup
        name="user.passwordConfirmation"
        label="パスワード(確認)"
        required
        note="確認のためもう一度入力してください"
      />
      <Button type="submit" block primary large loading={isSubmitting}>
        変更する
      </Button>
      <Button block onClick={onCancelClick}>
        キャンセル
      </Button>
    </div>
  );
}, {
  schema,
});
