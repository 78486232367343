import { formatDateTime } from "shared/helpers";
import { Checkout, Topic } from "shared/models";
import { BFC } from "shared/types";

import { TopicRewardBadge } from "../TopicRewardBadge";
import { TopicRewardTypeBadge } from "../TopicRewardTypeBadge";

type Props = {
  topic: Topic;
  checkout?: Checkout | null;
};

export const TopicRewardDetail: BFC<Props> = ({
  topic,
  checkout,
}) => {
  return (
    <div className="flex flex-col gap-2 rounded border p-3">
      <div className="flex justify-center">
        <TopicRewardBadge
          type={topic.rewardType}
          amount={topic.getRewardAmount()}
          iconSize={22}
          className="text-lg"
        />
      </div>
      <div className="flex justify-center">
        <TopicRewardTypeBadge type={topic.rewardType} />
      </div>
      <div className="text-black-500 text-sm">
        {topic.rewardTypeIsAllForBest() && (
          "設定された報酬は、起票者が任意に選ぶベスト発言者に全額支払われます。"
        )}
        {topic.rewardTypeIsDistributeByOwner() && (
          "設定された報酬は、起票者が任意に選ぶ複数の回答者に分配されます。"
        )}
        {topic.rewardTypeIsDistributeByAuto() && (
          "設定された報酬は、いいねの数に応じて自動的に回答者に分配されます。"
        )}
      </div>
      {checkout && !topic.isFixed() && (
        <div className="text-black-500 -mx-3 -mb-3 border-t bg-gray-100 p-3 text-sm">
          {checkout.isCanceled() && (
            <div>
              支払いキャンセル済みです。
              再公開することはできません。
            </div>
          )}
          {checkout.isCancellable() && (
            <div>
              この決済は{formatDateTime(checkout.cancellableAt)}までキャンセル可能です。<br />
              {topic.isOpen() && (
                "キャンセルするにはまずはトピックを非公開にしてください。"
              )}
            </div>
          )}
          {!checkout.isCancellable() && !topic.isFixed() && !checkout.isCanceled() && topic.rewardExpiresAt && (
            <div>
              {formatDateTime(topic.rewardExpiresAt)}までに報酬を配布してください
            </div>
          )}
        </div>
      )}
    </div>
  );
};
