import classNames from "classnames";
import { differenceInSeconds } from "date-fns";
import { useEffect, useState } from "react";
import { formatSecondsDuration } from "~/../../packages/shared/helpers";

import { BFC } from "shared/types";

type Props = {
  endAt: Date;
};

export const PrioritizedRemainingTime: BFC<Props> = ({
  endAt,
  className,
}) => {
  const [remainingSeconds, setRemainingSeconds] = useState(differenceInSeconds(endAt, new Date()));

  useEffect(() => {
    setTimeout(function timer() {
      const newSeconds = differenceInSeconds(endAt, new Date());
      setRemainingSeconds(newSeconds);
      if (newSeconds > 0) {
        setTimeout(timer, 1000);
      }
    }, 1000);
  }, []);

  return (
    <span className={classNames(className)}>{formatSecondsDuration(remainingSeconds)}</span>
  );
};
