"use client";
import { useRouter } from "next/navigation";
import {
  Fragment, useCallback, useEffect, useMemo, useRef, useState, useTransition
} from "react";
import { DeepPartial } from "react-hook-form";
import { toast } from "react-hot-toast";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import { ColorRing } from "react-loader-spinner";

import { InputLabel } from "shared/components";
import { Proposal, ProposalBodyType, ProposalType, TopicType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ProposalListItem, TopicDetailAccordion } from "../../components";
import { CreateProposalData, CreateProposalForm } from "../../forms";
import { useCreateProposal, useProposal, useTopic } from "../../hooks";

type Props = {
  topic: TopicType;
  positionId?: string;
  threadId?: string;
};

export const ProposalsNewPage: BFC<Props> = ({
  topic: topicData,
  positionId,
  threadId,
}) => {
  const router = useRouter();
  const { topic, positions, isLoading } = useTopic(topicData.id, topicData);
  const {
    drafts: draftsData,
    createProposal,
    updateProposal,
    deleteProposal,
    publishProposal,
    isMutating,
    isDraftLoading,
  } = useCreateProposal(topicData.id);
  const { threadProposals } = useProposal(threadId);
  const lastThreadProposal = useMemo(() => threadProposals?.[0], [threadProposals]);
  const [draft, setDraft] = useState<DeepPartial<ProposalType>>({
    position: {
      id: positionId,
    },
  });
  const [defaultValues, setDefaultValues] = useState<DeepPartial<CreateProposalData>>({});
  const [isPending, startTransition] = useTransition();

  const drafts = useMemo(() => {
    if (threadId) {
      return draftsData.filter((d) => d.threadId === threadId);
    }

    return draftsData;
  }, [draftsData, threadId]);

  const onDraftSelectClick = useCallback((proposal: Proposal) => () => {
    setDraft(proposal);
  }, []);

  const onDraftDeleteClick = useCallback((proposal: Proposal) => () => {
    deleteProposal({ id: proposal.id });
  }, []);

  const onDraftSave = useCallback(async (data: CreateProposalData) => {
    if (!draft.id) {
      if (!data.proposal.body) {
        return;
      }

      const { data: { proposal } } = await createProposal(data);
      setDraft(proposal);
    } else {
      const { data: { proposal } } = await updateProposal({ id: draft.id, ...data });
      setDraft(proposal);
    }
  }, [draft.id]);

  const onSubmit = useCallback(async () => {
    if (draft.id) {
      await publishProposal({ id: draft.id });
      toast.success("発言を投稿しました");
      startTransition(() => {
        if (draft.id) {
          router.push(routes.TOPICS_PROPOSALS_SHOW(topic.id, draft.id));
        }
      });
    }
  }, [topic, draft]);

  const detailRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!draft?.id) {
      const lastDraft = drafts[0];
      if (lastDraft) {
        setDraft(lastDraft);
      }
    }
  }, [draft, drafts]);

  useEffect(() => {
    if (lastThreadProposal) {
      setDraft((draft) => ({
        ...draft,
        threadId: lastThreadProposal.threadId || lastThreadProposal.id,
        position: {
          id: lastThreadProposal.position?.id,
        },
        asAnonymous: lastThreadProposal.asAnonymous,
      }));
    }
  }, [lastThreadProposal]);

  useEffect(() => {
    if (!draft) {
      return;
    }

    setDefaultValues({
      proposal: {
        body: draft.body,
        bodyText: draft.bodyText,
        bodyData: draft.bodyData,
        bodyType: ProposalBodyType.Editorjs,
        positionId: draft.position?.id,
        threadId: draft.threadId,
        asAnonymous: draft.asAnonymous,
      },
    });
  }, [draft?.id, draft?.threadId]);

  useEffect(() => {
    if (detailRef.current && formRef.current) {
      const detailRect = detailRef.current.getBoundingClientRect();
      const formRect = formRef.current.getBoundingClientRect();
      const top = formRect.top - detailRect.top - detailRect.height - 16;
      window.scrollTo({ top, behavior: "smooth" });
    }
  }, [threadProposals.length]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4" ref={detailRef}>
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <HiOutlinePencilAlt size={24} />
          発言を投稿する
        </h1>
        <div className="text-black-400 text-sm">
          このトピックについてあなたの声をお聞かせください
        </div>
        <TopicDetailAccordion
          topic={topic}
          hideActions
          hideMenu
          className="overflow-hidden rounded border"
        />
      </div>

      {drafts.length > 1 && (
        <div className="border-y bg-white">
          <div className="p-4">
            <InputLabel label="下書き一覧" />
          </div>
          <div className="flex flex-col divide-y border-t">
            {drafts.map((d) => (
              <div key={d.id} className="grid grid-cols-12 items-center p-4" onClick={onDraftSelectClick(d)}>
                <div className="col-span-9 line-clamp-1 break-all">{d.bodyText}</div>
                <div className="col-span-3 flex items-center justify-end gap-3">
                  {d.id === draft?.id ? (
                    <div className="text-black-500 rounded border px-3 py-1 text-sm">編集中</div>
                  ) : (
                    <button onClick={onDraftDeleteClick(d)}>
                      <HiOutlineTrash size={20} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div>
        {threadProposals.length > 0 && (
          <div className="flex flex-col">
            {threadProposals.map((proposal) => (
              <Fragment key={proposal.id}>
                <ProposalListItem
                  proposal={proposal}
                  className="mx-1 rounded border"
                />
                <div className="mx-3 h-8 border-l-8 border-gray-300" />
              </Fragment>
            ))}
          </div>
        )}

        <div className="border-y bg-white p-4" ref={formRef}>
          {isLoading || isDraftLoading ? (
            <div className="flex justify-center">
              <ColorRing />
            </div>
          ) : (
            <CreateProposalForm
              topic={topic}
              draft={draft}
              positions={positions}
              onDraftSave={onDraftSave}
              isDraftSaving={isMutating}
              positionChangeable={threadProposals.length === 0}
              forceAsAnonymous={threadProposals.length > 0}
              isPending={isPending}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
            />
          )}
        </div>
      </div>
    </div>
  );
};
