import classNames from "classnames";
import { ReactNode, useMemo } from "react";

import { Link } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  image?: string;
  link?: string;
  children?: ReactNode;
};

export const Item: BFC<Props> = ({
  image,
  link,
  className,
  children,
}) => {
  const content = useMemo(() => {
    return (
      <>
        {image && (
          <img
            className="h-full w-full object-cover"
            src={image}
          />
        )}
        {children}
      </>
    );
  }, [image]);

  return (
    <div className={classNames("h-full", className)}>
      {link ? (
        <Link href={link} className="h-full w-full">{content}</Link>
      ) : (
        <>{content}</>
      )}
    </div>
  );
};
