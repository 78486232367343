"use client";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { AiTwotoneMoneyCollect } from "react-icons/ai";
import { HiOutlineChatBubbleLeftRight, HiOutlineCurrencyYen } from "react-icons/hi2";

import { Button } from "shared/components";

import { routes } from "~/constants";

import { useConnectStripeAccount } from "../../hooks";

export const StripeAccountPage = () => {
  const router = useRouter();
  const { create, isMutating } = useConnectStripeAccount();

  const onCreateClick = useCallback(async () => {
    create(
      { returnTo: `${process.env.NEXT_PUBLIC_WEB_ORIGIN}${routes.MYPAGE_STRIPE_CONNECT}` },
      {
        onSuccess: ({ data: { url } }) => {
          router.push(url);
        },
      },
    );
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <AiTwotoneMoneyCollect size={24} />
          Stripeアカウント設定
        </h1>
        <div className="text-black-400 text-sm">
          収益化のためにStripeアカウントの設定が必要です
        </div>
      </div>
      <div className="bg-white">
        <h2 className="border-b p-4 font-bold">Toinyで収益を得るには？</h2>
        <div className="flex flex-col gap-4 p-4">
          <div>
            Toinyで収益を得る方法は主に2つあります。
          </div>
          <div>
            <h3 className="flex items-center gap-1 font-bold">
              <HiOutlineChatBubbleLeftRight size={24} />
              報酬付きのトピックでベストアンサーを獲得する
            </h3>
            <div className="mt-3">
              トピックには報酬を設定することができます。<br />
              トピックに設定された報酬は、ベストアンサーを獲得したユーザーに対して支払われます。<br />
            </div>
          </div>
          <div>
            <h3 className="flex items-center gap-1 font-bold">
              <HiOutlineCurrencyYen size={24} />
              発言に対する支援(チップ)を受け取る
            </h3>
            <div className="mt-3">
              ユーザーは、発言に対して支援(チップ)を送ることができます。<br />
              送られたチップは、ベストアンサーとは無関係に発言者が直接受け取ることができます。
            </div>
          </div>
          <div className="text-black-500 mt-3 rounded border bg-gray-100 p-3">
            収益化設定をしなくてもベストアンサーやチップを獲得することはできますが、報酬を払い出すには収益化設定が必須となります。
          </div>
        </div>
      </div>
      <div className="bg-white">
        <h2 className="border-b p-4 font-bold">Stripeアカウントについて</h2>
        <div className="flex flex-col gap-4 p-4">
          <div>
            Toinyでは報酬の払い出しについてStripeを利用しています。<br />
          </div>
          <div>
            Stripeは、国際的に信頼されている決済サービスで、ユーザーはStripeを通して安心・安全に報酬を受け取ることができます。<br />
          </div>
          <div>
            <Button primary block large loading={isMutating} onClick={onCreateClick}>Stripeアカウントを作成する</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
