"use client";
import { useSearchParams } from "next/navigation";
import { useCallback, useMemo } from "react";
import { toast } from "react-hot-toast";
import { AiTwotoneMoneyCollect } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi2";
import InfiniteScroll from "react-infinite-scroller";

import { LoadingOverlay } from "shared/components";
import { MoneyCurrency } from "shared/models";

import { useBankAccounts } from "~/features/users";

import { PayoutListItem } from "../../components";
import { PayoutData, PayoutForm } from "../../forms";
import { useBalances, usePayouts } from "../../hooks";

export const PayoutsPage = () => {
  const params = useSearchParams();
  const currency = params?.get("currency") ?? MoneyCurrency.Jpy;
  const { balances, isLoading: isBalancesLoading } = useBalances();
  const { payouts, requestPayout, hasNextPage, fetchNextPage } = usePayouts();
  const { bankAccounts, defaultBankAccount } = useBankAccounts();

  const balance = useMemo(() => {
    return balances.find((b) => b.isAvailable() && b.amount.currency === currency);
  }, [balances, currency]);

  const onSubmit = useCallback(async (data: PayoutData) => {
    await requestPayout(data);
    toast.success("出金申請を受け付けました");
  }, []);

  const loadMore = useCallback(() => {
    if  (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage]);

  const defaultValues = useMemo(() => ({
    payout: {
      amount: balance?.amount.amount ?? 0,
      currency: balance?.amount.currency ?? MoneyCurrency.Jpy,
      stripeBankAccountId: defaultBankAccount?.id,
    },
  }), [balance, defaultBankAccount]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="flex flex-col gap-3 border-b bg-white p-4">
        <h1 className="flex items-center gap-1 text-lg font-bold">
          <AiTwotoneMoneyCollect size={24} />
          出金申請
        </h1>
        <div className="text-black-400 text-sm">
          出金状況の確認や、出金申請を行うことができます
        </div>
      </div>

      <div className="flex flex-col gap-3 border-y bg-white p-4">
        <LoadingOverlay loading={isBalancesLoading}>
          {balance && (
            <PayoutForm
              balance={balance}
              bankAccounts={bankAccounts}
              defaultValues={defaultValues}
              onSubmit={onSubmit}
            />
          )}
        </LoadingOverlay>
      </div>

      <div className="flex flex-col border-y bg-white">
        <h3 className="flex items-center gap-1 border-b p-4 font-bold">
          <HiOutlineClock size={24} />
          申請履歴
        </h3>
        <div>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            {payouts.map((payout) => (
              <PayoutListItem key={payout.id} payout={payout} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
