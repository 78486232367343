import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import classNames from "classnames";
import { ChangeEventHandler, useCallback } from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { MdOutlineDragIndicator } from "react-icons/md";

import { TextControlGroup } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  id: string;
  index: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onRemoveClick: (id: string) => void;
};

export const PositionControlGroup: BFC<Props> = ({
  id,
  index,
  onChange,
  onRemoveClick,
  className,
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef,
  } = useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const classes = classNames(className, {
    "opacity-50": isDragging,
  });

  const onRemoveClickHandler = useCallback(() => {
    onRemoveClick(id);
  }, [id, onRemoveClick]);

  return (
    <div ref={setNodeRef} className={classNames("flex items-center justify-between gap-1", classes)} style={style}>
      <button type="button" {...attributes} {...listeners}>
        <MdOutlineDragIndicator className="text-gray-400" size={24} />
      </button>
      <TextControlGroup
        name={`positions.${index}.name`}
        placeholder="例: 賛成、反対など"
        onChange={onChange}
        className="w-full"
      />
      <button type="button" onClick={onRemoveClickHandler}>
        <HiOutlineTrash className="text-gray-400" size={24} />
      </button>
    </div>
  );
};
