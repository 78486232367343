import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useCallback, useState } from "react";
import { ColorRing } from "react-loader-spinner";

import { Button, RadioInput } from "shared/components";
import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { PaymentMethodListItem } from "../PaymentMethodListItem";

type Props = {
  paymentsMethods: PaymentMethod[];
  defaultPaymentMethod?: PaymentMethod | null;
  selectedPaymentMethod?: PaymentMethod | null;
  isLoading: boolean;
  onSelected?: (paymentMethod: PaymentMethod) => void;
  onNewClick?: () => void;
};

export const ListPage: BFC<Props> = ({
  paymentsMethods,
  defaultPaymentMethod,
  selectedPaymentMethod: currentPaymentMethod,
  isLoading,
  onSelected,
  onNewClick,
}) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(currentPaymentMethod ?? null);

  const onSelectFactory = useCallback((paymentMethod: PaymentMethod) => () => {
    setSelectedPaymentMethod(paymentMethod);
  }, []);

  const onSelectedClick = useCallback(() => {
    if (selectedPaymentMethod) {
      onSelected?.(selectedPaymentMethod);
    }
  }, [selectedPaymentMethod]);

  return (
    <div>
      {isLoading && (
        <div className="flex items-center justify-center">
          <ColorRing />
        </div>
      )}
      <div className="flex flex-col divide-y">
        {paymentsMethods.map((paymentMethod) => (
          <div key={paymentMethod.id} className="flex items-center gap-3 px-3" onClick={onSelectFactory(paymentMethod)}>
            <div className="flex flex-col justify-start">
              <RadioInput checked={paymentMethod.id === selectedPaymentMethod?.id} />
            </div>
            <PaymentMethodListItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isDefault={defaultPaymentMethod?.id === paymentMethod.id}
              className="flex-1 py-3"
            />
          </div>
        ))}
        <div className="flex items-center justify-between px-3 py-4" onClick={onNewClick}>
          支払い方法を追加する
          <ChevronRightIcon className="w-5" />
        </div>
        <div className="p-3">
          <Button primary large block onClick={onSelectedClick}>
            変更する
          </Button>
        </div>
      </div>
    </div>
  );
};
