"use client";
import { useCallback, useEffect, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { BFC } from "shared/types";

import { GoogleAdsenseUnit } from "~/features/ads";
import { useCarouselItems, TOP_CAROUSEL_HANDLE, TopCarousel } from "~/features/carousels";
import { useCategorySelectorLayout } from "~/features/categories";

import { TopicCardList } from "../../components";
import { useTopics } from "../../hooks";

export const TopPage: BFC = () => {
  const { selectCategory } = useCategorySelectorLayout();
  const { carouselItems } = useCarouselItems(TOP_CAROUSEL_HANDLE);
  const { fetchNextPage, topics, hasNextPage, isLoading, isFetchingNextPage } = useTopics();

  useEffect(() => {
    selectCategory(null);
  }, []);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const slides = useMemo(() => {
    return carouselItems.map((item) => (
      <TopCarousel.Item key={item.id} image={item.image.url} link={item.url} />
    ));
  }, [carouselItems]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      {slides.length > 0 && (
        <TopCarousel
          slides={slides}
          className="aspect-video bg-white md:aspect-auto md:h-80"
          containerClassName="gap-4"
          slideClassName=""
        />
      )}

      <div className="flex">
        <div className="flex-1">
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
            <TopicCardList topics={topics} className="md:px-3" />
          </InfiniteScroll>
          {(isLoading || isFetchingNextPage) && (
            <div className="flex items-center justify-center">
              <ColorRing />
            </div>
          )}
        </div>
        <div className="sticky hidden border-l border-t bg-white p-4 md:top-16 md:w-64">
          <GoogleAdsenseUnit
            slot="8492432568"
          />
        </div>
      </div>
    </div>
  );
};

const Slide1 = () => (
  <div className="flex h-full items-center justify-center">
    <div className="bg-primary flex aspect-video h-full w-full flex-col items-center justify-center gap-4 text-white">
      <div className="text-lg font-bold">
        3月1日よりベータ版公開開始 🎉
      </div>
    </div>
  </div>
);
